import * as yup from 'yup'

export const TICKET_AUTOCOMPLETE_SCHEMA = yup.object().shape({
  seat: yup
    .string()
    .trim()
    .transform((v) => (v ? v : null))
    .nullable(),
  row: yup
    .string()
    .trim()
    .transform((v) => (v ? v : null))
    .nullable(),
  section: yup
    .string()
    .trim()
    .transform((v) => (v ? v : null))
    .nullable(),
})
