import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useDeleteProxiesMutation, useGetCountriesQuery } from 'services/bots/api'

import { AddProxiesModal } from 'components/bots/proxies/AddProxiesModal/index'
import { EditProxyModal } from 'components/bots/proxies/EditProxyModal/index'
import ConfirmModal from 'components/common/ConfirmModal/index'
import TableToolbar from 'components/table/TableToolbar/index'

import { ProxiesTabActions } from 'pages/bots/ProxiesTab/index'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: ProxiesTabActions
  setModal: React.Dispatch<React.SetStateAction<ProxiesTabActions>>
  singleRow: any
}

export const ProxiesTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const [deleteProxies, { isLoading }] = useDeleteProxiesMutation()
  const { data: countries } = useGetCountriesQuery()
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const selectedRowIds = selectedRows.map((v: any) => v?.id)
  const confirmDelete = async () => {
    deleteProxies({ proxyIds: modal === 'DELETE' ? selectedRowIds : [singleRow?.id] })
      .unwrap()
      .then(() => {
        showNotification(
          modal === 'DELETE_SINGLE' || selectedRowIds.length === 1
            ? `Proxy successfully deleted`
            : `Selected proxies successfully deleted`,
          'success'
        )
        setModal('')
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const confirmMessage =
    modal === 'DELETE'
      ? selectedRowIds.length === 1
        ? `Are you sure you want to delete ${selectedRowIds?.join()} proxy?`
        : `Are you sure you want to delete ${selectedRowIds?.join()} proxies?`
      : `Are you sure you want to delete ${singleRow?.id} proxy?`
  return (
    <>
      <TableToolbar
        onChange={onSearchChange}
        searchValue={searchValue}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('ADD')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Add Proxies</Typography>
                </MenuItem>
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Proxy</Typography>
                  </MenuItem>
                )}
                {selectedRows.length >= 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('DELETE')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">
                      Delete Prox{selectedRows.length === 1 ? 'y' : 'ies'}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        }
      />
      {modal === 'ADD' && (
        <AddProxiesModal
          onCLose={() => {
            setModal('')
          }}
          countries={countries}
        />
      )}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditProxyModal
          onCLose={() => {
            setModal('')
          }}
          proxy={modal === 'EDIT' ? selectedRows[0] : singleRow}
          countries={countries}
        />
      )}
      {(modal === 'DELETE' || modal === 'DELETE_SINGLE') && (
        <ConfirmModal
          open={true}
          onConfirm={confirmDelete}
          loading={isLoading}
          onClose={() => {
            setModal('')
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
    </>
  )
}
