import { MouseEvent, useEffect, useState } from 'react'

import { AddCircleOutline } from '@mui/icons-material'
import { Box, Button, Chip, ListItem, Menu, MenuItem, SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'

import { useGetTagsQuery } from 'services/bookings/api'
import { Tag } from 'services/bookings/types'

const ITEM_HEIGHT = 48

type TagSelectable = Tag & { selected?: boolean }

interface Props {
  register: any
  setValue: any
  watch: any
  sx?: SxProps
}

export const Tags = ({ register, setValue, watch, sx }: Props) => {
  register('tagIds')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data } = useGetTagsQuery()
  const [selectedTags, setSelectedTags] = useState<TagSelectable[]>([])
  const currentTags = watch('tagIds') as number[]

  useEffect(() => {
    data &&
      setSelectedTags(
        data.map((tag) => ({ ...tag, selected: currentTags.includes(tag.id as number) }))
      )
  }, [data, currentTags])

  const handleOpenTagsMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget)
  }
  const handleCloseTagsMenu = () => {
    setAnchorEl(null)
  }

  const handleAddTag = (currentTag: TagSelectable) => {
    setSelectedTags((prev) =>
      prev.map((tag) => (tag.id === currentTag.id ? { ...tag, selected: true } : tag))
    )
    setValue('tagIds', [...currentTags, currentTag.id])
  }

  const handleDeleteTag = (currentTag: TagSelectable) => {
    setSelectedTags((prev) =>
      prev.map((tag) => (tag.id === currentTag.id ? { ...tag, selected: false } : tag))
    )
    setValue(
      'tagIds',
      currentTags.filter((tag) => tag !== currentTag.id)
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '100%',
          p: 1,
          width: '100%',
          border: '1px solid #E0F1F0',
          borderRadius: '0.3rem',
          justifyContent: 'space-between',
          //alignItems: 'center',
          ...sx,
        }}
      >
        <Box
          component="ul"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            overflowX: 'auto',
            listStyle: 'none',
            whiteSpace: 'nowrap',
            padding: 0,
            marginBlock: 'auto',
            marginLeft: '0.5rem',
            gap: '0.5rem',
          }}
        >
          {selectedTags.filter((tag) => tag.selected).length ? (
            selectedTags
              .filter((tag) => tag.selected)
              .map((tag) => (
                <ListItem
                  key={`${tag?.id}-tag-chip`}
                  sx={{
                    width: 'auto',
                    maxWidth: 200,
                    padding: '0 0 0 0.25rem',
                    display: 'flex',
                  }}
                >
                  <Chip
                    label={
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            backgroundColor: tag?.color,
                            minWidth: '1.2rem',
                            width: '1.2rem',
                            height: '1.2rem',
                            borderRadius: '0.2rem',
                            marginRight: 4,
                            display: 'inline',
                          }}
                        />
                        {tag?.label}
                      </div>
                    }
                    data-id={tag?.id}
                    onDelete={() => handleDeleteTag(tag)}
                  />
                </ListItem>
              ))
          ) : (
            <Typography sx={{ opacity: '0.5' }} component={'p'}>
              No selected Tags{' '}
            </Typography>
          )}
        </Box>
        <Button
          sx={{ minWidth: 80, borderRadius: '20px', marginLeft: 2 }}
          aria-controls={anchorEl ? 'tags-menu' : undefined}
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-haspopup="true"
          variant="outlined"
          size="small"
          onClick={handleOpenTagsMenu}
          startIcon={<AddCircleOutline />}
        >
          Tag
        </Button>
      </Box>
      <Menu
        id="tags-menu"
        MenuListProps={{ 'aria-labelledby': 'tags-menu' }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseTagsMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '15rem',
          },
        }}
      >
        {selectedTags.map(
          (availableTag) =>
            !availableTag.selected && (
              <MenuItem
                sx={{
                  whiteSpace: 'wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                key={`tags-menu-item-${availableTag.id}`}
                onClick={() => handleAddTag(availableTag)}
              >
                <div
                  style={{
                    backgroundColor: availableTag.color,
                    minWidth: '1.2rem',
                    width: '1.2rem',
                    height: '1.2rem',
                    borderRadius: '0.2rem',
                    marginRight: 4,
                    display: 'inline',
                  }}
                />
                {availableTag.label}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  )
}
