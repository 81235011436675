import { yupResolver } from '@hookform/resolvers/yup'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import { Typography } from '@mui/material'
import Box from '@mui/material/Box/Box'

import { showNotification } from 'utils/showNotification'

import { useGetSalesPlatfromsToSelectQuery, useRegisterSaleMutation } from 'services/bookings/api'
import { BookingsListing } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import CustomCheckbox from 'components/common/Checkbox'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

import { REGISTER_SALE_SCHEMA } from './validation'

interface Props {
  listing: BookingsListing
  onClose: () => void
}

interface FormType {
  salePriceEur: number
  salesPlatformId: {
    value: string
    name: string
  }
  customerName?: string | null
  customerEmail?: string | null
  sendEmail?: string | null
  trackingNumber?: string | null
  sendTickets: boolean
  salesPlatformUserId: {
    value: string
    name: string
  }
  platformOrderId: string
  tickets: {
    ticketId: number
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    selected?: boolean
  }[]
}

export const RegisterSaleModal = ({ listing, onClose }: Props) => {
  const { data: platforms } = useGetSalesPlatfromsToSelectQuery()
  const [registerSale, { isLoading }] = useRegisterSaleMutation()
  const platformsToSelect = platforms?.data?.map((platform) => ({
    name: platform.name,
    value: platform.id?.toString(),
  }))

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(REGISTER_SALE_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      tickets:
        listing.tickets.map((ticket) => ({
          ticketId: ticket.id,
          firstName: '',
          lastName: '',
          email: '',
          saleStatusType: ticket.saleStatusType,
          selected: false,
        })) || [],
      salePriceEur: 0,
      salesPlatformId: {
        value: '',
        name: '',
      },
      customerName: '',
      customerEmail: '',
      sendTickets: false,
      sendEmail: '',
      trackingNumber: '',
      salesPlatformUserId: {
        value: '',
        name: '',
      },
      platformOrderId: '',
    },
  })

  console.log(errors)
  const { fields: tickets } = useFieldArray({ control, name: 'tickets' })

  const salesPlatform = watch('salesPlatformId')

  const usersToSelect = platforms?.data
    .find((platform) => platform.id === +salesPlatform?.value)
    ?.platformUsers?.map((user) => ({
      name: user.platformUserName,
      value: user.id.toString(),
    }))

  const onSubmit = async (formValues: FormType) => {
    await registerSale({
      ...formValues,
      salesPlatformUserId: +formValues.salesPlatformUserId?.value,
      salesPlatformId: +formValues.salesPlatformId?.value,
      listingId: listing.id,
      tickets: formValues.tickets
        .filter((ticket) => ticket.selected)
        .map((ticket) => ({
          ...ticket,
          id: ticket.ticketId,
        })),
    })
      .unwrap()
      .then(() => {
        showNotification('Sale successfully registered', 'success')
        onClose()
      })
      .catch(() => {
        showNotification('Error. Try again later', 'error')
      })
  }

  return (
    <AppModal title="Register Sale" onClose={onClose} maxWidth={800} loading={isLoading}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        id="registerSaleForm"
        sx={{ width: '800px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ width: '800px' }}>
          <ControlledSelectAutocomplete
            name="salesPlatformId"
            label="Sales Platform"
            errors={errors}
            control={control}
            selectOptions={platformsToSelect}
          />
          <Box display="flex" gap={2}>
            <ControlledSelectAutocomplete
              name="salesPlatformUserId"
              label="Platform User"
              errors={errors}
              control={control}
              selectOptions={usersToSelect}
              disabled={!salesPlatform}
              sx={{ flex: 1 }}
            />
            <TextInput
              name="platformOrderId"
              label="Platform Order ID"
              errors={errors}
              control={control}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextInput
            name="salePriceEur"
            label="Sale Price, EUR"
            errors={errors}
            control={control}
          />
          <TextInput
            name="trackingNumber"
            label="Tracking Number"
            errors={errors}
            control={control}
          />
          <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
            <TextInput
              name="customerName"
              label="Customer Name"
              errors={errors}
              control={control}
            />
            <TextInput
              name="customerEmail"
              label="Customer Email"
              errors={errors}
              control={control}
            />
          </Box>
          <TextInput name="sendEmail" label="Send Email" errors={errors} control={control} />
          <CustomCheckbox name="sendTickets" control={control} label="Send tickets?" />
          <Box mt={3}>
            {tickets.map((ticket, index) => (
              <Box key={ticket.ticketId} sx={{ display: 'flex', width: '100%', gap: 2 }}>
                <CustomCheckbox
                  name={`tickets.${index}.selected`}
                  control={control}
                  label={`Ticket#${ticket.ticketId}`}
                  disabled={ticket.saleStatusType === 10}
                />
                <TextInput
                  name={`tickets.${index}.firstName`}
                  label="Holder First Name"
                  errors={errors?.tickets?.[index]?.firstName?.message}
                  control={control}
                  disabled={ticket.saleStatusType === 10}
                />
                <TextInput
                  name={`tickets.${index}.lastName`}
                  label="Holder Last Name"
                  errors={errors?.tickets?.[index]?.lastName?.message}
                  control={control}
                  disabled={ticket.saleStatusType === 10}
                />
                <TextInput
                  name={`tickets.${index}.email`}
                  label="Holder Email"
                  errors={errors?.tickets?.[index]?.email?.message}
                  control={control}
                  disabled={ticket.saleStatusType === 10}
                />
              </Box>
            ))}
            {errors?.tickets?.root?.message && (
              <Typography sx={{ fontSize: 12, color: 'error.main' }}>
                {errors?.tickets?.root?.message}
              </Typography>
            )}
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading} fullWidth={isMobileOnly}>
            Register Sale
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
