import { ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

import { rolesEnum } from 'services/users/types'

const ProtectedRoute = ({
  children,
  allowedRoles,
}: {
  children: ReactElement
  allowedRoles: number[]
}) => {
  const { user } = useAuth()
  const location = useLocation()
  const isAllowed = user && allowedRoles.includes(user?.roleId)
  if (user && !isAllowed && user.roleId === rolesEnum.Operator) {
    return <Navigate to="/purchasing" replace />
  } else if (user && !isAllowed && user.roleId === rolesEnum.Manager) {
    return <Navigate to="/bot-dashboard/charts" replace />
  } else if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}

export default ProtectedRoute
