import React, { useCallback, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import { TableServerResponse } from 'types/TableServerResponse'

import {
  EQUAL_STRING_OPERATOR,
  CONTAINS_STRING_OPERATOR,
  ALL_DATE_OPERATORS,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'
import { showNotification } from 'utils/showNotification'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useLazyGetMailsQuery } from 'services/dashboard/api'
import { Mail } from 'services/dashboard/types'

import { MailsTabToolbar } from 'components/mails/mails/MailsTabToolbar'
import CustomDataGrid from 'components/table/DataGrid'

export type MailTabActions = 'SHOW_MESSAGE' | 'SHOW_MESSAGE_SINGLE' | ''

export const MailsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'sender',
        headerName: 'Sender',
        type: 'string',
        headerAlign: 'left',
        align: 'left',
        flex: 3,
        hideable: false,
        sortable: false,
        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'recipient',
        headerName: 'Recipient',
        type: 'string',
        flex: 3,
        sortable: false,
        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'subject',
        headerName: 'Subject',
        type: 'string',
        flex: 3.5,
        sortable: false,
        //@ts-ignore
        filterOperators: [...EQUAL_STRING_OPERATOR, ...CONTAINS_STRING_OPERATOR],
      },
      {
        field: 'receivedAt',
        headerName: 'Received At',
        type: 'Datetime',
        flex: 2,
        sortable: false,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'catcherName',
        headerName: 'Catcher',
        flex: 3,
        sortable: false,
        filterable: false,
        type: 'string',
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('SHOW_MESSAGE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Show Message"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )

  const { queryParams, paginationDataGridProps } = usePagination({
    columns,
  })
  const { modal, setModal, singleRow, setSingleRow } = useActions<MailTabActions>()

  const { selectedRows, handleRowSelection } = useSelectedRows()

  const [getMails, { isFetching }] = useLazyGetMailsQuery()
  const [data, setData] = useState<TableServerResponse<Mail[]>>()

  const onFilterApplyClick = useCallback(() => {
    getMails({ ...queryParams, page: { ...queryParams.page, size: 100 } })
      .unwrap()
      .then((res) => setData(res))
      .catch(() => showNotification('Error. Try again later', 'error'))
  }, [JSON.stringify(queryParams)])

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<MailTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        pagination={false}
        //hideFooter
        onRowSelectionModelChange={handleRowSelection}
        {...paginationDataGridProps}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={MailsTabToolbar}
        onFilterApplyClick={onFilterApplyClick}
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        checkboxSelection
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: 'Total mails:',
            labelDisplayedRows: ({ count }) => `${count}`,
          },
        }}
        slotProps={{
          pagination: {
            nextIconButtonProps: { disabled: true, sx: { display: 'none' } },
            backIconButtonProps: { disabled: true, sx: { display: 'none' } },
            labelRowsPerPage: 'Total mails:',
            labelDisplayedRows: ({ count }) => `${count}`,
          },
        }}
      />
    </Box>
  )
}
