import { FC } from 'react'
import type { Theme } from 'react-base16-styling'
import { JSONTree } from 'react-json-tree'

import Box from '@mui/material/Box/Box'

import { AppModal } from 'components/common/AppModal/index'

export const theme: Theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

interface Props {
  onClose: () => void
  item: any
}

export const DetailsModal: FC<Props> = ({ onClose, item }) => {
  const renderDataPurchasing = {
    proxy: item.proxy,
    account: item.account,
    approveData: item.approveData,
    application: item.application,
  }
  const renderDataBotsAccount = {
    accountDetails: item.accountDetails,
  }
  const renderData = item.accountDetails ? renderDataBotsAccount : renderDataPurchasing
  return (
    <AppModal title="Details" onClose={onClose} maxWidth={800}>
      <AppModal.Wrapper sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}>
        <Box sx={{ width: 800 }} px={2}>
          <JSONTree hideRoot theme={theme} data={renderData} invertTheme />
        </Box>
      </AppModal.Wrapper>
    </AppModal>
  )
}