import React, { FC, useMemo } from 'react'

import OutboxIcon from '@mui/icons-material/Outbox'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  createAutocompleteOperator,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  ALL_NUMBER_OPERATORS,
  createNotEqualsAutocompleteOperator,
  ALL_STRING_OPERATORS,
  NOT_CONTAINS_STRING_OPERATOR,
  CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetSalesQuery } from 'services/bookings/api'
import {
  SENT_STATUS_OPTIONS_TO_SELECT,
  SENT_STATUS_TYPE,
  SentStatusType,
} from 'services/bookings/types'

import { SendTicketsModal } from 'components/bookings/Sales/SendTicketsModal/index'
import CustomDataGrid from 'components/table/DataGrid'

export type SalesModalActions = 'SEND_TICKETS' | 'SEND_TICKETS_SINGLE' | ''

interface IProps {
  listingId: number
}

const ManageListingTable: FC<IProps> = ({ listingId }) => {
  const { modal, setModal, singleRow, setSingleRow } = useActions<SalesModalActions>()
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 0.5,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'sentStatusHp',
        headerName: 'Sent status',
        type: 'singleSelect',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row.sentStatus,
        valueFormatter: ({ value }) => {
          const methodKey = value as SentStatusType
          return SENT_STATUS_TYPE[methodKey] || ''
        },
        valueOptions: SENT_STATUS_OPTIONS_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },

      {
        field: 'listing.eventCategory.event.eventGroup.name',
        headerName: 'Event group',
        type: 'String',
        flex: 2,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory?.eventGroupName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.event_group.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.event_group.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'listing.eventCategory.event.name',
        headerName: 'Event Name',
        type: 'String',
        flex: 2,
        valueGetter: ({ row }) => row?.eventCategory?.eventName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'numberOfTickets',
        headerName: 'Number of Tickets',
        type: 'Number',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row?.tickets?.length,
        filterable: false,
        sortable: false,
      },
      {
        field: 'listing.eventCategory.name',
        headerName: 'Category',
        type: 'String',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row?.eventCategory?.name,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'platformOrderId',
        headerName: 'Order ID',
        type: 'String',
        flex: 1.7,
        resizable: true,
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return !params.row.active
            ? [
                <GridActionsCellItem
                  key={params.id}
                  onClick={() => {
                    setModal('SEND_TICKETS_SINGLE')
                    setSingleRow(params.row)
                  }}
                  label=""
                  icon={
                    <Tooltip title="Send tickets">
                      <OutboxIcon />
                    </Tooltip>
                  }
                />,
              ]
            : []
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetSalesQuery({
    ...queryParams,
    completed: true,
    filters: [{ column: 'listing.id', operator: '=', values: [listingId], valueType: 'Number' }],
  })
  return (
    <Box sx={{ width: '100%', pt: 1, height: '800px' }}>
      <CustomDataGrid<SalesModalActions>
        rows={data?.data || []}
        columns={columns}
        columnVisibilityModel={{
          matchNumber: false,
          description: false,
        }}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        rowHeight={80}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
      {modal === 'SEND_TICKETS_SINGLE' && (
        <SendTicketsModal onClose={() => setModal('')} sale={singleRow} />
      )}
    </Box>
  )
}

export default ManageListingTable
