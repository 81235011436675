import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import TableToolbar from 'components/table/TableToolbar'

import { SalesModalActions } from 'pages/bookings/SalesTab'

import { SendTicketsModal } from '../SendTicketsModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: SalesModalActions
  setModal: React.Dispatch<React.SetStateAction<SalesModalActions>>
  singleRow: any
}

export const SalesTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button
              size="small"
              startIcon={<MenuIcon />}
              onClick={handleOpenMenu}
              disabled={!(selectedRows.length === 1)}
            >
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('SEND_TICKETS')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Send tickets</Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
          </Box>
        }
      />

      {(modal === 'SEND_TICKETS' || modal === 'SEND_TICKETS_SINGLE') && (
        <SendTicketsModal
          onClose={() => setModal('')}
          sale={modal === 'SEND_TICKETS' ? selectedRows[0] : singleRow}
        />
      )}
    </>
  )
}
