import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs from 'components/common/NavigationTabs'
import {rolesEnum} from "services/users/types";

const tabs = [
    { id: 'mails', label: 'MAILS', allowedRoles: [rolesEnum.Admin, rolesEnum.Manager] },
    { id: 'scrap-tasks', label: 'SCRAP TASKS', allowedRoles: [rolesEnum.Admin] },
]

const Mails: FC = () => {
  return (
    <Box>
      <NavigationTabs tabs={tabs} basePath="/mails" />
      <Outlet />
    </Box>
  )
}
export default Mails
