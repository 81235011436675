import React, { FC, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useTakeBotsToWorkMutation } from 'services/purchasing/api'

import TableToolbar from 'components/table/TableToolbar'

const AccountsTableToolbar: FC<Partial<GridToolbarProps & ToolbarPropsOverrides>> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const [takeToWork] = useTakeBotsToWorkMutation()

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    if (selectedRows.length) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const handleTakeToWork = async () => {
    await takeToWork({ botsIds: selectedRows })
      .then(() => {
        handleCloseMenu()
        navigate('../in-work')
        showNotification(
          `Bot${selectedRows.length > 1 ? 's' : ''} with id${
            selectedRows.length > 1 ? 's ' : ' '
          } ${selectedRows} successfully taken to work`,
          'success'
        )
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>

            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleTakeToWork}>
                  <Typography textAlign="center">Take to work</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        }
      />
    </>
  )
}

export default AccountsTableToolbar
