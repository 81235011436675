import { FC, useEffect, useState } from 'react'

import FileOpenIcon from '@mui/icons-material/FileOpen'
import Box from '@mui/material/Box'

import { showNotification } from 'utils/showNotification'

interface Props {
  fileName: string
  downloadFile: (data: { filename: string }) => Promise<{ data: Blob }>
}

export const FilePreviewLink: FC<Props> = ({ fileName, downloadFile }) => {
  const [downloadLink, setDownoloadLink] = useState('')
  useEffect(() => {
    const handleDownload = async () => {
      try {
        const data = await downloadFile({ filename: fileName })
        data?.data && setDownoloadLink(URL.createObjectURL(data?.data))
      } catch (e) {
        console.error(e)
        showNotification(`${fileName} file download failed`, 'error')
      }
    }
    handleDownload()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '120px',
        border: '1px solid #007bff',
        borderRadius: '4px',
        padding: 1,
      }}
    >
      <a
        href={downloadLink}
        target="_blank"
        rel="noreferrer"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <FileOpenIcon sx={{ color: '#007bff' }} />
        {fileName}
      </a>
    </Box>
  )
}
