import { MouseEvent, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import useAuth from 'hooks/useAuth'

import Logo from 'assets/logo.png'

import { menuItems } from 'components/main-layout/index'

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const MyAppBar = () => {
  const { user, logout } = useAuth()
  const currentPath = useLocation().pathname
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleSignOut = () => {
    setAnchorElUser(null)
    logout()
  }
  return (
    <AppBar position="fixed" sx={{ background: '#FFFFFF', color: '#222b45' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Box component="img" src={Logo} width={40} height="auto" ml={1} />
          <Typography fontSize="24px" fontWeight={300} ml={4}>
            {menuItems.find((item) => item.link === '/' + currentPath.split('/')[1])?.text}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
          <Tooltip title="User menu">
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={handleOpenUserMenu}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="User Name" src="">
                  {user?.name?.charAt(0).toUpperCase() || ''}
                </Avatar>
              </IconButton>
              <Box sx={{ ml: 2 }}>{user?.name}</Box>
            </Box>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorElUser}
            onClose={handleCloseUserMenu}
          >
            <MenuItem onClick={handleSignOut}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default MyAppBar
