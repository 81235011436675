import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const EDIT_TEMPLATES_SCHEMA = yup.object().shape({
  reportsList: yup.array().of(yup.object().shape({
    beId: yup.number(),
    name: yup.string().required(requiredMessage),
    sqlQuery: yup.string().required(requiredMessage),
  })).required(requiredMessage)
})

export const ADD_TEMPLATE_SCHEMA = yup.object().shape({
  name: yup.string().required(requiredMessage),
  sqlQuery: yup.string().required(requiredMessage)
})