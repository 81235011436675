import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_DATE_OPERATORS,
  createAutocompleteOperator,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetEventGroupDetailsQuery, useGetEventGroupsQuery } from 'services/events/api'

import EventGroupsTableToolbar from 'components/events/eventGroups/EventGroupsTableToolbar/EventGroupsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

export type EventGroupActions = 'ADD' | 'EDIT' | 'EDIT_SINGLE' | ''

const EventGroupsTab = () => {
  const { data: detailsData, isFetching: isDetailsLoading } = useGetEventGroupDetailsQuery()
  const currencyOptions = useMemo(
    () => detailsData?.currencies?.map((v) => ({ value: v.id, label: v.name })),
    [detailsData]
  )
  const ticketSystemOptions = useMemo(
    () => detailsData?.ticketSystem?.map((v) => ({ value: v.id, label: v.name })),
    [detailsData]
  )
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/events/event-groups/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/events/event-groups/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'ticketSystemId',
        headerName: 'Ticket system',
        type: 'singleSelect',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        valueGetter: (data) => {
          return data?.row?.ticketSystem?.name
        },
        renderCell: ({ value }) => {
          return (
            <Box display="flex" alignItems="center">
              {value}
            </Box>
          )
        },
        valueOptions: ticketSystemOptions,
      },
      {
        field: 'country',
        headerName: 'Country',
        type: 'String',
        flex: 1,
        filterOperators: [
          createAutocompleteOperator('/api/events/event-groups/distinct', 'country'),
          createNotEqualsAutocompleteOperator('/api/events/event-groups/distinct', 'country'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'startDate',
        headerName: 'Start Date',
        type: 'Datetime',
        flex: 1,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        type: 'Datetime',
        flex: 1,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'currencyId',
        headerName: 'Currency',
        type: 'singleSelect',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        valueGetter: (data) => {
          return data?.row?.currency?.name
        },
        renderCell: ({ value }) => {
          return (
            <Box display="flex" alignItems="center">
              {value}
            </Box>
          )
        },
        valueOptions: currencyOptions,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'String',
        flex: 1,
        filterOperators: [
          createAutocompleteOperator('/api/events/event-groups/distinct', 'description'),
          createNotEqualsAutocompleteOperator('/api/events/event-groups/distinct', 'description'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Event Group"
              showInMenu
            />,
          ]
        },
      },
    ],
    [ticketSystemOptions]
  )
  const { modal, setModal, singleRow, setSingleRow } = useActions<EventGroupActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetEventGroupsQuery(
    { ...queryParams, showArchived: false },
    { refetchOnMountOrArgChange: true }
  )

  return (
      <Box sx={{ height: 'calc(100vh - 122px)', width: '100%',pt:1 }}>
      <CustomDataGrid<EventGroupActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching || isDetailsLoading}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={EventGroupsTableToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}
export default EventGroupsTab
