import { useState } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField/TextField'
import { GridFilterInputValueProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterInputValueProps'
import { DesktopDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DateRange } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

import { formatDate } from 'utils/dateFormat'
import { isValidDate } from 'utils/dateHelper'

export function InputDateInterval(props: GridFilterInputValueProps) {
  const { item, applyValue } = props
  const [filterValueState, setFilterValueState] = useState<DateRange<Date | null>>(
    item?.value ? [new Date(item.value?.[0]), new Date(item.value?.[1])] : [null, null]
  )

  const updateFilterValue = (lowerBound: Date | null, upperBound: Date | null) => {
    setFilterValueState([lowerBound, upperBound])
    applyValue({
      ...item,
      value: isValidDate(lowerBound) &&
        lowerBound !== null &&
        isValidDate(formatDate(lowerBound)) &&
        isValidDate(upperBound) &&
        upperBound !== null &&
        isValidDate(formatDate(upperBound)) && [formatDate(lowerBound), formatDate(upperBound)],
    })
  }
  const handleLowerFilterChange = (val: DateRange<Date | null>) => {
    updateFilterValue(val[0], val[1])
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        position: 'relative',
        maxWidth: '250px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDateRangePicker
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              variant: 'standard',
            },
          }}
          localeText={{ start: 'From', end: 'To' }}
          value={filterValueState}
          calendars={1}
          onChange={(val) => handleLowerFilterChange(val)}
        />
      </LocalizationProvider>
    </Box>
  )
}
