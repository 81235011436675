import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_DATE_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { useExportReport } from 'hooks/useExportReport'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetUsersQuery, useGetUsersReportMutation } from 'services/users/api'
import { ROLES_TO_SELECT, USER_ROLE } from 'services/users/types'

import CustomDataGrid from 'components/table/DataGrid'
import UsersTableToolbar from 'components/users/UsersTableToolbar'
import ConfirmExportModal from "components/common/ConfirmExportModal/index";
import Typography from "@mui/material/Typography";

export type UsersListActions = 'ADD' | 'EDIT' | 'DELETE' | 'EDIT_SINGLE' | 'DELETE_SINGLE' | ''

const UsersList = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 0.7,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'login',
        headerName: 'Login',
        type: 'String',
        flex: 7.5,
        filterOperators: [
          createAutocompleteOperator('/api/users/distinct', 'login'),
          createNotEqualsAutocompleteOperator('/api/users/distinct', 'login'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'String',
        flex: 4,
        filterOperators: [
          createAutocompleteOperator('/api/users/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/users/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'roleId',
        headerName: 'Role',
        type: 'singleSelect',
        flex: 3.2,
        valueFormatter: ({ value }) => USER_ROLE[value.toString() as keyof typeof USER_ROLE],
        valueOptions: ROLES_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'Datetime',
        flex: 4,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit User"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('DELETE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Delete User"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const [getReport, { isLoading: isDownload }] = useGetUsersReportMutation()
  const { modal, setModal, singleRow, setSingleRow } = useActions<UsersListActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { isModal,setIsModal,handleExport, handleExportButtonClick } = useExportReport({
    queryParams,
    columns,
    getReport,
    reportName: 'Users',
  })
  const { data, isFetching } = useGetUsersQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  return (
      <Box sx={{ height: 'calc(100vh - 122px)', width: '100%',pt:1 }}>
      <CustomDataGrid<UsersListActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching || isDownload}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        onExportButtonClick={handleExportButtonClick}
        toolbar={UsersTableToolbar}
        rowSelectionModel={selectedRows}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
      {isModal && (
          <ConfirmExportModal
              open={true}
              onConfirm={handleExport}
              loading={isDownload}
              onClose={() => {
                setIsModal(false)
              }}
          >
            <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
            >
              Do you want to export only the current page or all the data?
            </Typography>
          </ConfirmExportModal>
      )}
    </Box>
  )
}

export default UsersList
