import { FC } from 'react'

import { Box } from '@mui/material'
import List from '@mui/material/List'

import MenuItem from '../SidebarMenuItem'
import { MenuItemType } from '../index'

interface Props {
  menuItems: MenuItemType[]
}

const SidebarMenu: FC<Props> = ({ menuItems }) => {
  return (
    <Box
      sx={{
        width: '106px',
        backgroundColor: 'white',
        pt: 5,
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mx: 1 }}>
        {menuItems.map((item) => (
          <MenuItem key={item.text} text={item.text} link={item.link} icon={item.icon} />
        ))}
      </List>
    </Box>
  )
}

export default SidebarMenu
