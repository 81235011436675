import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Typography from '@mui/material/Typography/Typography'

import { showNotification } from 'utils/showNotification'

import { useAddSalePlatformMutation, useEditSalePlatformMutation } from 'services/bookings/api'
import { BookingsSalesPlatform } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'

import { SALE_PLATFORM_SCHEMA } from './validation'

interface FormValues {
  name: string
  site?: string | null
  description: string
  username: string
  token?: string | null
}

interface Props {
  onClose: () => void
  platform?: BookingsSalesPlatform
}

const EditSalePlatformModal: FC<Props> = ({ onClose, platform }) => {
  const [addCard, { isLoading: isSaving }] = useAddSalePlatformMutation()
  const [editCard, { isLoading: isUpdate }] = useEditSalePlatformMutation()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(SALE_PLATFORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: platform?.name || '',
      site: platform?.site || '',
      description: platform?.description || '',
      username: '',
      token: '',
    },
  })

  const onSubmit = async (newPlatfrom: FormValues) => {
    if (platform) {
      editCard({ ...newPlatfrom, id: platform?.id })
        .unwrap()
        .then(() => {
          showNotification('Platform updated', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      addCard(newPlatfrom)
        .unwrap()
        .then(() => {
          showNotification('Platform created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  return (
    <AppModal
      title={`${platform ? 'Edit' : 'Add'} sale platform`}
      onClose={onClose}
      loading={isSaving || isUpdate}
    >
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <TextInput name="name" label="Name" errors={errors} control={control} />
          <TextInput name="site" label="Site" errors={errors} control={control} />
          <TextInput
            name="description"
            multiline
            minRows={3}
            label="Description"
            errors={errors}
            control={control}
          />
          <Typography fontWeight={700} fontSize="20px" textAlign="center">
            Sale Platform User
          </Typography>
          <TextInput name="username" label="Username" errors={errors} control={control} />
          <TextInput name="token" label="Password/Token" errors={errors} control={control} />
        </AppModal.ContentBox>

        <AppModal.ButtonsBox>
          <Button loading={isSaving} variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSaving || isUpdate}
            fullWidth={isMobileOnly}
          >
            {platform ? 'Update' : 'Add'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
export default EditSalePlatformModal
