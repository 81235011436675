export const requiredMessage = 'This field is required'
export const numberTypeMessage = 'Must be a number'
export const emailRegexp = new RegExp('^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$')
export const ACCEPTED_FILE_TYPES_BOOKING_CONFIRMATION = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
]
export const ACCEPTED_FILE_TYPES_SEND_TICKETS = ['application/pdf']
export const ACCEPTED_FILE_TYPES_ORDER_SCREENSHOT = ['image/jpeg', 'image/png', 'image/jpg']
