import React, { ChangeEvent, FC } from 'react'
import { Controller } from 'react-hook-form'

import Box, { BoxProps } from '@mui/material/Box'
import Slider from '@mui/material/Slider/Slider'
import TextField from '@mui/material/TextField'

interface Props extends BoxProps {
  control: any
  errors?: any
  name: string
  minInputLabel: string
  maxInputLabel: string
  customErrorMessage?: string
  inputsSize?: 'medium' | 'small'
  minAvailableValue?: number
  maxAvailableValue?: number
  sliderStep?: number
  setValue: any
}

export const RangeSlider: FC<Props> = ({
  control,
  errors,
  name,
  minInputLabel,
  maxInputLabel,
  customErrorMessage,
  inputsSize = 'medium',
  maxAvailableValue = 100,
  minAvailableValue = 0,
  sliderStep = 1,
  setValue,
  sx,
  ...rest
}) => {
  const handleMinChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: [number, number]
  ) => {
    setValue(name, [+e.target.value, val[1]])
  }

  const handleMaxChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: [number, number]
  ) => {
    setValue(name, [val[0], +e.target.value])
  }
  const handleChangeSlider = (e: Event, newValue: number | number[]) => {
    setValue(name, newValue)
  }
  const handleMinBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: [number, number]
  ) => {
    setValue(name, [
      +e.target.value > maxAvailableValue || +e.target.value < minAvailableValue
        ? minAvailableValue
        : +e.target.value,
      val[1],
    ])
  }
  const handleMaxBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: [number, number]
  ) => {
    setValue(name, [
      val[0],
      +e.target.value < minAvailableValue || +e.target.value > maxAvailableValue
        ? maxAvailableValue
        : +e.target.value,
    ])
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: 5,
        width: '100%',
        ...sx,
      }}
      {...rest}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, name, ref } }) => (
          <>
            <TextField
              onChange={(e) => {
                handleMinChangeInput(e, value)
              }}
              value={value[0] ?? ''}
              inputRef={ref}
              label={minInputLabel}
              onBlur={(e) => handleMinBlur(e, value)}
              error={typeof errors === 'string' ? !!errors : !!errors?.[name]}
              helperText={
                customErrorMessage
                  ? customErrorMessage
                  : typeof errors === 'string'
                  ? errors
                  : errors?.[name]?.message
              }
              size={inputsSize}
              type="number"
              fullWidth
              sx={{ height: '70px' }}
            />

            <Slider
              color={'primary'}
              max={maxAvailableValue}
              min={minAvailableValue}
              value={value}
              onChange={(e, newVal) => {
                handleChangeSlider(e, newVal)
              }}
              size={inputsSize}
              valueLabelDisplay="auto"
              step={sliderStep}
            />

            <TextField
              onChange={(e) => {
                handleMaxChangeInput(e, value)
              }}
              value={value[1] ?? ''}
              inputRef={ref}
              label={maxInputLabel}
              onBlur={(e) => {
                handleMaxBlur(e, value)
              }}
              error={typeof errors === 'string' ? !!errors : !!errors?.[name]}
              helperText={
                customErrorMessage
                  ? customErrorMessage
                  : typeof errors === 'string'
                  ? errors
                  : errors?.[name]?.message
              }
              size={inputsSize}
              type="number"
              fullWidth
              sx={{ height: '70px' }}
            />
          </>
        )}
      />
    </Box>
  )
}
