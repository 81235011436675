import { useState, useRef, useEffect} from 'react'

import Box from '@mui/material/Box'
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField'
import { GridFilterInputValueProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterInputValueProps'
import { useGridRootProps } from '@mui/x-data-grid/hooks/utils/useGridRootProps'

export function InputNumberInterval(props: GridFilterInputValueProps) {
  const rootProps = useGridRootProps()
  const { item, applyValue } = props

  const filterTimeout = useRef<any>()
  const [filterValueState, setFilterValueState] = useState<[string, string]>(item.value ?? '')

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined]
    setFilterValueState(itemValue)
  }, [item.value])

  const updateFilterValue = (lowerBound: string, upperBound: string) => {
    clearTimeout(filterTimeout.current)
    setFilterValueState([lowerBound, upperBound])
    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [lowerBound, upperBound] })
    }, rootProps.filterDebounceMs)
  }

  const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
    const newUpperBound = event.target.value
    updateFilterValue(filterValueState[0], newUpperBound)
  }
  const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
    const newLowerBound = event.target.value
    updateFilterValue(newLowerBound, filterValueState[1])
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
      id={item.field}
    >
      <TextField
        name={`lower-bound-input-${item.field}`}
        placeholder="From"
        label="From"
        variant="standard"
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        type="number"
        sx={{ mr: 2 }}
      />
      <TextField
        name={`upper-bound-input-${item.field}`}
        placeholder="To"
        label="To"
        variant="standard"
        value={filterValueState[1]}
        onChange={handleUpperFilterChange}
        type="number"
      />
    </Box>
  )
}
