import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const SALE_PLATFORM_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(requiredMessage),
  site: yup.string().trim().nullable().transform((v) => v ? v : null),
  description: yup.string().trim().required(requiredMessage),
  username: yup.string().trim().required(requiredMessage),
  token: yup.string().trim().nullable().transform((v) => v ? v : null)
})
