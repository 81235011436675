import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const REGISTER_SALE_SCHEMA = yup.object().shape({
  salePriceEur: yup
    .number()
    .typeError('Must be a number')
    .required(requiredMessage)
    .min(0, 'Must be 0 or more'),
  salesPlatformId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  customerName: yup
    .string()
    .trim()
    .transform((value) => (!value ? null : value))
    .nullable(),
  customerEmail: yup
    .string()
    .trim()
    .transform((value) => (!value ? null : value))
    .nullable(),
  sendEmail: yup
    .string()
    .trim()
    .transform((value) => (!value ? null : value))
    .nullable(),
  trackingNumber: yup
    .string()
    .trim()
    .transform((value) => (!value ? null : value))
    .nullable(),
  sendTickets: yup.boolean().required(requiredMessage),
  salesPlatformUserId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  platformOrderId: yup.string().trim().required(requiredMessage),

  tickets: yup
    .array()
    .of(
      yup.object().shape({
        ticketId: yup.number().required(requiredMessage),
        firstName: yup
          .string()
          .trim()
          .transform((value) => (!value ? null : value))
          .nullable()
          .min(2, 'Must have at least 2 characters'),
        lastName: yup
          .string()
          .trim()
          .transform((value) => (!value ? null : value))
          .nullable()
          .min(2, 'Must have at least 2 characters'),
        email: yup
          .string()
          .trim()
          .transform((value) => (!value ? null : value))
          .nullable()
          .min(8, 'Must have at least 8 characters'),
        saleStatusType: yup.number(),
        selected: yup.boolean(),
      })
    )
    .test('atleast-one-selected', 'At least one ticket must be checked', (tickets) => {
      return !!tickets?.filter(ticket => ticket.selected).length
    })
    .required(requiredMessage),
})