import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const addTagSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.object().shape({
        caption: yup
          .string()
          .min(3, 'Minimum 3 characters')
          .max(40, 'Maximum 40 characters')
          .required(requiredMessage),
        color: yup.string().required(requiredMessage),
        beId: yup.number(),
      })
    )
    .required(requiredMessage),
})
