import * as yup from 'yup'

import { numberTypeMessage, requiredMessage } from 'validation/index'

export const EDIT_PROXIES_SCHEMA = yup.object().shape({
  host: yup.string().trim().required(requiredMessage),
  port: yup.string().trim().required(requiredMessage),
  countryId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  username: yup
    .string()
    .trim()
    .required(requiredMessage)
    .transform((v) => (!v ? null : v))
    .nullable(),
  password: yup
    .string()
    .trim()
    .required(requiredMessage)
    .transform((v) => (!v ? null : v))
    .nullable(),
  tags: yup.array().of(yup.string().trim().required(requiredMessage)).required(requiredMessage),
  maxBotsCount: yup
    .number()
    .typeError(numberTypeMessage)
    .required(requiredMessage)
    .min(1, 'Must be >= 1')
    .max(19, 'Must be <= 19'),
})