import React, { useEffect, useRef, useState } from 'react'

import { GridFilterInputValueProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterInputValueProps'
import { useGridRootProps } from '@mui/x-data-grid/hooks/utils/useGridRootProps'

import {
  AutocompleteOption,
  CommonSelectAutocomplete,
} from 'components/common/CustomSelectAutocomplete/CommonSelectAutocomplete/index'

interface CustomAutoCompleteProps extends GridFilterInputValueProps {
  column: string
  columnValue?: string
  onValueChange: (value: string) => void
  optionsUrl: string
}

export function FilterAutoComplete({
  column,
  columnValue,
  item,
  applyValue,
  focusElementRef = null,
  optionsUrl,
}: CustomAutoCompleteProps) {
  const rootProps = useGridRootProps()
  const [value, setValue] = useState<AutocompleteOption | AutocompleteOption[]>(
    item.value?.map((v: string) => ({ value: v, name: v })) ?? []
  )

  const filterTimeout = useRef<any>()

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current)
    }
  }, [])

  useEffect(() => {
    const itemValue = item.value?.map((v: string) => ({ value: v, name: v })) ?? []
    setValue(itemValue)
  }, [item.value])

  const updateFilterValue = (value: AutocompleteOption | AutocompleteOption[]) => {
    clearTimeout(filterTimeout.current)
    if (Array.isArray(value)) {
      filterTimeout.current = setTimeout(() => {
        applyValue({ ...item, value: value?.map((val) => val.value) })
      }, rootProps.filterDebounceMs)
    }
  }
  const onChange = (data: AutocompleteOption | AutocompleteOption[]) => {
    setValue(data)
    updateFilterValue(data)
  }

  return (
    <CommonSelectAutocomplete
      value={value}
      onChange={onChange}
      columnValue={columnValue}
      column={column}
      inputRef={focusElementRef}
      url={optionsUrl}
      multiple={true}
      noOptionsText="Enter name"
      size="medium"
      variant="standard"
      sx={{ height: 'fit-content', width: `250px`, mt: 2 }}
    />
  )
}
