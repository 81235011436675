import { format, parseISO } from 'date-fns'

export const formatToLocaleTimezone = (
  dateString: string | null | undefined,
  dateFormat: string = 'yyyy-MM-dd HH:mm'
): string => {
  if (!dateString) {
    return '-'
  }

  const zonedDate = parseISO(dateString)

  return format(zonedDate, dateFormat)
}

