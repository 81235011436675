import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useAddBookingsCardMutation, useUpdateBookingsCardMutation } from 'services/bookings/api'
import { BookingsCard, CARD_TYPES_OPTIONS } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

import { CARD_SCHEMA } from './validation'

interface FormValues {
  num: string
  name: string
  cvv: string
  month: string
  year: string
  needApprove: boolean
  cardType: {
    value: string
    name: string
  }
  balance: number
}

interface Props {
  onClose: () => void
  card?: BookingsCard
}

const EditCardModal: FC<Props> = ({ onClose, card }) => {
  const [addCard, { isLoading: isSaving }] = useAddBookingsCardMutation()
  const [editCard, { isLoading: isUpdate }] = useUpdateBookingsCardMutation()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(CARD_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      num: card?.num || '',
      name: card?.name || '',
      cvv: card?.cvv || '',
      month: card?.month || '',
      year: card?.year || '',
      needApprove: card?.needApprove || false,
      cardType: {
        value: card?.cardType ? CARD_TYPES_OPTIONS[card.cardType]?.value?.toString() : '0',
        name: card?.cardType ? CARD_TYPES_OPTIONS[card.cardType]?.name : 'Other',
      },
      balance: card?.balance || 0,
    },
  })

  const onSubmit = async (newCard: FormValues) => {
    if (card) {
      editCard({ ...newCard, id: card?.id, cardType: +newCard?.cardType?.value })
        .unwrap()
        .then(() => {
          showNotification('Card updated', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      addCard({ ...newCard, cardType: +newCard?.cardType?.value })
        .unwrap()
        .then(() => {
          showNotification('Card created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  return (
    <AppModal
      title={card ? 'Edit Card' : 'Create Card'}
      onClose={onClose}
      loading={isSaving || isUpdate}
    >
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <TextInput name="num" label="Card number" errors={errors} control={control} />
          <TextInput name="name" label="Holder name" errors={errors} control={control} />
          <TextInput name="cvv" label="CVV code" errors={errors} control={control} />
          <TextInput name="month" label="Expiration month" errors={errors} control={control} />
          <TextInput name="year" label="Expiration year" errors={errors} control={control} />
          <ControlledSelectAutocomplete
            sx={{ mb: 3 }}
            selectOptions={CARD_TYPES_OPTIONS}
            name="cardType"
            label="Card type"
            errors={errors}
            control={control}
          />
          <TextInput name="balance" label="Card balance" errors={errors} control={control} />
          <Checkbox name="needApprove" label="Need approve?" control={control} />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button
            loading={isSaving || isUpdate}
            variant="outlined"
            color="warning"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSaving || isUpdate}
            fullWidth={isMobileOnly}
          >
            {card ? 'Edit' : 'Add'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
export default EditCardModal
