import type { FC } from 'react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import NavigationTabs from 'components/common/NavigationTabs/index'

export const Bots: FC = () => {
  const tabs = [
    { id: 'bots', label: 'BOTS' },
    { id: 'accounts', label: 'ACCOUNTS' },
    { id: 'proxies', label: 'PROXIES' },
  ]
  return (
    <Box sx={{ height: 'calc(100vh - 80px)' }}>
      <NavigationTabs tabs={tabs} basePath="/bots" />
      <Outlet />
    </Box>
  )
}