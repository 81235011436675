import { PostgreSQL, sql } from '@codemirror/lang-sql'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useCreateReportMutation, useGetSqlTemplatesQuery } from 'services/dashboard/api'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { CodeEditor } from 'components/common/CodeEditor'
import Select from 'components/common/Select'

import { CREATE_REPORT_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  setData: any
  setTab: any
}

interface formValues {
  sqlQuery: string
  template?: any
}

export const CreateReportModal = ({ onClose, setData, setTab }: Props) => {
  const { data: templates } = useGetSqlTemplatesQuery()
  const [createReport, { isLoading }] = useCreateReportMutation()

  const TEMPLATES_OPTIONS =
    templates?.reports.map((template) => ({
      value: template.sqlQuery,
      label: template.name,
    })) || []

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(CREATE_REPORT_SCHEMA),
    defaultValues: {
      sqlQuery: '',
      template: '',
    },
  })

  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('template', e.target.value)
    setValue('sqlQuery', e.target.value)
  }

  const handleSqlSet = (e: string) => {
    setValue('sqlQuery', e)
  }
  const onSubmit = ({ sqlQuery }: formValues) => {
    createReport({ sqlQuery })
      .unwrap()
      .then((res) => {
        setData(res)
        showNotification('Report created successfully', 'success')
        setTab('TABLE')
        onClose()
      })
      .catch(() => {
        showNotification('Error. Try again later', 'error')
      })
  }

  return (
    <AppModal title="Create Report" onClose={onClose} maxWidth={600} loading={isLoading}>
      <AppModal.Wrapper
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content', width: '600px' }}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <AppModal.ContentBox sx={{ width: '600px' }}>
          <Select
            control={control}
            name="template"
            label="Template"
            errors={errors}
            options={TEMPLATES_OPTIONS}
            onChange={handleTemplateChange}
          />
          <CodeEditor
            control={control}
            label="SQL Query"
            name="sqlQuery"
            extentions={[sql({ dialect: PostgreSQL })]}
            onChange={handleSqlSet}
            height="300px"
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Create
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
