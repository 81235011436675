import CodeMirror, { Extension } from '@uiw/react-codemirror'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface TextInputProps {
  control: any
  name: string
  label: string
  onChange: any
  errors?: any
  extentions: Extension[]
  height?: string
}

export const CodeEditor: FC<TextInputProps> = ({
  errors,
  control,
  name,
  label,
  onChange,
  extentions,
  height = '500px',
}) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography sx={{ textAlign: 'center', mb: '10px' }} variant="body1">
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => (
          <CodeMirror
            value={value}
            height={height}
            width="100%"
            extensions={extentions}
            onChange={onChange}
          />
        )}
      />
      {errors?.[name]?.message && (
        <Typography sx={{ color: 'red', fontSize: '13px', ml: '20px', mt: '3px' }}>
          {errors?.[name].message}
        </Typography>
      )}
    </Box>
  )
}
