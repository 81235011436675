import { FC, ReactNode } from 'react'
import { Link, useMatch } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

interface MenuItemProps {
  text: string
  icon: ReactNode
  link: string
}

const MenuItem: FC<MenuItemProps> = ({ text, icon, link }) => {
  const match = useMatch({
    path: link,
    end: false,
  })

  return (
    <ListItem component="nav" disablePadding sx={{ mt: 2 }}>
      <Link
        to={link}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: match ? '#007bff' : '#636465',
          backgroundColor: match ? '#f0f0f0' : 'transparent',
        }}
      >
        <ListItemButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 70,
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{ textAlign: 'center' }}
            primaryTypographyProps={{
              sx: {
                fontSize: 12,
              },
            }}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

export default MenuItem
