import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const ADD_EVENT_SCHEMA = yup.object().shape({
  eventGroupId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  name: yup.string().trim().required(requiredMessage),
  timeDifUtc: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  city: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  date: yup.string().trim().required(requiredMessage),
  venue: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  url: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  currencyId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  description: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  categories: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required(requiredMessage),
      priority: yup.number().required(requiredMessage).typeError('Must be a number'),
      price: yup.array().of(yup.number().required(requiredMessage)).required(requiredMessage),
      catId: yup.number(),
    })
  ),
})