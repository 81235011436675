import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { theme } from 'theme'

import './index.css'

import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'

import router from './pages'
import reportWebVitals from './reportWebVitals'
import { store } from './store'

LicenseInfo.setLicenseKey(
  '8538000aa817c96cbb8e37be72d8e8fcTz04NjI2OCxFPTE3NDE4NTM4MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5} preventDuplicate>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
)

reportWebVitals()
