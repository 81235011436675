import React, { FC, ReactNode } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import Box, { BoxProps } from '@mui/material/Box'

import calculateFileSize from 'utils/calculateFileSize'

interface Props {
  file: File
  onRemoveFile: () => void
  icon: ReactNode
  sx?: BoxProps
}

const UploadedFile: FC<Props> = ({ file, onRemoveFile, icon, sx }) => (
  <Box
    onClick={(e) => {
      e.stopPropagation()
    }}
    sx={{
      width: '300px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 1,
      borderRadius: 1,
      border: '1px solid #E9E9EA',
      ...sx,
    }}
  >
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', height: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 1,
          backgroundColor: '#F0F1F3',
          borderRadius: '4px',
        }}
      >
        {icon}
      </Box>
      <Box width="100%" height="40px" ml="12px">
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            maxWidth: '180px',
            fontWeight: 500,
            padding: 0,
            margin: 0,
          }}
        >
          {file.name}
        </Box>
        <Box fontSize="12px" fontWeight={500} color="text.secondary">
          {calculateFileSize(file.size)}
        </Box>
      </Box>
      <ClearIcon sx={{ cursor: 'pointer' }} fontSize="small" onClick={onRemoveFile} />
    </Box>
  </Box>
)

export default UploadedFile
