import domtoimage from 'dom-to-image'
import React, { FC, useState, MouseEvent } from 'react'

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { downloadReportAsExcel } from 'utils/downloadReportAsExel'
import { showNotification } from 'utils/showNotification'

import { useDownloadReportMutation } from 'services/dashboard/api'

import { ReportsTabActions, SelectedTabType } from 'pages/dashboard/ReportsTab'

import { CreateReportModal } from '../CreateReportModal'
import { EditTemplateModal } from '../EditTemplatesModal'

interface Props {
  tab: SelectedTabType
  setTab: React.Dispatch<React.SetStateAction<SelectedTabType>>
  modal: ReportsTabActions
  setModal: React.Dispatch<React.SetStateAction<ReportsTabActions>>
  setData: any
  reportFileName: string
  chartSeriesLength: number
}

export const ReportsTableToolbar: FC<Props> = ({
  tab,
  setTab,
  modal,
  setModal,
  setData,
  reportFileName,
  chartSeriesLength
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [downloadReport] = useDownloadReportMutation()
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onExportButtonClick = async () => {
    if (tab === 'TABLE') {
      downloadReport({ fileName: reportFileName })
        .unwrap()
        .then((blob) => {
          downloadReportAsExcel(blob, reportFileName)
        })
        .catch(() => showNotification('Error. Failed to download report', 'error'))
    } else {
      const chartNode = document?.getElementById('chartToDownload')
      if (chartNode) {
        domtoimage.toJpeg(chartNode, { quality: 1, bgcolor: '#fff' }).then(function (dataUrl) {
          var link = document.createElement('a')
          link.download = 'chart-image.jpeg'
          link.href = dataUrl
          link.click()
        })
      }
    }
  }

  return (
    <>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            size="small"
            onClick={() => setTab('TABLE')}
            variant={tab === 'TABLE' ? 'contained' : 'outlined'}
          >
            Table
          </Button>
          <Button
            size="small"
            onClick={() => setTab('BAR_CHART')}
            variant={tab === 'BAR_CHART' ? 'contained' : 'outlined'}
            disabled={chartSeriesLength > 5}
          >
            Bar Chart
          </Button>
          <Button
            size="small"
            onClick={() => setTab('LINE_CHART')}
            variant={tab === 'LINE_CHART' ? 'contained' : 'outlined'}
            disabled={chartSeriesLength > 5}
          >
            Line Chart
          </Button>
          <Button
            size="small"
            onClick={() => setTab('PIE_CHART')}
            variant={tab === 'PIE_CHART' ? 'contained' : 'outlined'}
            disabled={chartSeriesLength > 5}
          >
            Pie Chart
          </Button>
        </Box>
        <Box>
          <Button
            size="small"
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={onExportButtonClick}
            disabled={!reportFileName}
          >
            Export
          </Button>
          <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
            Actions
          </Button>
          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-toolbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() => {
                  setModal('CREATE_REPORT')
                  handleCloseMenu()
                }}
              >
                <Typography textAlign="center">Create Report</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setModal('EDIT_TEMPLATES')
                  handleCloseMenu()
                }}
              >
                <Typography textAlign="center">Edit Templates</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      {modal === 'CREATE_REPORT' && (
        <CreateReportModal onClose={() => setModal('')} setData={setData} setTab={setTab} />
      )}
      {modal === 'EDIT_TEMPLATES' && <EditTemplateModal onClose={() => setModal('')} />}
    </>
  )
}
