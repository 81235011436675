export const capitalize = (str: string) =>
  str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const capitalizeWords = (str: string): string => {
  if (!str) return '-'

  str = str.replace(/_/g, ' ')
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const truncateText = (text: string, length: number) => {
  return text.length > length ? text.substr(0, length) + '...' : text
}

export const snakeToCamel = (str: string) =>
  str.replace(/(_\w)/g, (match) => match[1].toUpperCase())

export const camelToSnake = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
