import React, { FC, useState } from 'react'
import { Controller } from 'react-hook-form'

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { IconButton, InputAdornment } from '@mui/material'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'

import { capitalize } from 'utils/text'

interface Props extends StandardTextFieldProps {
  control: any
  name: string
  label: string
  errors?: any
  disabled?: boolean
}

const PasswordInput: FC<Props> = ({
  control,
  errors,
  name,
  label,
  disabled,
  size = 'medium',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, ref } }) => (
        <TextField
          {...rest}
          onChange={onChange}
          value={value}
          name={name}
          inputRef={ref}
          label={label}
          size={size}
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: '#797D80' }}
                >
                  {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors?.[name]}
          helperText={errors?.[name]?.message && capitalize(errors[name]?.message)}
          fullWidth
        />
      )}
    />
  )
}

export default PasswordInput
