import { FC, ReactNode } from 'react'

import { Box, Button as MuiButton, ButtonProps } from '@mui/material'

interface Props extends ButtonProps {
  children?: string | ReactNode
  type?: 'submit' | 'button' | 'reset'
  variant?: 'contained' | 'outlined' | 'text'
  disabled?: boolean
  loading?: boolean
}

const Button: FC<Props> = ({
  loading,
  type = 'button',
  children,
  variant = 'contained',
  disabled,
  size = 'medium',
  ...rest
}) => {
  return (
    <MuiButton
      disabled={disabled || loading}
      type={type}
      variant={variant}
      size={size}
      disableRipple={variant === 'text'}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          height: '20px',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Box>
    </MuiButton>
  )
}

export default Button
