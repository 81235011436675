import { numberTypeMessage } from 'validation'
import * as yup from 'yup'

export const VIEW_LOGS_SCHEMA = yup.object().shape({
  logLevel: yup.object().shape({
    value: yup
      .string()
      .trim()
      .nullable()
      .transform((v) => (v ? v : null)),
    name: yup
      .string()
      .trim()
      .nullable()
      .transform((v) => (v ? v : null)),
  }),
  searchString: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
  startDate: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
  endDate: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
  limit: yup
    .number()
    .typeError(numberTypeMessage)
    .max(1000, 'Must be less or equal to 1000')
    .min(1, 'Must be more or equal to 1')
    .nullable()
    .transform((v) => v ?? null),
})