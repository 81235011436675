import React, { FC } from 'react'

import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import TableToolbar from 'components/table/TableToolbar'

const GoalsTableToolbar: FC<Partial<GridToolbarProps & ToolbarPropsOverrides>> = ({
  searchValue,
  onSearchChange,
}) => {
  return (
    <>
      <TableToolbar searchValue={searchValue} onChange={onSearchChange} />
    </>
  )
}

export default GoalsTableToolbar
