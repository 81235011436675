import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { useRemoveBotsFromWorkMutation } from 'services/purchasing/api'

import ConfirmModal from 'components/common/ConfirmModal'
import TableToolbar from 'components/table/TableToolbar'

import { InWorkTabActions } from 'pages/purchasing/InWorkTab/index'

import { EditPassword } from '../EditPassword'
import { RegisterAppModal } from '../RegisterAppModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: InWorkTabActions
  setModal: React.Dispatch<React.SetStateAction<InWorkTabActions>>
  singleRow: any
}

const InWorkTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [removeFromWork] = useRemoveBotsFromWorkMutation()

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    if (selectedRows.length) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>

            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('CONFIRM')
                  }}
                >
                  <Typography textAlign="center">Remove from work</Typography>
                </MenuItem>
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('REGISTER_APP')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Register Application</Typography>
                  </MenuItem>
                )}
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('CHANGE_PASSWORD')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Change Password</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        }
      />
      {(modal === 'CONFIRM' || modal === 'CONFIRM_SINGLE') && (
        <ConfirmModal
          onConfirm={() => {
            removeFromWork({ botsIds: modal === 'CONFIRM' ? selectedRows : [singleRow] })
            setModal('')
          }}
          open={true}
          onClose={() => setModal('')}
        >
          {' '}
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Are you sure you want to remove selected bots from work?
          </Typography>
        </ConfirmModal>
      )}
      {(modal === 'REGISTER_APP' || modal === 'REGISTER_APP_SINGLE') && (
        <RegisterAppModal
          onClose={() => setModal('')}
          botId={modal === 'REGISTER_APP' ? selectedRows[0] : singleRow}
        />
      )}
      {modal === 'CHANGE_PASSWORD' && selectedRows && (
        <EditPassword onClose={() => setModal('')} selectedAccountId={selectedRows[0]} />
      )}
      {modal === 'CHANGE_PASSWORD_SINGLE' && (
        <EditPassword onClose={() => setModal('')} selectedAccountId={singleRow} />
      )}
    </>
  )
}

export default InWorkTableToolbar
