import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import Box from '@mui/material/Box'

import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import { useCreateUserMutation, useUpdateUserMutation } from 'services/users/api'
import { ROLES_TO_SELECT, User, USER_ROLE } from 'services/users/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

import { USER_SCHEMA } from './validation'

interface FormValues {
  login: string
  name: string
  password?: string
  role: {
    value: string
    name: string
  }
}

interface Props {
  onClose: () => void
  user?: User
}

const EditUserModal: FC<Props> = ({ onClose, user }) => {
  const [createUser, { isLoading: isSaving }] = useCreateUserMutation()
  const [updateUser, { isLoading: isUpdate }] = useUpdateUserMutation()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(USER_SCHEMA),
    mode: 'onBlur',
    context: {
      user,
    },
    defaultValues: {
      login: user?.login || '',
      name: user?.name || '',
      password: '',
      role: { value: user?.roleId.toString() || '2', name: user?.role || USER_ROLE['2'] },
    },
  })

  const onSubmit = async (newUser: FormValues) => {
    if (user) {
      updateUser({ ...newUser, role: +newUser.role.value, userId: user?.id })
        .unwrap()
        .then(() => {
          showNotification('User successfully updated', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      createUser({ ...newUser, role: +newUser.role.value })
        .unwrap()
        .then(() => {
          showNotification('User successfully created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  const generatePassword = () => {
    setValue('password', passwordGenerator())
    if (errors.password?.message) clearErrors('password')
  }

  return (
    <AppModal
      title={user ? 'Edit User' : 'Add User'}
      onClose={onClose}
      loading={isSaving || isUpdate}
    >
      <AppModal.Wrapper
        component="form"
        sx={{ height: 'fit-content' }}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <AppModal.ContentBox>
          <TextInput name="name" label="Name" errors={errors} control={control} />
          <TextInput name="login" label="Login" errors={errors} control={control} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <TextInput name="password" label="Password" errors={errors} control={control} />
            <Button variant="text" onClick={generatePassword} sx={{ mx: 1, mt: '4px' }}>
              <AutorenewIcon />
            </Button>
          </Box>
          <ControlledSelectAutocomplete
            name="role"
            label="Role"
            errors={errors}
            control={control}
            selectOptions={ROLES_TO_SELECT}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button
            loading={isSaving || isUpdate}
            color="warning"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSaving || isUpdate}
            fullWidth={isMobileOnly}
          >
            {user ? 'Update' : 'Add'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}

export default EditUserModal
