import { useState } from 'react'

export const useActions = <T extends string = string>() => {
  const [modal, setModal] = useState<T>('' as T)
  const [singleRow, setSingleRow] = useState<any>()
  return {
    modal,
    setModal,
    singleRow,
    setSingleRow,
  }
}
