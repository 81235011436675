export const createSeries = (rows: any[]) => {
  const result = []
  for (const field in rows?.[0]) {
    result.push({ data: rows?.map((row) => row?.[field]), label: field })
  }
  return result
}

export const createPieSeries = (
  yAxis?: {
    data: any[]
    label: string
  },
  xAxis?: {
    data: any[]
    label: string
  }
) => {
  const result: any = [{ data: [], paddingAngle: 4, cornerRadius: 4 }]
  let totalCount = 0
  if (yAxis?.data?.length && xAxis?.data?.length) {
    totalCount = yAxis.data.reduce((prev, next) => prev + next, 0)
    for (let i = 0; i < yAxis?.data.length; i++) {
      result[0].data.push({
        id: i,
        value: yAxis.data[i],
        label: `${xAxis.label} ${xAxis.data[i]} - ${((yAxis.data[i] / totalCount) * 100).toFixed(
          2
        )}%`,
      })
    }
  }
  return result
}