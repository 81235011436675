import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useEditSaleMutation } from 'services/bookings/api'
import { ListingSale } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

import { SPLITING_TYPE_OPTIONS } from '../RegisterOnSaleModal'
import { EDIT_SALE_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  sale?: ListingSale
}

export const EditSaleModal: FC<Props> = ({ onClose, sale }) => {
  const [editSale, { isLoading }] = useEditSaleMutation()
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(EDIT_SALE_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      salePriceEur: sale?.salePrice ?? 0,
      splitType: {
        value: sale?.splitType.toString() ?? SPLITING_TYPE_OPTIONS[0].value.toString(),
        name:
          SPLITING_TYPE_OPTIONS.find((v) => v.value === sale?.splitType.toString())?.name ??
          SPLITING_TYPE_OPTIONS[0].name,
      },
    },
  })

  const onSubmit = async (data: {
    salePriceEur: number
    splitType: {
      value: string
      name: string
    }
  }) => {
    await editSale({
      id: sale?.id!,
      priceChange: data.salePriceEur,
      splitType: +data.splitType.value,
    })
      .unwrap()
      .then(() => {
        showNotification('Sale successfully updated', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }

  return (
    <AppModal onClose={onClose} title="Edit Sale" loading={isLoading}>
      <AppModal.Wrapper
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{
          width: '100%',
          minWidth: 400,
          maxHeight: 'calc(100dvh - 250px)',
          height: 'fit-content',
        }}
      >
        <AppModal.ContentBox>
          <TextInput
            control={control}
            name="salePriceEur"
            label="Sale price, EUR"
            errors={errors}
          />
          <ControlledSelectAutocomplete
            control={control}
            name="splitType"
            selectOptions={SPLITING_TYPE_OPTIONS}
            label="Splitting type"
            errors={errors}
          />
        </AppModal.ContentBox>

        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose} loading={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" fullWidth={isMobileOnly} disabled={isLoading}>
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
