import * as yup from 'yup'

import { emailRegexp, requiredMessage } from 'validation/index'

export const CREATE_ACCOUNT_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(emailRegexp, 'This field must be email'),
  password: yup.string().trim().required(requiredMessage).min(4, 'Min 4 symbols'),
  recoveryEmail: yup.string().trim().required(requiredMessage),
  domainId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  countryId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
})