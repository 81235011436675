import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useCreateBotsBulkMutation, useGetBotsCreateQuery } from 'services/bots/api'

import { CREATE_BOTS_BULK_SCHEMA } from 'components/bots/bots/CreateBotsModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

interface Props {
  onClose: () => void
}

interface FormValues {
  quantity: number
  ticketSystemId: {
    value: string
    name: string
  }
  proxyTags?:
    | {
        value?: string
        name?: string
      }[]
    | null
}

export const CreateBotsModal: FC<Props> = ({ onClose }) => {
  const { data } = useGetBotsCreateQuery()
  const [createBotsBulk, { isLoading }] = useCreateBotsBulkMutation()

  const proxyTagsOptions = useMemo(
    () => data?.proxyTags.map((v) => ({ name: v, value: v })) || [],
    [data]
  )
  const ticketsSystemsOptions = useMemo(
    () => data?.ticketSystems.map((v) => ({ name: v.name, value: v.id + '' })),
    [data]
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(CREATE_BOTS_BULK_SCHEMA),
    defaultValues: {
      quantity: 1000,
      ticketSystemId: {
        value: '',
        name: '',
      },
      proxyTags: [],
    },
  })
  const onSubmit = async ({ quantity, ticketSystemId, proxyTags }: FormValues) => {
    await createBotsBulk({
      quantity,
      ticketSystemId: +ticketSystemId.value,
      proxyTags: proxyTags?.map((value) => value?.name) || null,
    })
      .unwrap()
      .then(() => {
        showNotification('Bot successfully created.', 'success')
        onClose()
      })
      .catch(() => showNotification('Error.Try again later', 'error'))
  }
  return (
    <AppModal title="Create Bots" onClose={onClose} loading={isLoading}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <ControlledSelectAutocomplete
            name="ticketSystemId"
            label="Ticket System"
            control={control}
            errors={errors}
            selectOptions={ticketsSystemsOptions}
          />
          <ControlledSelectAutocomplete
            name="proxyTags"
            label="Proxy Tags"
            control={control}
            selectOptions={proxyTagsOptions}
            multiple={true}
          />
          <TextInput name="quantity" label="Number Of Bots" control={control} />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button onClick={onClose} variant="outlined" color="warning">
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}