export interface User {
  createdAt: Date
  id: number
  login: string
  name: string
  role: 'Admin' | 'Manager' | 'Operator'
  roleId: 0 | 1 | 2
}

export interface NewUser {
  login: string
  name: string
  password?: string
  role: number
}

export const USER_ROLE = {
  '0': 'Admin',
  '1': 'Manager',
  '2': 'Operator',
} as const

export const ROLES_TO_SELECT = Object.entries(USER_ROLE).map(([value, name]) => ({
  value,
  name,
  label: name,
}))

export interface UsersLogItem {
  apiMethod: LogsMethodType
  apiSection: LogsSectionType
  apiUri: string
  createdAt: Date
  description: string
  id: number
  user: User
}

export const LOGS_METHOD_TYPE = {
  '10': 'GET',
  '20': 'POST',
  '30': 'PUT',
  '40': 'DELETE',
} as const

export const LOGS_METHOD_OPTIONS_TO_SELECT = Object.entries(LOGS_METHOD_TYPE).map(
  ([value, label]) => ({
    value,
    label,
  })
)

export type LogsMethodType = keyof typeof LOGS_METHOD_TYPE

export const LOGS_SECTION_TYPE = {
  '10': 'Bookings',
  '20': 'Events',
  '30': 'Bots',
  '40': 'Utility',
  '50': 'Users',
} as const

export const LOGS_SECTION_TYPE_TO_SELECT = Object.entries(LOGS_SECTION_TYPE).map(
  ([value, label]) => ({
    value,
    label,
  })
)

export type LogsSectionType = keyof typeof LOGS_SECTION_TYPE

export enum rolesEnum {
  Admin = 0,
  Manager = 1,
  Operator = 2,
}
