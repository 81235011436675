import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { showNotification } from 'utils/showNotification'

import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetAccountsQuery, useTakeBotsToWorkMutation } from 'services/purchasing/api'
import { BOT_STATUSES, BotStatus } from 'services/purchasing/types'

import { CopyToClipboard } from 'components/common/CopyToClipboard/index'
import { DetailsModal } from 'components/common/DetailsModal'
import AccountsTableToolbar from 'components/purchasing/accounts/AccountsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

const AccountsTab = () => {
  const [isHighlight, setIsHighlight] = useState<boolean>(false)
  const handleCellHighlight = (field: string) => {
    console.log(field)
    if (['password', 'login'].includes(field)) {
      setIsHighlight(true)
    }
  }
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'archivedStatus',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        flex: 2,
        type: 'singleSelect',
        valueOptions: BOT_STATUSES,
        valueFormatter: ({ value }) => BotStatus[value as keyof typeof BotStatus],
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'account.email',
        headerName: 'Bot User',
        type: 'String',
        flex: 4,
        valueGetter: (row) => row.row.login,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/bots/distinct', 'account.email'),
          createNotEqualsAutocompleteOperator('/api/purchasing/bots/distinct', 'account.email'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        renderCell: ({ row }) => (
          <CopyToClipboard
            handleCellHighlight={handleCellHighlight}
            value={row?.account?.email}
            field="login"
          />
        ),
      },
      {
        field: 'password',
        headerName: 'Bot password',
        sortable: false,
        type: 'String',
        flex: 2,
        filterable: false,
        renderCell: ({ row }) => (
          <CopyToClipboard
            handleCellHighlight={handleCellHighlight}
            value={row?.password}
            field="password"
          />
        ),
      },
      {
        field: 'ticket_system.name',
        headerName: 'Bot Source',
        flex: 3,
        type: 'String',
        valueGetter: ({ row }) => row?.ticketSystem?.name,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/bots/distinct', 'ticket_system.name'),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/bots/distinct',
            'ticket_system.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              handleDetailsClick(params.row)
            }}
          >
            Show Details
          </Button>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={async () => {
                await takeToWork({ botsIds: [params.row.id] })
                  .then(() => {
                    navigate('../in-work')
                    showNotification(
                      `Bot with id ${params.row.id} successfully taken to work`,
                      'success'
                    )
                  })
                  .catch(() => showNotification('Error. Try again later', 'error'))
              }}
              label="Take to work"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const navigate = useNavigate()
  const [takeToWork] = useTakeBotsToWorkMutation()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, setSelectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetAccountsQuery({ ...queryParams, showTaken: false })
  const [modal, setModal] = useState<'INFO' | ''>('')

  const handleDetailsClick = useCallback((row: any) => {
    setModal('INFO')
    setSelectedRows([row])
  }, [])

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={selectedRows}
        toolbar={AccountsTableToolbar}
        checkboxSelection
        onCellClick={() => setIsHighlight(false)}
        sx={{
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: isHighlight ? '' : 'none',
          },
        }}
      />
      {modal === 'INFO' && (
        <DetailsModal
          onClose={() => {
            setModal('')
            setSelectedRows([])
          }}
          item={selectedRows[0]}
        />
      )}
    </Box>
  )
}

export default AccountsTab
