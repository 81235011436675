import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { AddCircleOutline } from '@mui/icons-material'
import { Box, Button, Chip, ListItem, Typography } from '@mui/material'

import { TICKET_AUTOCOMPLETE_SCHEMA } from 'components/bookings/listings/CreateListingModal/TicketAutocomplete/validation'
import { internalTickets } from 'components/bookings/listings/CreateListingModal/index'
import { AppModal } from 'components/common/AppModal/index'
import TextInput from 'components/common/TextInput/index'

interface FormValues {
  seat?: string | null
  row?: string | null
  section?: string | null
}

interface TicketsAutocompleteProps {
  append: (val: FormValues) => void
  remove: (ind: number) => void
  replace: any
  update: any
  currentTickets: {
    seat?: string | null
    row?: string | null
    section?: string | null
    beId?: number | null
  }[]
  listingErrors: any
}

export const TicketsAutocomplete: FC<TicketsAutocompleteProps> = ({
  append,
  remove,
  update,
  currentTickets,
  listingErrors,
}) => {
  const [isAddTicketDialogOpen, setIsAddTicketDialogOpen] = useState<boolean>(false)

  const [ticketToEdit, setTicketToEdit] = useState<{
    seat?: string | null
    row?: string | null
    section?: string | null
    beId?: number | null
    ind: number
  }>()

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(TICKET_AUTOCOMPLETE_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      seat: '',
      row: '',
      section: '',
    },
  })
  const onSubmit = (data: FormValues) => {
    if (ticketToEdit) {
      update(ticketToEdit.ind, { ...data, beId: ticketToEdit.beId })
      setIsAddTicketDialogOpen(false)
    } else {
      append(data)
      setIsAddTicketDialogOpen(false)
    }
    setTicketToEdit(undefined)
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '100%',
            p: 1,
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.26)',
            borderRadius: '0.3rem',
            minHeight: 56,
          }}
        >
          {!currentTickets?.length && (
            <Typography sx={{ opacity: 0.6, alignSelf: 'center' }}>Add tickets</Typography>
          )}
          <Box
            component="ul"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              overflowX: 'auto',
              listStyle: 'none',
              whiteSpace: 'nowrap',
              padding: 0,
              marginBlock: 'auto',
              marginLeft: '0.5rem',
              gap: '0.5rem',
            }}
          >
            {currentTickets.map((ticket: internalTickets, index) => (
              <ListItem
                key={ticket.id}
                sx={{
                  width: 'auto',
                  maxWidth: 200,
                  padding: '0 0 0 0.25rem',
                  display: 'flex',
                }}
              >
                <Chip
                  label={`Seat: ${ticket.seat} Row: ${ticket.row} Sec.: ${ticket.section}`}
                  onDelete={
                    //@ts-ignore
                    ticket?.saleStatusType === 0 || ticket?.saleStatusType === undefined
                      ? () => remove(index)
                      : undefined
                  }
                  onClick={() => {
                    setIsAddTicketDialogOpen(true)
                    reset(ticket)
                    setTicketToEdit({ ...ticket, ind: index })
                  }}
                />
              </ListItem>
            ))}
          </Box>
          <Button
            sx={{ minWidth: 90, borderRadius: '20px', marginLeft: 2 }}
            variant="outlined"
            size="small"
            onClick={() => {
              setIsAddTicketDialogOpen(true),
                reset({
                  seat: '',
                  row: '',
                  section: '',
                })
            }}
            startIcon={<AddCircleOutline />}
          >
            Ticket
          </Button>
        </Box>
        {listingErrors?.internalTickets?.message && (
          <Box mt={1}>
            <Typography variant="body2" color="error">
              {listingErrors?.internalTickets?.message}
            </Typography>
          </Box>
        )}
      </Box>

      {isAddTicketDialogOpen && (
        <AppModal
          title={ticketToEdit ? 'Edit ticket' : 'Add ticket to listing'}
          onClose={() => {
            setIsAddTicketDialogOpen(false)
            setTicketToEdit(undefined)
          }}
          sx={{ marginTop: '300px' }}
        >
          <AppModal.Wrapper
            sx={{ width: '300px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
            component="form"
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleSubmit(onSubmit)()
            }}
          >
            <AppModal.ContentBox sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <TextInput name="seat" label="Seat" errors={errors} control={control} />
              <TextInput name="row" label="Row" errors={errors} control={control} />
              <TextInput name="section" label="Section" errors={errors} control={control} />
            </AppModal.ContentBox>

            <AppModal.ButtonsBox>
              <Button variant="contained" size="small" type="submit">
                {ticketToEdit ? 'Edit' : 'Add'} Ticket
              </Button>
            </AppModal.ButtonsBox>
          </AppModal.Wrapper>
        </AppModal>
      )}
    </>
  )
}
