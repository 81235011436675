import { PostgreSQL, sql } from '@codemirror/lang-sql'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { CodeEditor } from 'components/common/CodeEditor'
import TextInput from 'components/common/TextInput'

import { ADD_TEMPLATE_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  append?: any
  update?: any
  templateIndex?: number
  templateToEdit?: {
    beId?: number
    name: string
    sqlQuery: string
  }
}

interface FormValues {
  name: string
  sqlQuery: string
}

export const AddTemplateModal = ({
  onClose,
  append,
  update,
  templateIndex,
  templateToEdit,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(ADD_TEMPLATE_SCHEMA),
    defaultValues: {
      name: templateToEdit?.name || '',
      sqlQuery: templateToEdit?.sqlQuery || '',
    },
  })
  const handleSqlSet = (e: string) => {
    setValue('sqlQuery', e)
  }

  const onSubmit = (formValues: FormValues) => {
    if (append) append(formValues)
    if (update) {
      update(templateIndex, {
        ...(templateToEdit?.beId && { beId: templateToEdit.beId }),
        ...formValues,
      })
    }
    onClose()
  }

  return (
    <AppModal
      onClose={onClose}
      title={`${templateIndex || templateIndex === 0 ? 'Edit' : 'Add'} template`}
      maxWidth={750}
    >
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          height: 'fit-content',
          maxHeight: 'calc(100dvh - 250px)',
          width: '700px',

        }}
      >
        <AppModal.ContentBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '700px'
          }}
        >
          <TextInput label="Template title" name="name" control={control} errors={errors} />
          <CodeEditor
            control={control}
            name="sqlQuery"
            label="SQL code"
            height="300px"
            extentions={[sql({ dialect: PostgreSQL })]}
            onChange={handleSqlSet}
            errors={errors}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning">
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {templateIndex || templateIndex === 0 ? 'Edit' : 'Add'} Template
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
