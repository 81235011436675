import AxiosInterceptor from 'api'
import { AuthProvider } from 'context/AuthContext'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

const Root = () => {
  return (
    <>
      <AuthProvider>
        <CssBaseline />
        <AxiosInterceptor />
        <Outlet />
        <ScrollRestoration />
      </AuthProvider>
    </>
  )
}

export default Root
