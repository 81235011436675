import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { showNotification } from 'utils/showNotification'

import { useAddTagsMutation, useGetTagsQuery } from 'services/bookings/api'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput/index'

import { addTagSchema } from './validation'

export interface SingleTag {
  color: string
  caption: string
  beId?: number
}

interface FormValues {
  data: SingleTag[]
}

interface Props {
  onClose: () => void
}

const CreateTagModal: FC<Props> = ({ onClose }) => {
  const { data } = useGetTagsQuery()
  const [editTag, { isLoading: isEditLoading }] = useAddTagsMutation()
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      data:
        data?.map((v) => ({
          color: v.color || '#000000',
          caption: v.label,
          beId: v.id,
        })) || [],
    },
    resolver: yupResolver(addTagSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  useEffect(() => {
    if (data) {
      setValue(
        'data',
        data?.map((v) => ({
          color: v.color || '#000000',
          caption: v.label,
          beId: v.id,
        }))
      )
    }
  }, [data])

  const onSubmit = (data: FormValues) => {
    editTag({
      data: data.data.map((tag) => ({ color: tag.color, id: tag.beId, caption: tag.caption })),
    })
      .unwrap()
      .then(() => {
        showNotification('Tags successfully updated', 'success')
        onClose()
      })
      .catch(() => {
        showNotification('Error. Try again later', 'error')
      })
  }
  return (
    <AppModal title="Edit Tags" onClose={onClose} loading={isEditLoading}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: 600,
          maxHeight: 'calc(100dvh - 250px)',
          height: 'fit-content',
          overflowY: 'hidden',
        }}
      >
        <AppModal.ContentBox sx={{ width: 600 }}>
          {fields.map((v, ind) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
              key={v.id}
            >
              <TextInput
                name={`data.${ind}.color`}
                label=""
                errors={errors?.data?.[ind]?.color?.message}
                control={control}
                type={'color'}
                style={{ width: 80, padding: 0 }}
              />
              <TextInput
                name={`data.${ind}.caption`}
                label="Caption"
                errors={errors?.data?.[ind]?.caption?.message}
                control={control}
              />
              <IconButton
                sx={{ alignSelf: 'start' }}
                aria-label="delete"
                size="large"
                color="warning"
                onClick={() => {
                  remove(ind)
                }}
              >
                <DeleteIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
            </Box>
          ))}
        </AppModal.ContentBox>

        <AppModal.ButtonsBox sx={{ justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            disabled={isEditLoading}
            onClick={() => {
              append({ color: '#000000', caption: '' })
            }}
          >
            Add Tag
          </Button>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="warning" onClick={onClose} disabled={isEditLoading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth={isMobileOnly}
              disabled={isEditLoading}
              type="submit"
            >
              Update
            </Button>
          </Box>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}

export default CreateTagModal