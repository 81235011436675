import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import Box from '@mui/material/Box'

import useAuth from 'hooks/useAuth'

import { rolesEnum } from 'services/users/types'

import AppBar from 'components/main-layout/AppBar'
import SidebarMenu from 'components/main-layout/SidebarMenu'

export interface MenuItemType {
  text: string
  icon: ReactNode
  link: string
  allowedRoles: number[]
}

// export const oldURL = 'https://ticketscpl-dev.wecandevelopit.com'

export const menuItems: MenuItemType[] = [
  {
    text: 'Bot Dashboard',
    icon: <PrecisionManufacturingIcon />,
    link: `/bot-dashboard`,
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager],
  },
  {
    text: 'Mails',
    icon: <MailOutlineIcon />,
    link: `/mails`,
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager],
  },
  {
    text: 'Bookings',
    icon: <FormatListBulletedIcon />,
    link: `/bookings`,
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager],
  },
  {
    text: 'Events',
    icon: <EventNoteOutlinedIcon />,
    link: `/events`,
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager],
  },
  {
    text: 'Bots',
    icon: <SmartToyOutlinedIcon />,
    link: `/bots`,
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager],
  },
  {
    text: 'Users',
    icon: <PeopleOutlineOutlinedIcon />,
    link: `/users`,
    allowedRoles: [rolesEnum.Admin],
  },
  {
    text: 'Purchasing',
    icon: <AttachMoneyOutlinedIcon />,
    link: '/purchasing',
    allowedRoles: [rolesEnum.Admin, rolesEnum.Manager, rolesEnum.Operator],
  },
]

const MainLayout = () => {
  const { user } = useAuth()
  const filteredMenuItems = menuItems.filter(
    (item) => user && item.allowedRoles.includes(user?.roleId)
  )
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar />
      <SidebarMenu menuItems={filteredMenuItems} />
      <Box component="main" pt={8} sx={{ width: 'calc(100% - 56px)' }}>
        <Box
          sx={{
            background: '#FFFFFF',
            width: '100%',
            minHeight: 'calc(100vh - 66px)',
            pr: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayout
