import { ACCEPTED_FILE_TYPES_SEND_TICKETS, requiredMessage } from 'validation'
import * as yup from 'yup'

export const SEND_TICKETS_SCHEMA = yup.object().shape({
  tickets: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(requiredMessage),
        linkedFile: yup
          .mixed()
          .test('fileSize', 'Maximum size is 2MB', (value: any) => {
            if (typeof value === 'string') return true // without new attachment
            if (!value?.length) return true // attachment is optional
            return value?.[0]?.size <= 2 * 2 ** 20
          })
          .test('fileType', 'File must be of .pdf type', (value: any) => {
            if (typeof value === 'string') return true // without new attachment
            if (!value?.length) return true // attachment is optional
            return ACCEPTED_FILE_TYPES_SEND_TICKETS.includes(value?.[0].type)
          })
          .transform((v) => (Array.isArray(v) && v.length ? v : null))
          .nullable(),

        holderFirstName: yup
          .string()
          .trim()
          .transform((v) => (v ? v : null))
          .nullable(),
        holderLastName: yup
          .string()
          .trim()
          .transform((v) => (v ? v : null))
          .nullable(),
        holderEmail: yup
          .string()
          .trim()
          .min(7, 'Field must have at least 7 characters')
          .required(requiredMessage),
        send: yup.boolean().required(requiredMessage),
      })
    )
    .required(requiredMessage),
  accountDetails: yup
    .object()
    .shape({
      email: yup.string().required(requiredMessage),
      password: yup.string().required(requiredMessage),
    })
    .required(requiredMessage),
  platformOrderId: yup
    .string()
    .transform((v) => v || null)
    .nullable(),
  send: yup.boolean().required(requiredMessage),
  message: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
})
