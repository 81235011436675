import React, {useCallback, useMemo, useState} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { GridColDef } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetBotsAccountsQuery } from 'services/bots/api'

import { AccountsBotsTableToolbar } from 'components/bots/accounts/AccountsBotsTableToolbar/AccountsBotsTableToolbar'
import { CopyToClipboard } from 'components/common/CopyToClipboard/index'
import { DetailsModal } from 'components/common/DetailsModal'
import CustomDataGrid from 'components/table/DataGrid'

export type AccountsTabActions = 'ADD' | 'INFO' | ''
export const AccountsBotTab = () => {
  const [isHighlight, setIsHighlight] = useState<boolean>(false)
  const handleCellHighlight = (field: string) => {
    console.log(field)
    if (['password', 'email'].includes(field)) {
      setIsHighlight(true)
    }
  }
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'String',
        flex: 4,
        filterOperators: [
          createAutocompleteOperator('/api/bots/accounts/distinct', 'email'),
          createNotEqualsAutocompleteOperator('/api/bots/accounts/distinct', 'email'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        renderCell: ({ row }) => <CopyToClipboard value={row?.email} field="email" handleCellHighlight={handleCellHighlight}/>,
      },
      {
        field: 'password',
        headerName: 'Password',
        type: 'String',
        flex: 2,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => <CopyToClipboard value={row?.password} field="password" handleCellHighlight={handleCellHighlight}/>,
      },
      {
        field: 'country.name',
        headerName: 'Country',
        flex: 3,
        type: 'String',
        valueGetter: ({ row }) => row.country.name,
        filterOperators: [
          createAutocompleteOperator('/api/bots/accounts/distinct', 'country.name'),
          createNotEqualsAutocompleteOperator('/api/bots/accounts/distinct', 'country.name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'details',
        headerName: 'Details',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              handleDetailsClick(params.row)
            }}
          >
            Show Details
          </Button>
        ),
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetBotsAccountsQuery(queryParams)
  const { modal, setModal, singleRow, setSingleRow } = useActions<AccountsTabActions>()

  const handleDetailsClick = useCallback((row: any) => {
    setModal('INFO')
    setSingleRow(row)
  }, [])
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<AccountsTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        onRowSelectionModelChange={handleRowSelection}
        {...paginationDataGridProps}
        selectedRows={selectedRows}
        toolbar={AccountsBotsTableToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        onCellClick={() => setIsHighlight(false)}
        sx={{
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: isHighlight ? '' : 'none',
          },
        }}
      />
      {modal === 'INFO' && (
        <DetailsModal
          onClose={() => {
            setModal('')
            setSingleRow(null)
          }}
          item={singleRow}
        />
      )}
    </Box>
  )
}
