import { format } from 'date-fns'

import { showNotification } from './showNotification'

export const downloadReportAsExcel = (blob: Blob, fileName: string) => {
  try {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}_${format(new Date(), 'dd-MM-yyyy_HH-mm')}.xlsx`)
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }

    window.URL.revokeObjectURL(url)
  } catch (error) {
    showNotification('Error. Try again later', 'error')
  }
}
