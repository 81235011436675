import { FC, useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'

import { showNotification } from 'utils/showNotification'

interface Props {
  fileName: string
  downloadFile?: (data: { filename: string; disposition: string }) => Promise<{ data: Blob }>
  isEditForm: boolean
  sx?: BoxProps
}

const DownloadedFile: FC<Props> = ({ fileName, downloadFile, isEditForm, sx }) => {
  const [downloadLink, setDownoloadLink] = useState('')
  useEffect(() => {
    if (downloadFile) {
      const handleDownload = async () => {
        try {
          const data = await downloadFile({ filename: fileName, disposition: 'inline' })
          setDownoloadLink(URL.createObjectURL(data.data))
        } catch (e) {
          console.error(e)
          showNotification(`${fileName} file download failed`, 'error')
        }
      }
      isEditForm && handleDownload()
    }
  }, [])

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      component="label"
      htmlFor="link"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {downloadLink ? (
          <a id="link" href={downloadLink} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        ) : (
          <Typography fontSize="16px">{fileName}</Typography>
        )}
      </Box>
    </Box>
  )
}

export default DownloadedFile