import { QueryTableState } from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import {
  Account,
  Bot,
  CreateAccount,
  CreateBot,
  Proxy,
  Country,
  EditProxies,
  CreateProxies,
  CountriesTransform,
  MailDomainsTransform,
  EditBot,
  GetBotCreate,
  GetBotEdit,
  AssignCardType,
  CreateBotsBulk,
} from 'services/bots/types'

const botsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBots: build.query<TableServerResponse<Bot[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/bots/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Bots', id: 'BOTS' }],
    }),

    getBotsCreate: build.query<GetBotCreate, void>({
      query: () => ({
        url: `/api/bots/create`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Bots', id: 'BOTS' }],
    }),

    createBot: build.mutation<{ msg: string }, CreateBot>({
      query: (body) => ({
        url: '/api/bots',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Bots', id: 'BOTS' }],
    }),
    getEditBot: build.query<GetBotEdit, { id: number }>({
      query: ({ id }) => ({
        url: `/api/bots/edit?bot_id=${id}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Bots', id: 'BOTS' }],
    }),
    editBot: build.mutation<{ msg: string }, EditBot>({
      query: (body) => ({
        url: '/api/bots',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'Bots', id: 'BOTS' }],
    }),
    createBotsBulk: build.mutation<{ msg: string }, CreateBotsBulk>({
      query: (body) => ({
        url: '/api/bots/bulk',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Bots', id: 'BOTS' }],
    }),

    deleteBots: build.mutation<{ msg: string }, { botIds: number[] }>({
      query: (body) => ({
        url: '/api/bots',
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: [{ type: 'Bots', id: 'BOTS' }],
    }),
    assignBotsCard: build.mutation<{ msg: string }, AssignCardType>({
      query: (body) => ({
        url: '/api/bots/cards',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Bots', id: 'BOTS' }],
    }),

    getBotsCardTypesAndAccountNames: build.query<
      { cardTypes: string[]; cardNames: string[] },
      void
    >({
      query: () => ({
        url: '/api/bots/cards',
        method: 'GET',
      }),
      transformResponse: (res: { data: { cardTypes: string[]; cardNames: string[] } }) => res?.data,
    }),

    getBotsAccounts: build.query<TableServerResponse<Account[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/accounts/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Accounts', id: 'ACCOUNTS' }],
    }),

    createBotsAccount: build.mutation<{ msg: string }, CreateAccount>({
      query: (body) => ({
        url: '/api/bots/account',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'ACCOUNTS' }],
    }),

    getMailDomains: build.query<MailDomainsTransform[], void>({
      query: () => ({
        url: '/api/utility/mail-domains',
        method: 'GET',
      }),
      transformResponse: (response: { mailDomains: { id: number; name: string }[] }) =>
        response.mailDomains.map((v: { id: number; name: string }) => ({
          value: v.id?.toString(),
          name: v.name,
        })) || [],
    }),

    getCountries: build.query<CountriesTransform[], void>({
      query: () => ({
        url: '/api/utility/countries',
        method: 'GET',
      }),
      transformResponse: (response: { countries: Country[] }) =>
        response.countries.map((v: Country) => ({
          value: v.id?.toString(),
          name: v.name,
        })) || [],
    }),

    getProxies: build.query<TableServerResponse<Proxy[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/proxies/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Proxies', id: 'PROXIES' }],
    }),
    addProxies: build.mutation<{ msg: string }, CreateProxies>({
      query: (body) => ({
        url: '/api/proxies',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Proxies', id: 'PROXIES' }],
    }),
    editProxy: build.mutation<{ msg: string }, EditProxies>({
      query: (body) => ({
        url: '/api/proxies',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'Proxies', id: 'PROXIES' }],
    }),
    deleteProxies: build.mutation<{ msg: string }, { proxyIds: [] }>({
      query: (body) => ({
        url: '/api/proxies',
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: [{ type: 'Proxies', id: 'PROXIES' }],
    }),
  }),
})
export const {
  useGetBotsQuery,
  useDeleteBotsMutation,
  useAssignBotsCardMutation,
  useGetBotsCardTypesAndAccountNamesQuery,
  useGetBotsCreateQuery,
  useCreateBotMutation,
  useCreateBotsBulkMutation,
  useEditBotMutation,
  useGetEditBotQuery,
  useGetBotsAccountsQuery,
  useCreateBotsAccountMutation,
  useGetProxiesQuery,
  useGetCountriesQuery,
  useGetMailDomainsQuery,
  useAddProxiesMutation,
  useEditProxyMutation,
  useDeleteProxiesMutation,
} = botsApi