import axios, { AxiosError, AxiosResponse } from 'axios'
import { useLayoutEffect } from 'react'

import useAuth from 'hooks/useAuth'

export const baseURL = process.env.REACT_APP_API_URL

export const axiosApi = axios.create({
  baseURL,
  withCredentials: true,
})

export const AxiosInterceptor = () => {
  const { logout } = useAuth()

  useLayoutEffect(() => {
    const responseInterceptor = axiosApi.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          console.log('AxiosInterceptor 401')
          logout()
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosApi.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return null
}

export default AxiosInterceptor
