import { yupResolver } from '@hookform/resolvers/yup'
import React, {useEffect, useRef} from 'react'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'

import { showNotification } from 'utils/showNotification'

import {useLazyViewBotsLogsQuery} from 'services/dashboard/api'
import { BotLog, LOG_LEVELS, LOG_LEVELS_OPTIONS } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import DatePicker from 'components/common/DatePicker'
import TextInput from 'components/common/TextInput'

import { VIEW_LOGS_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  log: BotLog
}

const COLOR_BY_MESSAGE_TYPE = {
  '0': 'blue',
  '10': 'green',
  '20': '#007bff',
  '30': 'orange',
  '40': 'red',
  '50': 'crimson',
}

interface FormValues {
  logLevel: {
    value?: string | null
    name?: string | null
  }
  searchString?: string | null
  startDate?: string | null
  endDate?: string | null
  limit?: number | null
}

export const ViewLogsModal = ({ onClose, log }: Props) => {
  const [getLogs, { isLoading, data }] = useLazyViewBotsLogsQuery()
  const divRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    getLogs({
      botId: log.botDetails.id,
      logLevel: 0,
      searchString: null,
      startDate: null,
      endDate: null,
      limit: 500,
    })
  }, [])


  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [data?.logs.length]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(VIEW_LOGS_SCHEMA),
    defaultValues: {
      logLevel: {
        value: '',
        name: '',
      },
      searchString: '',
      startDate: '',
      endDate: '',
      limit: 500,
    },
  })

  const onSubmit = async (data: FormValues) => {
    await getLogs({
      botId: log.botDetails.id,
      ...data,
      logLevel:
        (data?.logLevel?.value && +data?.logLevel?.value) || data.logLevel.value === '0'
          ? +data?.logLevel?.value
          : null,
    })
      .unwrap()
      .catch(() => showNotification('Error. Try again later.', 'error'))
  }
  return (
    <AppModal title="Logs" onClose={onClose} maxWidth={750} loading={isLoading}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ height: 'fit-content', width: '100%', maxHeight: '80vh' }}
      >
        <AppModal.ContentBox sx={{ width: '100%', maxWidth: 750 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <ControlledSelectAutocomplete
              control={control}
              name="logLevel"
              label="Log Level"
              errors={errors}
              selectOptions={LOG_LEVELS_OPTIONS}
              sx={{ flex: 1 }}
            />
            <TextInput
              control={control}
              name="limit"
              label="Limit"
              errors={errors}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextInput control={control} name="searchString" label="Search" errors={errors} />

          <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
            <DatePicker control={control} name="startDate" label="From" errors={errors} />
            <DatePicker control={control} name="endDate" label="To" errors={errors} />
          </Box>
          <Button type="submit">Update</Button>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 2,
              padding: 2,
              border: '1px solid #007bff',
              borderRadius: '4px',
              maxHeight:'50vh',
              overflowY:'auto'
            }}
            ref={divRef}
          >
            {data?.logs?.length ? (
              <Typography component="code">
                {[...data.logs].reverse().map((log) => (
                  <Typography
                    key={log.logText + log.createdAt}
                    component="p"
                    fontFamily="monospace"
                    fontSize={14}
                  >
                    <Typography component="span" color="blue" fontSize={14}>
                      {log.createdAt}
                    </Typography>{' '}
                    <Typography
                      component="span"
                      color={
                        COLOR_BY_MESSAGE_TYPE[
                          (log.logLevel + '') as keyof typeof COLOR_BY_MESSAGE_TYPE
                        ]
                      }
                      fontSize={14}
                    >
                      {LOG_LEVELS[(log.logLevel + '') as keyof typeof COLOR_BY_MESSAGE_TYPE]}
                    </Typography>
                    :{' '}
                    <Typography
                      component="span"
                      color={
                        COLOR_BY_MESSAGE_TYPE[
                          (log.logLevel + '') as keyof typeof COLOR_BY_MESSAGE_TYPE
                        ]
                      }
                      fontSize={14}
                    >
                      {log.logText}
                    </Typography>
                  </Typography>
                ))}
              </Typography>
            ) : (
              <Typography width="100%" textAlign="center">
                No data
              </Typography>
            )}
          </Box>
        </AppModal.ContentBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
