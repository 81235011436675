import {
  ACCEPTED_FILE_TYPES_BOOKING_CONFIRMATION,
  ACCEPTED_FILE_TYPES_ORDER_SCREENSHOT,
  requiredMessage,
} from 'validation'
import * as yup from 'yup'
import { ValidationError } from 'yup'

export const NEW_LISTING_SCHEMA = yup.object().shape({
  event: yup.lazy((val) => {
    if (typeof val === 'string') return yup.string().required(requiredMessage)
    else
      return yup
        .object()
        .shape({
          name: yup.string(),
          id: yup.string(),
          categories: yup.array().of(
            yup.object().shape({
              name: yup.string(),
              id: yup.string(),
            })
          ),
        })
        .test('event-test', requiredMessage, (value) => {
          return value && (value.name || value.id)
            ? true
            : new ValidationError(requiredMessage, {}, 'event')
        })
  }),
  user: yup.lazy((val) => {
    if (typeof val === 'string') return yup.string().required(requiredMessage)
    else
      return yup
        .object()
        .shape({
          name: yup.string().required(requiredMessage),
          value: yup.string().required(requiredMessage),
        })
        .test('user-test', requiredMessage, (value) => {
          return value && (value.name || value.value)
            ? true
            : new ValidationError(requiredMessage, {}, 'user')
        })
        .required(requiredMessage)
  }),
  // password: yup.string().required(requiredMessage),
  orderedDateCet: yup.string().required(requiredMessage),
  numberOfTickets: yup.number().required(requiredMessage).typeError('Only numeric values'),
  eventCategoryId: yup.object().shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
  }),
  cardId: yup.object().shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
  }),
  note: yup.string(),
  bookingConfirmationFilename: yup
    .mixed<string | File[]>()
    .required(requiredMessage)
    .test('fileSize', 'Maximum size is 2MB', (value: any) => {
      if (typeof value === 'string') return true
      return value?.[0]?.size <= 2 * 2 ** 20
    })
    .test('fileType', 'File must be of .pdf,.jpg, jpeg or png type', (value: any) => {
      if (typeof value === 'string') return true
      return ACCEPTED_FILE_TYPES_BOOKING_CONFIRMATION.includes(value?.[0]?.type)
    }),
  orderScreenshotFilename: yup
    .mixed<string | File[]>()
    .required(requiredMessage)
    .test('fileSize', 'Maximum size is 2MB', (value: any) => {
      if (typeof value === 'string') return true
      return value?.[0]?.size <= 2 * 2 ** 20
    })
    .test('fileType', 'File must be of .jpg, jpeg, png type', (value: any) => {
      if (typeof value === 'string') return true
      return ACCEPTED_FILE_TYPES_ORDER_SCREENSHOT.includes(value?.[0]?.type)
    }),
})
