import React, { FC } from 'react'
import { Controller } from 'react-hook-form'

import {
  CommonSelectAutocomplete,
  CommonSelectAutocompleteProps,
} from 'components/common/CustomSelectAutocomplete/CommonSelectAutocomplete/index'

export interface AutocompleteOption {
  name: string
  value: string
}

interface Props extends Omit<CommonSelectAutocompleteProps, 'value' | 'onChange'> {
  control: any
  name: string
  value?: AutocompleteOption | AutocompleteOption[]
  onChange?: (value: AutocompleteOption | AutocompleteOption[]) => void
}

export const ControlledSelectAutocomplete: FC<Props> = ({ name, control, onChange, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: controlOnChange, value, ref, name } }) => {
        return (
          <CommonSelectAutocomplete
            {...rest}
            name={name}
            value={value}
            onChange={onChange || controlOnChange}
            inputRef={ref}
          />
        )
      }}
    />
  )
}
