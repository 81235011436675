import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetSalePlatformsQuery } from 'services/bookings/api'

import BookingsSalePlatfromTableToolbar from 'components/bookings/SalesPlatforms/SalesPlatformsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

const INTEGRATION_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]
export type SalePlatformsActions = 'ADD' | 'EDIT' | 'EDIT_SINGLE' | ''
const SalePlatforms = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'Platform ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'name',
        headerName: 'Platform Name',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/sales-platforms/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/bookings/sales-platforms/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'site',
        headerName: 'Site',
        type: 'String',
        flex: 3,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/sales-platforms/distinct', 'site'),
          createNotEqualsAutocompleteOperator('/api/bookings/sales-platforms/distinct', 'site'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'String',
        flex: 4,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/sales-platforms/distinct', 'description'),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/sales-platforms/distinct',
            'description'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'integration',
        headerName: 'Integration',
        type: 'singleSelect',
        flex: 0.7,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueOptions: INTEGRATION_OPTIONS,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ value }) => (
          <Box display="flex" alignItems="center">
            {value ? 'Yes' : 'No'}
          </Box>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Platform"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const { modal, setModal, singleRow, setSingleRow } = useActions<SalePlatformsActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetSalePlatformsQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<SalePlatformsActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={BookingsSalePlatfromTableToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}
export default SalePlatforms
