import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import InfoIcon from '@mui/icons-material/Info'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { showNotification } from 'utils/showNotification'

import { useEditGoalsEventMutation } from 'services/events/api'
import { EventCategoryStat, GoalsModes } from 'services/events/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button/index'
import TextInput from 'components/common/TextInput/index'
import { setGoalsSchema } from 'components/events/events/SetGoalsModal/validation'

interface FormValues {
  data: {
    beId: number
    name: string
    settings: {
      1: number
      2: number
      3: number
      4: number
      5: number
      6: number
    }
    priority: number
    goalsMode?: number | null
  }[]
}

interface Props {
  onClose: () => void
  goalsData: EventCategoryStat[]
}

const SetGoalsModal: FC<Props> = ({ onClose, goalsData }) => {
  const [editGoals, { isLoading }] = useEditGoalsEventMutation()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(setGoalsSchema),
    defaultValues: {
      data:
        goalsData?.map((v) => ({
          beId: v.id || 1,
          name: v.name || '',
          settings: {
            '1': v.settings['1'] || 0,
            '2': v.settings['2'] || v.settings['2'] === 0 ? v.settings['2'] : -1,
            '3': v.settings['3'] || v.settings['3'] === 0 ? v.settings['3'] : -1,
            '4': v.settings['4'] || v.settings['4'] === 0 ? v.settings['4'] : -1,
            '5': v.settings['5'] || v.settings['5'] === 0 ? v.settings['5'] : -1,
            '6': v.settings['6'] || v.settings['6'] === 0 ? v.settings['6'] : -1,
          },
          priority: v.priority || 1,

          goalsMode: v.goalsMode || GoalsModes.DISABLED,
        })) || [],
    },
  })
  const { fields, update } = useFieldArray({
    name: 'data',
    control: control,
  })

  const onSubmit = async (data: FormValues) => {
    const requestData = data.data.map((v: any) => ({
      id: v.beId,
      priority: v.priority || 1,
      settings: {
        '1': v.settings['1'] || 0,
        '2': v.settings['2'] || v.settings['2'] === 0 ? v.settings['2'] : -1,
        '3': v.settings['3'] || v.settings['3'] === 0 ? v.settings['3'] : -1,
        '4': v.settings['4'] || v.settings['4'] === 0 ? v.settings['4'] : -1,
        '5': v.settings['5'] || v.settings['5'] === 0 ? v.settings['5'] : -1,
        '6': v.settings['6'] || v.settings['6'] === 0 ? v.settings['6'] : -1,
      },
      goalsMode: v.goalsMode,
    }))
    editGoals({ settingGoals: requestData })
      .unwrap()
      .then(() => {
        showNotification('Changes saved', 'success')
        onClose()
      })
      .catch(() => {
        showNotification('Error. Try again later', 'error')
      })
  }

  return (
    <AppModal title="Set Goals" onClose={onClose} loading={isLoading}>
      <AppModal.Wrapper
        sx={{ width: '600px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <AppModal.ContentBox sx={{ width: '100%' }}>
          <Typography variant="subtitle2" sx={{ alignSelf: 'start', width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                p: 1,
                gap: 2,
              }}
            >
              <InfoIcon color="primary" />
              <Typography variant="body2">-1 - buy while available</Typography>
            </Box>
          </Typography>
          {fields?.map((goal, ind) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              key={goal.id}
            >
              <Typography variant={'h6'} sx={{ mt: 1 }}>
                {goal.name}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', m: 3 }}>
                {/* update(ticketToEdit.ind, { ...data, beId: ticketToEdit.beId }) */}
                <Button
                  onClick={() => update(ind, { ...goal, goalsMode: GoalsModes.ON_HOLD })}
                  variant={
                    goal.goalsMode === GoalsModes.HIGH ||
                    goal.goalsMode === GoalsModes.LOW ||
                    goal.goalsMode === GoalsModes.ON_HOLD
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  Automatic
                </Button>

                <Button
                  onClick={() => update(ind, { ...goal, goalsMode: GoalsModes.MANUAL })}
                  variant={goal.goalsMode === GoalsModes.MANUAL ? 'contained' : 'outlined'}
                >
                  Manual
                </Button>
                <Button
                  onClick={() => update(ind, { ...goal, goalsMode: GoalsModes.DISABLED })}
                  variant={goal.goalsMode === GoalsModes.DISABLED ? 'contained' : 'outlined'}
                >
                  Disabled
                </Button>
              </Box>
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 1.5 }}>
                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.1`}
                    label={'1*'}
                    errors={errors?.data?.[ind]?.settings?.['1']?.message}
                    type="number"
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    sx={{ width: '100%', textAlign: 'start' }}
                  />

                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.2`}
                    label={'2*'}
                    errors={errors?.data?.[ind]?.settings?.['2']?.message}
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    type="number"
                    sx={{ width: '100%' }}
                  />
                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.3`}
                    label={'3*'}
                    errors={errors?.data?.[ind]?.settings?.['3']?.message}
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    type="number"
                    sx={{ width: '100%' }}
                  />
                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.4`}
                    label={'4*'}
                    errors={errors?.data?.[ind]?.settings?.['4']?.message}
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    type="number"
                    sx={{ width: '100%' }}
                  />
                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.5`}
                    label={'5*'}
                    errors={errors?.data?.[ind]?.settings?.['5']?.message}
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    type="number"
                    sx={{ width: '100%' }}
                  />
                  <TextInput
                    control={control}
                    name={`data.${ind}.settings.6`}
                    label={'6*'}
                    errors={errors?.data?.[ind]?.settings?.['6']?.message}
                    disabled={goal.goalsMode !== GoalsModes.MANUAL}
                    type="number"
                    sx={{ width: '100%' }}
                  />
                </Box>
                <TextInput
                  control={control}
                  name={`data.${ind}.priority`}
                  label={'Priority'}
                  errors={errors?.data?.[ind]?.priority?.message}
                  type="number"
                  sx={{ width: '100%' }}
                />
              </Box>
              <Divider sx={{ mt: 1, width: '100%' }} />
            </Box>
          ))}
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button
            color="warning"
            variant="outlined"
            fullWidth={isMobileOnly}
            type="button"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            fullWidth={isMobileOnly}
            type="submit"
          >
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}

export default SetGoalsModal
