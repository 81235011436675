import {
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterInputSingleSelect,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid'

import { InputDateInterval } from 'components/table/BettweenDateFilter/BetweenDateFilter'
import { InputNumberInterval } from 'components/table/BetweenFilter'
import { DateInput } from 'components/table/DateInput/DateInput'
import { FilterAutoComplete } from 'components/table/FilterAutoComplete'

//NUMBER
export const EQUAL_NUMBER_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridNumericOperators().filter((operator) => ['='].includes(operator.value))

export const NOT_EQUAL_NUMBER_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridNumericOperators()
    .filter((operator) => ['='].includes(operator.value))
    .map((operator) => ({
      ...operator,
      label: '!=',
      value: '!=',
      valueType: 'number',
    }))

export const MORE_OR_EQUAL_NUMBER_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridNumericOperators().filter((operator) => ['>='].includes(operator.value))

export const LESS_OR_EQUAL_NUMBER_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridNumericOperators().filter((operator) => ['<='].includes(operator.value))

export const BETWEEN_NUMBER_OPERATOR: GridFilterOperator<any, number> = {
  label: 'Between',
  value: 'between',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
      return null
    }
    if (filterItem.value[0] == null || filterItem.value[1] == null) {
      return null
    }
    return ({ value }: any) => {
      return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1]
    }
  },
  InputComponent: InputNumberInterval,
}
export const ALL_NUMBER_OPERATORS = [
  ...EQUAL_NUMBER_OPERATOR,
  ...MORE_OR_EQUAL_NUMBER_OPERATOR,
  ...LESS_OR_EQUAL_NUMBER_OPERATOR,
  ...NOT_EQUAL_NUMBER_OPERATOR,
  BETWEEN_NUMBER_OPERATOR,
]

//STRING
export const EQUAL_STRING_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridStringOperators()
    .filter((operator) => ['equals'].includes(operator.value))
    .map((operator) => ({
      ...operator,
      label: 'Equals',
      value: '=',
      valueType: 'string',
    }))

export const NOT_EQUAL_STRING_OPERATOR: GridFilterOperator<any, string | number | null, any>[] =
  getGridStringOperators()
    .filter((operator) => ['equals'].includes(operator.value))
    .map((operator) => ({
      ...operator,
      label: 'Not equals',
      value: '!=',
      valueType: 'string',
    }))

export const CONTAINS_STRING_OPERATOR: GridFilterOperator<any, string | null | number>[] =
  getGridStringOperators()
    .filter((operator) => ['contains'].includes(operator.value))
    .map((operator) => ({
      ...operator,
      label: 'Contains',
      value: '~',
      valueType: 'string',
    }))

export const NOT_CONTAINS_STRING_OPERATOR: GridFilterOperator<any, string | null | number>[] =
  getGridStringOperators()
    .filter((operator) => ['contains'].includes(operator.value))
    .map((operator) => ({
      ...operator,
      label: 'Not contains',
      value: '!~',
      valueType: 'string',
    }))

export const ALL_STRING_OPERATORS = [
  ...EQUAL_STRING_OPERATOR,
  ...NOT_EQUAL_STRING_OPERATOR,
  ...CONTAINS_STRING_OPERATOR,
  ...NOT_CONTAINS_STRING_OPERATOR,
]
//DATE
export const TO_DATE_OPERATOR: GridFilterOperator<any, Date, any> = {
  label: 'To',
  value: '<=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null
    }
    return ({ value }): boolean => {
      return value !== null && value !== undefined && +new Date(filterItem.value[0]) === +value
    }
  },
  InputComponent: DateInput,
}
export const FROM_DATE_OPERATOR: GridFilterOperator<any, Date, any> = {
  label: 'From',
  value: '>=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null
    }
    return ({ value }): boolean => {
      return value !== null && value !== undefined && +new Date(filterItem.value[0]) === +value
    }
  },
  InputComponent: DateInput,
}

export const BETWEEN_DATE_OPERATOR: GridFilterOperator<any, string> = {
  label: 'Between',
  value: 'between',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
      return null
    }
    if (filterItem.value[0] == null || filterItem.value[1] == null) {
      return null
    }
    return ({ value }): boolean => {
      return (
        value !== null &&
        value !== undefined &&
        +new Date(filterItem.value[0]) <= +value &&
        +value <= +new Date(filterItem.value[1])
      )
    }
  },
  InputComponent: InputDateInterval,
}

export const ALL_DATE_OPERATORS = [FROM_DATE_OPERATOR, TO_DATE_OPERATOR, BETWEEN_DATE_OPERATOR]

//OPTION
export const createAutocompleteOperator = (
  optionsUrl: string,
  column: string,
  columnValue?: string
): GridFilterOperator<any, any, any> => ({
  label: 'Equals(Search)',
  value: '=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value)) {
      return null
    }
    //@ts-ignore
    return ({ value }) => {
      return value !== null
    }
  },
  InputComponent: FilterAutoComplete,
  InputComponentProps: {
    column: column,
    columnValue: columnValue,
    optionsUrl: optionsUrl,
  },
})

export const createNotEqualsAutocompleteOperator = (
  optionsUrl: string,
  column: string,
  columnValue?: string
): GridFilterOperator<any, any, any> => ({
  label: 'Not equals(Search)',
  value: '!=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value)) {
      return null
    }
    //@ts-ignore
    return ({ value }) => {
      return value !== null
    }
  },
  InputComponent: FilterAutoComplete,
  InputComponentProps: {
    column: column,
    columnValue: columnValue,
    optionsUrl: optionsUrl,
  },
})

export const SELECT_OPERATOR = {
  label: 'Equals',
  value: '=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    if (Array.isArray(filterItem.value)) {
      return ({ value }: GridFilterItem) => {
        return filterItem.value.includes(value)
      }
    } else {
      return ({ value }: GridFilterItem) => {
        return value === filterItem.value
      }
    }
  },
  InputComponent: GridFilterInputSingleSelect,
}

export const NOT_EQUAL_SELECT_OPERATOR = {
  label: 'Not equals',
  value: '!=',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    if (Array.isArray(filterItem.value)) {
      return ({ value }: GridFilterItem) => {
        return filterItem.value.includes(value)
      }
    } else {
      return ({ value }: GridFilterItem) => {
        return value === filterItem.value
      }
    }
  },
  InputComponent: GridFilterInputSingleSelect,
}
