import React, { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import { DialogProps } from '@mui/material/Dialog/Dialog'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'

interface Props extends DialogProps {
  children: ReactNode
  loading?: boolean
  confirm?: string
  cancel?: string
  onConfirm: () => void
  onClose: () => void
}

const ConfirmModal: FC<Props> = ({
  children,
  confirm = 'Yes',
  cancel = 'No',
  loading,
  onClose,
  onConfirm,
  open,
}) => {
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirm()
  }

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClose()
  }

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  return (
    <Modal
      open={open}
      onClick={handleModalClick}
      onDoubleClick={(e) => e.stopPropagation()}
      onClose={onCancel}
    >
      <Box padding="24px" maxWidth="350px">
        {children}
        <Box sx={{ mt: 2 }}>
          <Button
            loading={loading}
            onClick={onSubmit}
            variant="contained"
            color="warning"
            fullWidth
          >
            {confirm}
          </Button>
          <Button onClick={onCancel} variant="outlined" fullWidth sx={{ mt: 1 }}>
            {cancel}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmModal
