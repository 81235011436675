import { ElementType, ReactNode } from 'react'
import { isMobileOnly } from 'react-device-detect'

import CloseIcon from '@mui/icons-material/Close'
import { Box, BoxProps, CircularProgress, SxProps, Typography } from '@mui/material'

import Modal from 'components/common/Modal'

interface Props {
  title: string
  children: ReactNode
  onClose: () => void
  maxWidth?: number
  loading?: boolean
  sx?: SxProps
}

export const AppModal = ({ title, children, onClose, maxWidth, loading, sx }: Props) => {
  return (
    <Modal
      open={true}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose()
        }
      }}
      fullScreen={isMobileOnly}
      onDoubleClick={(e) => e.stopPropagation()}
      sx={sx}
    >
      <Box
        maxWidth={isMobileOnly ? '100%' : `${maxWidth}px` || '450px'}
        sx={{
          height: isMobileOnly ? '100dvh' : undefined,
          width: 'fit-content',
          position: 'relative',
          overflow: loading ? 'hidden' : 'auto',
        }}
      >
        <Box
          borderBottom="1px solid #E9E9EA"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box textAlign="center" maxWidth={maxWidth ? `${maxWidth - 100}px` : '350px'}>
            <Typography
              fontSize="18px"
              mr={1}
              fontWeight={600}
              lineHeight={1.44}
              noWrap
              pl={2}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {title}
            </Typography>
          </Box>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box
          onClick={(e) => {
            if (loading) {
              e.preventDefault()
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }
          }}
          sx={
            loading
              ? {
                  bgcolor: 'rgba(0,0,0,0.2)',
                  zIndex: 999999,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }
              : {}
          }
        >
          {loading && (
            <CircularProgress
              sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }}
            />
          )}
        </Box>
        {children}
      </Box>
    </Modal>
  )
}

const Wrapper = <C extends ElementType>({
  children,
  sx,
  ...rest
}: BoxProps<C, { component?: C }>) => (
  <Box
    {...rest}
    sx={{
      width: '100%',
      height: isMobileOnly ? 'calc(100dvh - 80px)' : '70dvh',
      display: 'flex',
      flexDirection: 'column',
      ...sx,
    }}
  >
    {children}
  </Box>
)
AppModal.Wrapper = Wrapper

const ContentBox = <C extends ElementType>({
  children,
  sx,
  ...rest
}: BoxProps<C, { component?: C }>) => (
  <Box
    {...rest}
    sx={{
      display: 'flex',
      width: '400px',
      flexDirection: 'column',
      flex: isMobileOnly ? 1 : 'unset',
      overflow: 'auto',
      gap: 2,
      px: 2,
      py: 4,
      ...sx,
    }}
  >
    {children}
  </Box>
)
AppModal.ContentBox = ContentBox

interface ButtonsBoxProps extends BoxProps {
  children: ReactNode
}

const ButtonsBox = ({ children, sx, ...rest }: ButtonsBoxProps) => (
  <Box
    {...rest}
    display="flex"
    p={2}
    gap={2}
    borderTop="1px solid #E9E9EA"
    sx={
      isMobileOnly
        ? {
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white',
            zIndex: 1,
            ...sx,
          }
        : {
            justifyContent: 'flex-end',
            ...sx,
          }
    }
  >
    {children}
  </Box>
)
AppModal.ButtonsBox = ButtonsBox
