import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'
import { SendType } from 'services/bookings/types'

interface Props {
  onClose: () => void
  isLoading: boolean
  control: any
  handleSubmit: any
  onSubmit: any
}

export const SaveAndSendModal = ({ onClose, isLoading, control, onSubmit, handleSubmit }: Props) => {
  return (
    <AppModal title="Send email" onClose={onClose} loading={isLoading} sx={{mt: 25}}>
      <AppModal.Wrapper sx={{ maxHeight: '80vh', height: 'fit-content' }}>
        <AppModal.ContentBox>
          <TextInput control={control} name="message" label="Email body" multiline minRows={2} />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            fullWidth={isMobileOnly}
            onClick={handleSubmit(onSubmit(SendType.MARK_AS_SENT))}
          >
            Mark as sent
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            fullWidth={isMobileOnly}
            onClick={handleSubmit(onSubmit(SendType.SEND_EMAIL))}
          >
            Send Email
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
