import { ReportRequest } from 'types/ReportFileRequest'
import { QueryTableState} from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import { NewUser, User, UsersLogItem } from './types'

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<TableServerResponse<User[]>, QueryTableState>({
      query: (payload) => ({
        url: '/api/users/paging',
        method: 'POST',
        data: payload,
      }),
      providesTags: () => [{ type: 'Users', id: 'LIST' }],
    }),
    createUser: build.mutation<{ msg: string }, NewUser>({
      query: (body) => ({
        url: '/api/users',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
    }),
    updateUser: build.mutation<{ msg: string }, NewUser & { userId: number }>({
      query: (body) => ({
        url: `/api/users`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
    }),
    deleteUsers: build.mutation<{ msg: string }, { usersIds: number[] }>({
      query: (body) => ({
        url: `/api/users`,
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Users', id: 'LIST' }],
    }),
    getUsersReport: build.mutation<Blob, ReportRequest>({
      query: (body) => ({
        url: '/api/users/report',
        method: 'POST',
        data: body,
        responseType: 'blob',
      }),
    }),
    getUsersLogs: build.query<TableServerResponse<UsersLogItem[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/users/logs/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'UsersLogs', id: 'LIST' }],
    }),
    getLogsReport: build.mutation<Blob, ReportRequest>({
      query: (body) => ({
        url: '/api/users/logs/report',
        method: 'POST',
        data: body,
        responseType: 'blob',
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUsersLogsQuery,
  useCreateUserMutation,
  useGetUsersReportMutation,
  useGetLogsReportMutation,
  useUpdateUserMutation,
  useDeleteUsersMutation,
} = usersApi
