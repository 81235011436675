import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useDeleteUsersMutation } from 'services/users/api'
import { User } from 'services/users/types'

import ConfirmModal from 'components/common/ConfirmModal'
import TableToolbar from 'components/table/TableToolbar'

import { UsersListActions } from 'pages/users/List/index'

import EditUserModal from '../EditUserModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: UsersListActions
  setModal: React.Dispatch<React.SetStateAction<UsersListActions>>
  singleRow: any
}

const UsersTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [deleteUsers, { isLoading }] = useDeleteUsersMutation()

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const confirmDelete = async () => {
    if (modal === 'DELETE') {
      deleteUsers({ usersIds: selectedRows.map((item: User) => item.id) })
        .unwrap()
        .then(() => {
          showNotification(
            selectedRows.length === 1
              ? `User successfully deleted`
              : `Selected users successfully deleted`,
            'success'
          )
          setModal('')
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      await deleteUsers({ usersIds: [singleRow.id] })
        .unwrap()
        .then(() => {
          showNotification(`User successfully deleted`, 'success')
          setModal('')
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  const confirmMessage =
    modal === 'DELETE'
      ? selectedRows.length > 1
        ? 'Are you sure you want to delete selected users?'
        : 'Are you sure you want to delete selected user?'
      : 'Are you sure you want to delete selected user?'
  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>

            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('ADD')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Add User</Typography>
                </MenuItem>
                {selectedRows?.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit User</Typography>
                  </MenuItem>
                ) : null}
                {selectedRows?.length ? (
                  <MenuItem
                    onClick={() => {
                      setModal('DELETE')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">
                      Delete User{selectedRows?.length > 1 && 's'}
                    </Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
          </Box>
        }
      />

      {modal === 'ADD' && <EditUserModal onClose={() => setModal('')} />}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditUserModal
          user={modal === 'EDIT' ? selectedRows[0] : singleRow}
          onClose={() => setModal('')}
        />
      )}
      {(modal === 'DELETE' || modal === 'DELETE_SINGLE') && (
        <ConfirmModal
          open={true}
          onConfirm={confirmDelete}
          loading={isLoading}
          onClose={() => {
            setModal('')
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
    </>
  )
}

export default UsersTableToolbar
