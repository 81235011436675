import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_DATE_OPERATORS,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetListingsQuery } from 'services/purchasing/api'

import ListingsTableToolbar from 'components/purchasing/listings/ListingsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

export type ListingsTabActions = 'ADD' | 'EDIT' | 'EDIT_SINGLE' | ''
const ListingsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'Booking ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'event_category.event.name',
        headerName: 'Event',
        type: 'string',
        flex: 3,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.eventName,

        filterOperators: [
          createAutocompleteOperator(
            '/api/purchasing/listings/distinct',
            'event_category.event.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/listings/distinct',
            'event_category.event.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'orderedDateCet',
        headerName: 'Order Date (CET)',
        type: 'Datetime',
        flex: 1.7,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'totalTicketsHp',
        headerName: 'Total Tickets',
        type: 'Number',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'numberOfTickets',
        headerName: 'Must be Tickets',
        flex: 1,
        type: 'Number',
        headerAlign: 'center',
        align: 'center',

        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'event_category.event.event_group.name',
        headerName: 'Event group name',
        type: 'String',
        flex: 3,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.eventGroupName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/purchasing/listings/distinct',
            'event_category.event.event_group.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/listings/distinct',
            'event_category.event.event_group.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'eventCategory.name',
        headerName: 'Category',
        type: 'String',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory.name,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/listings/distinct', 'event_category.name'),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/listings/distinct',
            'event_category.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'user',
        headerName: 'User',
        type: 'String',
        flex: 2,
        valueGetter: ({ value }) => value.username,
        // @ts-ignore
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/listings/distinct', 'user'),
          createNotEqualsAutocompleteOperator('/api/purchasing/listings/distinct', 'user'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'card.num',
        headerName: 'Card used',
        type: 'String',
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: ({ row }) => row?.card?.num,
      },
      {
        field: 'note',
        headerName: 'Note',
        type: 'String',
        flex: 1,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/listings/distinct', 'note'),
          createNotEqualsAutocompleteOperator('/api/purchasing/listings/distinct', 'note'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Listing"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const { modal, setModal, singleRow, setSingleRow } = useActions<ListingsTabActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetListingsQuery(
    {
      ...queryParams,
      showArchived: false,
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
      <Box sx={{ height: 'calc(100vh - 122px)', width: '100%',pt:1 }}>
      <CustomDataGrid<ListingsTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={ListingsTableToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}

export default ListingsTab
