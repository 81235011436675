import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { StandardTextFieldProps, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

export interface SelectOption {
  value: string | number
  label: string
}

interface Props extends StandardTextFieldProps {
  control: any
  name: string
  label: string
  errors: any
  options?: SelectOption[]
  disabled?: boolean
}

const Select: FC<Props> = ({
  control,
  errors,
  name,
  label,
  options = [],
  disabled,
  size = 'medium',
  defaultValue,
  onChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: controllerOnChange, name, value, ref } }) => (
        <TextField
          sx={{ height: '70px' }}
          onChange={onChange ?? controllerOnChange}
          value={value}
          name={name}
          inputRef={ref}
          label={label}
          fullWidth
          error={typeof errors === 'string' ? !!errors : !!errors?.[name]}
          helperText={typeof errors === 'string' ? errors : errors?.[name]?.message}
          size={size}
          select
          disabled={disabled}
          defaultValue={defaultValue}
          {...rest}
        >
          {options?.map((o, index) => (
            <MenuItem key={index} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

export default Select
