import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs from 'components/common/NavigationTabs'

const tabs = [
  { id: 'list', label: 'LIST' },
  { id: 'logs', label: 'LOGS' },
]

const Users: FC = () => {
  return (
    <Box>
      <NavigationTabs tabs={tabs} basePath="/users" />
      <Outlet />
    </Box>
  )
}
export default Users
