import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EventIcon from '@mui/icons-material/Event'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PersonIcon from '@mui/icons-material/Person'
import SellIcon from '@mui/icons-material/Sell'
import { Box, Checkbox, Chip, Divider, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'
import { showNotification } from 'utils/showNotification'

import {
  useDeleteBookingListingMutation,
  useDeleteSaleMutation,
  useGetBookingListingByIdQuery,
} from 'services/bookings/api'
import { ListingSale } from 'services/bookings/types'

import ManageListingTable from 'components/bookings/listings/ManageListingTable/index'
import Button from 'components/common/Button'
import ConfirmModal from 'components/common/ConfirmModal'

import {
  CreateListingModal,
  TICKET_COLOR_MAP,
  TICKET_FORM_MAP,
  TicketFormResponseType,
} from '../CreateListingModal'
import { EditSaleModal } from '../EditSaleModal/EditSaleModal'
import { RegisterOnSaleModal, SPLITING_TYPE_OPTIONS } from '../RegisterOnSaleModal'
import { RegisterSaleModal } from '../RegisterSaleModal'

export const SALE_STATUS_MAP = {
  0: { text: 'NotSold', color: 'error' },
  5: { text: 'OnSale', color: 'warning' },
  10: { text: 'Sold', color: 'secondary' },
  20: { text: 'Hold', color: 'success' },
  30: { text: 'PartiallySold', color: 'primary' },
  40: { text: 'Archived', color: 'info' },
  50: { text: 'Unfilled', color: 'warning' },
} as const

interface SelectableListingSale extends ListingSale {
  selected: boolean
}

export const ManageListing = () => {
  const { id } = useParams()
  const { data: listing } = useGetBookingListingByIdQuery({ id: +id! })
  const [deleteSales] = useDeleteSaleMutation()
  const navigate = useNavigate()
  const [modal, setModal] = useState<
    | 'REGISTER_SALE'
    | 'REGISTER_ON_SALE'
    | 'EDIT_ON_SALE'
    | 'DELETE_ON_SALE'
    | 'EDIT_LISTING'
    | 'DELETE_LISTING'
    | ''
  >('')

  const handleOpenRegisterSaleModal = () => {
    setModal('REGISTER_SALE')
  }
  const handleOpenRegisterOnSaleModal = () => {
    setModal('REGISTER_ON_SALE')
  }

  const [deleteListing] = useDeleteBookingListingMutation()

  const [selectedRows, setSelectedRows] = useState<SelectableListingSale[]>([])

  useEffect(() => {
    if (listing?.data?.sales)
      setSelectedRows((prev) =>
        listing.data.sales.map((clone) => ({
          ...clone,
          selected: prev?.find((p) => p.id === clone.id)?.selected || false,
        }))
      )
  }, [JSON.stringify(listing?.data?.sales)])

  const handleDeleteOnSale = async (selectedRows: SelectableListingSale[]) => {
    await deleteSales({
      saleIds: selectedRows.filter((clone) => clone.selected).map((clone) => clone.id),
    })
    setModal('')
  }

  const handleDeleteListing = async () => {
    try {
      await deleteListing({
        listingIds: [+id!],
      }).unwrap()

      showNotification('Listing deleted', 'success')
      navigate(-1)
    } catch (e) {
      showNotification('Error. Try again later', 'error')
    }
  }

  const handleSelectRow = (cloneId: number) => {
    setSelectedRows((prev) =>
      prev.map((clone) => (clone.id === cloneId ? { ...clone, selected: !clone.selected } : clone))
    )
  }

  return (
    <>
      <Box borderTop={1} borderColor="divider">
        <Typography fontWeight={800} fontSize="16px" py={2}>
          Manage Listing
        </Typography>
        <Box sx={{ width: '100%', padding: '24px 0' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography fontWeight={600} fontSize="16px">
                  Listing #{listing?.data?.id}
                </Typography>
                <Chip
                  label={
                    (listing?.data?.saleStatus || listing?.data?.saleStatus === 0) &&
                    SALE_STATUS_MAP[listing.data.saleStatus].text
                  }
                  color={
                    ((listing?.data?.saleStatus || listing?.data?.saleStatus === 0) &&
                      SALE_STATUS_MAP[listing.data.saleStatus].color) ||
                    'info'
                  }
                />
                <Chip
                  label={
                    (listing?.data?.ticketForm || listing?.data?.ticketForm === 0) &&
                    TICKET_FORM_MAP[listing?.data?.ticketForm as TicketFormResponseType]
                  }
                  color={TICKET_COLOR_MAP[listing?.data?.ticketForm as TicketFormResponseType]}
                />
                <IconButton onClick={() => setModal('EDIT_LISTING')} color="primary">
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
                <IconButton onClick={() => setModal('DELETE_LISTING')} color="warning">
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button onClick={handleOpenRegisterSaleModal} disabled={!listing}>
                  Register Sale
                </Button>
                <Button onClick={handleOpenRegisterOnSaleModal} disabled={!listing}>
                  Register On Sale
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Typography fontWeight={600} fontSize="16px" py={1} component="span">
                {listing?.data?.eventCategory.eventName}
              </Typography>{' '}
              <Typography fontWeight={400} fontSize="14px" py={1} component="span">
                {listing?.data?.numberOfTickets} tickets, {listing?.data?.eventCategory.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', gap: 5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  maxWidth: '500px',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid #E9E9EA',
                    borderRadius: '8px',

                    minHeight: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: 'calc(100vh - 640px)',
                    overflowY: 'scroll',
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize="16px"
                    component="h4"
                    width="100%"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <ConfirmationNumberIcon sx={{ color: '#007BFF' }} />
                    Tickets
                  </Typography>
                  <Divider sx={{ width: '100%', pt: 1 }} />

                  {listing?.data?.tickets.length ? (
                    listing?.data?.tickets.map((ticket) => (
                      <Box
                        key={ticket.id}
                        sx={{
                          width: '100%',
                          borderBottom: '1px solid #E9E9EA',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        p={1}
                      >
                        <Box>
                          #{ticket.id} section: {ticket.section}, row: {ticket.row}, seat:{' '}
                          {ticket.seat}{' '}
                        </Box>
                        <Chip
                          label={
                            (ticket.saleStatusType || ticket.saleStatusType === 0) &&
                            SALE_STATUS_MAP[ticket.saleStatusType].text
                          }
                          color={
                            ((ticket.saleStatusType || ticket.saleStatusType === 0) &&
                              SALE_STATUS_MAP[ticket.saleStatusType].color) ||
                            'info'
                          }
                        />
                      </Box>
                    ))
                  ) : (
                    <Typography
                      fontWeight={400}
                      fontSize="14px"
                      component="span"
                      textAlign="center"
                      m={6}
                    >
                      There is no any added tickets.
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid #E9E9EA',
                    borderRadius: '8px',
                  }}
                >
                  <Typography
                    fontWeight={600}
                    fontSize="16px"
                    component="h4"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <SellIcon sx={{ color: '#007BFF' }} />
                    Price
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Price, EUR
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.priceEur}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Total price, EUR
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.tickets?.length &&
                      listing?.data?.priceEur * listing?.data?.tickets?.length}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Fee, EUR
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.feeEur}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  p: 2,
                  border: '1px solid #E9E9EA',
                  borderRadius: '8px',
                  width: '100%',
                  minHeight: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize="16px"
                  component="h4"
                  width="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                    <ContentCopyIcon sx={{ color: '#007BFF' }} />
                    On Sale{' '}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <Button
                      onClick={() => setModal('EDIT_ON_SALE')}
                      size="small"
                      disabled={
                        selectedRows.filter((clone) => clone.selected).length > 1 ||
                        selectedRows.filter((clone) => clone.selected).length === 0
                      }
                    >
                      <ModeEditOutlineOutlinedIcon fill="#fff" />
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => setModal('DELETE_ON_SALE')}
                      disabled={selectedRows.filter((clone) => clone.selected).length === 0}
                    >
                      <DeleteOutlineOutlinedIcon fill="#fff" />
                    </Button>
                  </Box>
                </Typography>
                <Divider sx={{ width: '100%', pt: 1 }} />

                {selectedRows?.length ? (
                  selectedRows.map((clone) => (
                    <Box
                      key={`${clone.id}${clone.selected}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: '1px solid #E9E9EA',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'rgba(0, 0, 0, 0.1)',
                        },
                      }}
                      onClick={() => handleSelectRow(clone.id)}
                    >
                      <Tooltip title={`${clone.salePlatform.trim()}, ${clone.saleUser} `}>
                        <Box sx={{ width: '100%' }} p={1}>
                          #{clone.id}, {clone.numberOfTickets} tickets, {clone.salePrice} EUR,{' '}
                          {
                            SPLITING_TYPE_OPTIONS.find(
                              (opt) => opt.value === clone.splitType.toString()
                            )?.name
                          }
                        </Box>
                      </Tooltip>
                      <Checkbox checked={clone.selected} />
                    </Box>
                  ))
                ) : (
                  <Typography
                    fontWeight={400}
                    fontSize="14px"
                    component="span"
                    textAlign="center"
                    m={6}
                  >
                    There is no any sales.
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: 'column',
                  maxWidth: '400px',
                  width: '100%',
                }}
              >
                <Box sx={{ p: 2, border: '1px solid #E9E9EA', borderRadius: '8px' }}>
                  <Typography
                    fontWeight={600}
                    fontSize="16px"
                    component="h4"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <EventIcon sx={{ color: '#007BFF' }} />
                    Event Details
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Ordered Date
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.orderedDateCet &&
                      formatToLocaleTimezone(listing?.data?.orderedDateCet)}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Event date
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.eventCategory.eventDate &&
                      formatToLocaleTimezone(listing.data.eventCategory.eventDate)}
                  </Typography>
                </Box>

                <Box sx={{ p: 2, border: '1px solid #E9E9EA', borderRadius: '8px' }}>
                  <Typography
                    fontWeight={600}
                    fontSize="16px"
                    component="h4"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <PersonIcon sx={{ color: '#007BFF' }} />
                    User info
                  </Typography>
                  <Divider sx={{ pt: 1 }} />
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    E-mail
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.user}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Password
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.password}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Address
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.address}
                  </Typography>
                  <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                    Card
                  </Typography>
                  <Typography fontWeight={400} fontSize="14px" component="p">
                    {listing?.data?.card?.num}
                  </Typography>
                  {listing?.data?.note && (
                    <>
                      <Typography fontWeight={600} fontSize="14px" py={1} component="p">
                        Note
                      </Typography>
                      <Typography fontWeight={400} fontSize="14px" component="p">
                        {listing?.data?.note}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {listing?.data?.id && (
            <>
              <Typography
                fontWeight={600}
                fontSize="16px"
                component="h4"
                width="100%"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                p={1}
                pt={3}
              >
                <ConfirmationNumberIcon sx={{ color: '#007BFF' }} />
                Sold
              </Typography>

              <ManageListingTable listingId={listing?.data?.id} />
            </>
          )}
        </Box>
      </Box>
      {modal === 'REGISTER_SALE' && (
        <RegisterSaleModal onClose={() => setModal('')} listing={listing!.data!} />
      )}
      {modal === 'REGISTER_ON_SALE' && (
        <RegisterOnSaleModal onClose={() => setModal('')} listing={listing!.data!} />
      )}
      {modal === 'DELETE_ON_SALE' && (
        <ConfirmModal
          onClose={() => setModal('')}
          onConfirm={() => handleDeleteOnSale(selectedRows)}
          open={true}
        >
          Are you sure you want to delete the sale
          {selectedRows.filter((clone) => clone.selected).length > 1 ? 's' : ''} with ID
          {selectedRows.filter((clone) => clone.selected).length > 1 ? 's' : ''}{' '}
          {selectedRows
            .filter((clone) => clone.selected)
            .map((clone) => clone.id)
            .join(', ')}
          ?
        </ConfirmModal>
      )}
      {modal === 'EDIT_ON_SALE' && (
        <EditSaleModal
          onClose={() => setModal('')}
          sale={selectedRows.find((clone) => clone.selected)}
        />
      )}
      {modal === 'EDIT_LISTING' && (
        <CreateListingModal onClose={() => setModal('')} listing={listing?.data} />
      )}
      {modal === 'DELETE_LISTING' && (
        <ConfirmModal
          onClose={() => setModal('')}
          onConfirm={handleDeleteListing}
          open={true}
        >
          Are you sure you want to delete listing with ID {id}?
        </ConfirmModal>
      )}
    </>
  )
}
