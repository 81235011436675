import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const CREATE_BOTS_BULK_SCHEMA = yup.object().shape({
  quantity: yup.number().required(requiredMessage),
  ticketSystemId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  proxyTags: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        name: yup.string(),
      })
    )
    .transform((v) => (v ? v : null))
    .nullable(),
})