import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Ticket } from 'services/bookings/types'

interface Props {
  ticket: Ticket
  width?: string
}

const Tickets: FC<Props> = ({ ticket, width }) => {
  return (
    <Box display="flex" mb="3px" sx={{ ':last-child': { mb: '0' } }}>
      <Box width={width || undefined} textOverflow="ellipsis" overflow="hidden" fontSize="14px">
        <Typography display="inline-block" fontWeight={600} fontSize="14px">
          {' '}
          Sec:
        </Typography>{' '}
        {ticket.section}
        <Typography display="inline-block" fontWeight={600} fontSize="14px" ml={1}>
          {' '}
          Row:
        </Typography>{' '}
        {ticket.row}
        <Typography display="inline-block" fontWeight={600} fontSize="14px" ml={1}>
          {' '}
          Seat:
        </Typography>{' '}
        {ticket.seat}
      </Box>
    </Box>
  )
}

export default Tickets
