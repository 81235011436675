import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react/index'
import { MutationDefinition } from '@reduxjs/toolkit/query'
import { useState } from 'react'

import { GridColDef } from '@mui/x-data-grid'

import { ReportRequest } from 'types/ReportFileRequest'
import { QueryTableState } from 'types/TableServerRequest'

import { downloadReportAsExcel } from 'utils/downloadReportAsExel'
import { showNotification } from 'utils/showNotification'
import { camelToSnake } from 'utils/text'

import { TTagType } from 'api/api'

interface Props {
  queryParams: QueryTableState
  columns: GridColDef[]
  getReport: MutationTrigger<
    MutationDefinition<
      ReportRequest,
      BaseQueryFn<
        {
          url: string
          method: string | undefined
          data?: any
          params?: any
        },
        unknown,
        unknown,
        {},
        {}
      >,
      TTagType,
      Blob,
      'api'
    >
  >
  reportName?: string
  replacement?: { [key: string]: string }
}

export const useExportReport = ({
  queryParams,
  columns,
  getReport,
  reportName,
  replacement,
}: Props) => {
  const [isModal, setIsModal] = useState<boolean>(false)
  const handleExportButtonClick = () => {
    setIsModal(true)
  }
  const handleExport = async (size?: number, pageNumber?: number) => {
    const exportColumns = columns
      //removes actions from report
      .filter((c) => c.field !== 'actions')
      .map((column) => {
        if (replacement && Object.keys(replacement).includes(column.field)) {
          return camelToSnake(replacement[column.field])
        }
        return camelToSnake(column.field)
      })
    getReport({
      columns: exportColumns,
      orderBy: camelToSnake(queryParams.orderBy),
      direction: queryParams.direction,
      filters: queryParams.filters,
      //removes actions from report
      captions: columns.filter((c) => c.field !== 'actions').map((column) => column.headerName!),
      page: {
        size: size === undefined ? queryParams.page.size : size,
        pageNumber: pageNumber === undefined ? queryParams.page.pageNumber : pageNumber,
      },
    })
      .unwrap()
      .then((blob: Blob) => {
        downloadReportAsExcel(blob, reportName || 'report')
      })
      .then(() => {
        setIsModal(false)
        showNotification('Report successfully downloaded', 'success')
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  return { isModal, setIsModal, handleExportButtonClick, handleExport }
}
