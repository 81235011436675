import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const EDIT_BOT_SCHEMA = yup.object().shape({
  ticketSystemId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  proxyId: yup.number().required(requiredMessage),
  password: yup.string().required(requiredMessage),
  archivedStatus: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
})