import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const EVENT_GROUP_SCHEMA = yup.object().shape({
  name: yup.string().trim().required(requiredMessage),
  ticketSystemId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  url: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  timeDifUtc: yup
    .object()
    .shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
    })
    .transform((v) => (v ? v : null))
    .nullable(),
  country: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  currencyId: yup
    .object()
    .shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
    })
    .transform((v) => (v ? v : null))
    .nullable(),
  startDate: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  endDate: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
  description: yup
    .string()
    .trim()
    .transform((v) => (!v ? null : v))
    .nullable(),
})
