import * as yup from 'yup'

import { numberTypeMessage, requiredMessage } from 'validation/index'

export const ADD_PROXIES_SCHEMA = yup.object().shape({
  host: yup.string().trim().required(requiredMessage),
  minPort: yup
    .string()
    .trim()
    .required(requiredMessage)
    .test('is-number', 'Must be a number', (v) => !isNaN(+v))
    .test('is-positive', 'Must be positive', (v) => !isNaN(+v) && +v >= 1)
    .test('max', 'Must be less or equal than 100000', (v) => !isNaN(+v) && +v <= 100000),
  maxPort: yup
    .string()
    .trim()
    .required(requiredMessage)
    .test('is-number', 'Must be a number', (v) => !isNaN(+v))
    .test('is-positive', 'Must be positive', (v) => !isNaN(+v) && +v >= 1)
    .test('max', 'Must be less or equal than 100000', (v) => !isNaN(+v) && +v <= 100000)
    .when('minPort', (min, schema) => {
      return schema.test(
        'is-min-less-than-max',
        'Min port should be less than max port',
        (v) => +min <= +v
      )
    }),
  quantity: yup
    .number()
    .typeError(numberTypeMessage)
    .required(requiredMessage)
    .min(1, 'Must be >= 1'),
  maxBotsCount: yup
    .number()
    .typeError(numberTypeMessage)
    .required(requiredMessage)
    .min(1, 'Must be >= 1')
    .max(19, 'Must be <= 19'),
  tags: yup.array().of(yup.string().trim().required(requiredMessage)).required(requiredMessage),
  countryDistribution: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          countryId: yup.object().shape({
            value: yup.string().trim().required(requiredMessage),
            name: yup.string().trim().required(requiredMessage),
          }),
          percent: yup.number().typeError(numberTypeMessage).required(requiredMessage),
          tags: yup
            .array()
            .of(yup.string().trim().required(requiredMessage))
            .required(requiredMessage),
        })
        .required()
    )
    .required(),
  usernamePattern: yup.string().trim().required(requiredMessage),
  passwordPattern: yup.string().trim().required(requiredMessage),
})