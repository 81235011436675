import { FC } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { capitalize, IconButton } from '@mui/material'
import Box from '@mui/material/Box'

import copyToClipboard from 'utils/clipboardUtils'

interface Props {
  value: string
  field: string
  handleCellHighlight?: (field: string) => void
}

export const CopyToClipboard: FC<Props> = ({ value, field, handleCellHighlight }) => {
  const handleCellClick = async ({ field, value }: { field: string; value: string }) => {
    await copyToClipboard(value, `${capitalize(field)} was copied to clipboard`)
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '100%',
      }}
    >
      <Box
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {value}
      </Box>
      <IconButton
        sx={{ alignSelf: 'center' }}
        aria-label="copy"
        color="primary"
        size="small"
        onClick={async () => {
           await handleCellClick({ field, value })
            handleCellHighlight && handleCellHighlight(field)
        }}
      >
        <ContentCopyIcon sx={{ cursor: 'pointer' }} />
      </IconButton>
    </Box>
  )
}
