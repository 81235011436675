import { QueryTableState } from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import {
  ArchiveEventPayload,
  CreateEvent,
  CreateEventGroup,
  EditEvent,
  EventGroup,
  EventGroupDetails,
  GetEventGroupPayload,
  Event,
  EventDetails,
  EditEventGoals,
  EditEventGroup,
  EventGroupTransform,
  EventPauseGoalsRequest,
} from './types'

export const eventsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<TableServerResponse<Event[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/events/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Events', id: 'EVENTS' }],
    }),
    getEventDetails: build.query<EventDetails, void>({
      query: () => ({
        url: '/api/events/details',
        method: 'GET',
      }),
      providesTags: [{ type: 'Events', id: 'EVENTS' }],
    }),
    addEvent: build.mutation<{ msg: string }, CreateEvent>({
      query: (body) => ({
        url: '/api/events',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Events', id: 'EVENTS' }],
    }),
    editEvent: build.mutation<{ msg: string }, EditEvent>({
      query: (body) => ({
        url: '/api/events',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'Events', id: 'EVENTS' }],
    }),
    archiveEvent: build.mutation<{ msg: string }, ArchiveEventPayload>({
      query: (body) => ({
        url: '/api/events/archive',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Events', id: 'EVENTS' }],
    }),
    getEventGroups: build.query<TableServerResponse<EventGroup[]>, GetEventGroupPayload>({
      query: (body) => ({
        url: '/api/events/event-groups/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'EventGroups', id: 'EVENT_GROUPS' }],
    }),
    getEventGroupDetails: build.query<EventGroupDetails, void>({
      query: () => ({
        url: '/api/events/event-groups/details',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'EventGroups', id: 'EVENT_GROUPS' }],
    }),
    getEventGroup: build.query<EventGroupTransform[], void>({
      query: () => ({
        url: '/api/events/event-groups',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'EventGroups', id: 'EVENT_GROUPS' }],
      transformResponse: (response: { eventGroups: EventGroup[] }) =>
        response.eventGroups.map((v: EventGroup) => ({
          value: v.id.toString(),
          name: v.name,
        })) || [],
    }),
    addEventGroup: build.mutation<{ msg: string }, CreateEventGroup>({
      query: (body) => ({
        url: '/api/events/event-groups',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'EventGroups', id: 'EVENT_GROUPS' }],
    }),
    editEventGroup: build.mutation<{ msg: string }, EditEventGroup>({
      query: (body) => ({
        url: '/api/events/event-groups',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: [{ type: 'EventGroups', id: 'EVENT_GROUPS' }],
    }),
    editGoalsEvent: build.mutation<{ msg: string }, EditEventGoals>({
      query: (body) => ({
        url: '/api/events/categories/settings',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Events', id: 'EVENTS' }],
    }),
    editPauseGoalsEvent: build.mutation<{ msg: string }, EventPauseGoalsRequest>({
      query: (body) => ({
        url: '/api/events/pause-goals',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Events', id: 'EVENTS' }],
    }),
  }),
})

export const {
  useGetEventsQuery,
  useEditEventMutation,
  useAddEventMutation,
  useGetEventGroupsQuery,
  useAddEventGroupMutation,
  useEditEventGroupMutation,
  useGetEventGroupDetailsQuery,
  useGetEventDetailsQuery,
  useEditGoalsEventMutation,
  useGetEventGroupQuery,
  useArchiveEventMutation,
  useEditPauseGoalsEventMutation,
} = eventsApi
