import { FC } from 'react'

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { TagResponse } from 'services/bookings/types'

interface Props {
  openEditTags: () => void
  tags: TagResponse[]
}

const Tags: FC<Props> = ({ openEditTags, tags }) => {
  const size = `${tags.length >= 3 ? '35px' : tags.length === 2 ? '30px' : '25px'}`
  return (
    <Box position="relative" width={size} height={size}>
      {tags.slice(0, 3).map((item: TagResponse, index: number) => (
        <Tooltip
          placement="right"
          key={item.id}
          title={
            <Box>
              {tags.map((item) => (
                <Box key={item.id} display="flex" mb={1} sx={{ ':last-child': { mb: '3px' } }}>
                  <Box
                    sx={{
                      minWidth: '20px',
                      height: '20px',
                      bgcolor: `${item.color}`,
                      boxShadow: '.3px 1px #808080',
                      borderRadius: '3px',
                      mr: '5px',
                      mt: '3px',
                    }}
                  />
                  <Typography>-</Typography>
                  <Typography textAlign="justify" ml="5px">{`${item.caption}`}</Typography>
                </Box>
              ))}
            </Box>
          }
        >
          <Box
            onClick={(event) => {
              event.stopPropagation()
              openEditTags()
            }}
            sx={{
              position: 'absolute',
              width: '25px',
              height: '25px',
              bgcolor: `${item.color}`,
              borderRadius: '3px',
              cursor: 'pointer',
              top: `${5 * index}px`,
              left: `${5 * index}px`,
              boxShadow: '.3px 1px #808080 ',
            }}
          ></Box>
        </Tooltip>
      ))}
    </Box>
  )
}

export default Tags
