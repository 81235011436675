import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box/Box'

import { showNotification } from 'utils/showNotification'

import {
  useDeleteSqlTemplatesMutation,
  useGetSqlTemplatesQuery,
  useUpdateSqlTemplatesMutation,
} from 'services/dashboard/api'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'

import { AddTemplateModal } from './AddTemplateModal'
import { EDIT_TEMPLATES_SCHEMA } from './validation'

interface Props {
  onClose: () => void
}

interface FormValues {
  reportsList: {
    beId?: number
    name: string
    sqlQuery: string
  }[]
}
export const EditTemplateModal = ({ onClose }: Props) => {
  const { data: templatesFromServer, isLoading } = useGetSqlTemplatesQuery()
  const [updateTemplates] = useUpdateSqlTemplatesMutation()
  const [deleteTemplate, { isLoading: isDeleting }] = useDeleteSqlTemplatesMutation()
  const [templateToEdit, setTemplateToEdit] = useState<{
    ind: number
    data: { beId?: number; name: string; sqlQuery: string }
  }>()

  const [modal, setModal] = useState<'ADD_TEMPLATE' | 'EDIT_TEMPLATE' | ''>('')

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(EDIT_TEMPLATES_SCHEMA),
    defaultValues: {
      reportsList:
        templatesFromServer?.reports?.map((report) => ({ ...report, beId: report.id })) || [],
    },
  })
  useEffect(() => {
    if (templatesFromServer?.reports?.length)
      setValue(
        'reportsList',
        templatesFromServer.reports.map((report) => ({ ...report, beId: report.id }))
      )
  }, [templatesFromServer?.reports?.length])

  const {
    fields: templates,
    append,
    update,
    remove,
  } = useFieldArray({ control, name: 'reportsList' })

  const handleDeleteTemplate = async (
    ind: number,
    template: {
      beId?: number
      name: string
      sqlQuery: string
    }
  ) => {
    if (template?.beId) {
      await deleteTemplate({ ids: [template?.beId] })
        .unwrap()
        .then(() => {
          remove(ind)
          showNotification('Template successfully deleted', 'success')
        })
        .catch(() => {
          showNotification('Error. Failed to delete', 'error')
        })
    } else {
      remove(ind)
    }
    //
  }

  const onSubmit = (formValues: FormValues) => {
    updateTemplates({
      reportsList: formValues.reportsList.map(({ name, sqlQuery, beId }) => ({
        name,
        sqlQuery,
        id: beId,
      })),
    })
      .unwrap()
      .then(() => {
        showNotification('Templates successfully updated', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  return (
    <>
      <AppModal title="Edit Templates" onClose={onClose} loading={isLoading} maxWidth={750}>
        <AppModal.Wrapper
          sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <AppModal.ContentBox>
            {templates.length ? (
              templates.map((template, ind) => (
                <Box sx={{ width: '100%', display: 'flex', gap: 2 }} key={template.id}>
                  <TextInput
                    control={control}
                    errors={errors?.reportsList?.[ind]?.message}
                    label="Templete title"
                    name={`reportsList.${ind}.name`}
                    disabled
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: '56px', minWidth: '56px', maxWidth: '56px' }}
                    onClick={() => {
                      setTemplateToEdit({
                        ind,
                        data: {
                          ...(template?.beId && { beId: template?.beId }),
                          name: template.name,
                          sqlQuery: template.sqlQuery,
                        },
                      })
                      setModal('EDIT_TEMPLATE')
                    }}
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleDeleteTemplate(ind, template)}
                    sx={{ height: '56px', minWidth: '56px', maxWidth: '56px' }}
                    disabled={isDeleting}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              ))
            ) : (
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', minHeight: 100}}>
                <Typography sx={{ width: '100%', textAlign: 'center' }}>
                  There is no added templates yet.
                </Typography>
              </Box>
            )}
          </AppModal.ContentBox>
          <AppModal.ButtonsBox sx={{ justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={() => setModal('ADD_TEMPLATE')}>
              Add Template
            </Button>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="outlined" color="warning" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Box>
          </AppModal.ButtonsBox>
        </AppModal.Wrapper>
      </AppModal>
      {modal === 'ADD_TEMPLATE' && (
        <AddTemplateModal onClose={() => setModal('')} append={append} />
      )}
      {modal === 'EDIT_TEMPLATE' && templateToEdit && (
        <AddTemplateModal
          onClose={() => setModal('')}
          update={update}
          templateIndex={templateToEdit?.ind}
          templateToEdit={templateToEdit?.data}
        />
      )}
    </>
  )
}
