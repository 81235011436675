import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { User } from 'services/users/types'

export const USER_KEY = 'ticketsChaser:User'

const useAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem(USER_KEY)
    if (storedUser) {
      return JSON.parse(storedUser)
    }
    return null
  })

  const login = useCallback(
    (userData: User) => {
      localStorage.setItem(USER_KEY, JSON.stringify(userData))
      setUser(userData)
      navigate('/dashboard')
    },
    [location]
  )

  const logout = useCallback(() => {
    localStorage.removeItem(USER_KEY)
    setUser(null)
    navigate('/login', { state: { from: location } })
  }, [location])

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === USER_KEY) {
        if (e.newValue) {
          setUser(JSON.parse(e.newValue))
        } else {
          setUser(null)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return {
    user,
    login,
    logout,
  }
}

export default useAuth
