import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const setGoalsSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.object().shape({
        beId: yup.number().typeError('Must be a number').required(requiredMessage),
        name: yup.string().required(requiredMessage),
        settings: yup
          .object()
          .shape({
            '1': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
            '2': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
            '3': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
            '4': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
            '5': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
            '6': yup
              .number()
              .typeError(requiredMessage)
              .min(-1, 'Minimum value -1')
              .required(requiredMessage),
          })
          .required(requiredMessage),
        priority: yup
          .number()
          .typeError(requiredMessage)
          .min(1, 'Minimum value 1')
          .max(10, 'Maximum value 10')
          .required(requiredMessage),
        goalsMode: yup.number().nullable().notRequired()
      })
    )
    .required(requiredMessage),
})
