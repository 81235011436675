import * as yup from 'yup'
import { ValidationError } from 'yup'

import {
  ACCEPTED_FILE_TYPES_BOOKING_CONFIRMATION,
  ACCEPTED_FILE_TYPES_ORDER_SCREENSHOT,
} from 'validation/index'

const requiredMessage = 'This field is required'

export const createListingModalValidation = (currency: { name: string; rateToEur: number }) =>
  yup.object().shape({
    eventCategoryId: yup.object().shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
    }),
    priceLocal: yup
      .number()
      .typeError('Must be a number')
      .required(requiredMessage)
      .test(
        'ismorethan1',
        `Price should be more than ${currency.rateToEur} ${currency.name}`,
        (v) => v / currency.rateToEur > 1
      ),
    orderedDateCet: yup.string().trim().required(requiredMessage),
    password: yup.string().trim().required(requiredMessage),
    address: yup
      .string()
      .trim()
      .max(500, 'Max 500 symbols allowed')
      .transform((v) => (!v ? null : v))
      .nullable(),
    telephone: yup
      .string()
      .trim()
      .transform((v) => (!v ? null : v))
      .nullable(),
    cardId: yup.object().shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
    }),
    feeLocal: yup.number().typeError('Must be a number').required(requiredMessage),
    note: yup
      .string()
      .trim()
      .max(5000, 'Max 500 symbols allowed')
      .transform((v) => (!v ? null : v))
      .nullable(),
    bookingConfirmationFilename: yup
      .mixed<string | File[]>()
      .required(requiredMessage)
      .test('fileSize', 'Maximum size is 2MB', (value: any) => {
        if (typeof value === 'string') return true
        return value?.[0]?.size <= 2 * 2 ** 20
      })
      .test('fileType', 'File must be of .pdf,.jpg, jpeg or png type', (value: any) => {
        if (typeof value === 'string') return true
        return ACCEPTED_FILE_TYPES_BOOKING_CONFIRMATION.includes(value?.[0]?.type)
      }),
    orderScreenshotFilename: yup
      .mixed<string | File[]>()
      .required(requiredMessage)
      .test('fileSize', 'Maximum size is 2MB', (value) => {
        if (typeof value === 'string') return true
        return value?.[0]?.size <= 2 * 2 ** 20
      })
      .test('fileType', 'File must be of .jpg, jpeg, png type', (value) => {
        if (typeof value === 'string') return true
        return ACCEPTED_FILE_TYPES_ORDER_SCREENSHOT.includes(value?.[0]?.type)
      }),
    bookingId: yup
      .string()
      .trim()
      .transform((v) => (!v ? null : v))
      .nullable(),
    internalTicketForm: yup.object().shape({
      value: yup.string().trim().required(requiredMessage),
      name: yup.string().trim().required(requiredMessage),
    }),
    tagIds: yup.array().of(yup.number()).required(),
    internalTickets: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.mixed(),
          seat: yup
            .string()
            .trim()
            .transform((v) => (!v ? null : v))
            .nullable(),
          row: yup
            .string()
            .trim()
            .transform((v) => (!v ? null : v))
            .nullable(),
          section: yup
            .string()
            .trim()
            .transform((v) => (!v ? null : v))
            .nullable(),
        })
      )
      .min(1, 'Must have at least 1 ticket specified'),
    numberOfTickets: yup
      .number()
      .typeError('Must be a number')
      .when('$internalTickets', (internalTickets, schema) => {
        return !internalTickets.length ? schema.required(requiredMessage) : schema
      }),
    fanSection: yup
      .string()
      .nullable()
      .transform((v) => (v ? v : null)),
    user: yup.lazy((val) => {
      if (typeof val === 'string') return yup.string().required(requiredMessage)
      else
        return yup
          .object()
          .shape({
            name: yup.string().required(requiredMessage),
            value: yup.string().required(requiredMessage),
          })
          .test('user-test', requiredMessage, (value) => {
            return value && (value.name || value.value)
              ? true
              : new ValidationError(requiredMessage, {}, 'user')
          })
          .required(requiredMessage)
    }),
    internalEvent: yup.lazy((val) => {
      if (typeof val === 'string') return yup.string()
      else
        return yup
          .object()
          .shape({
            name: yup.string(),
            id: yup.string(),
            categories: yup.array().of(
              yup.object().shape({
                name: yup.string(),
                id: yup.string(),
                priceLocal: yup.number(),
              })
            ),
            currency: yup.object().shape({
              name: yup.string().required(requiredMessage),
              rateToEur: yup.number().required(requiredMessage),
            }),
          })
          .test('event-test', requiredMessage, (value) => {
            return value && (value.name || value.id)
              ? true
              : new ValidationError(requiredMessage, {}, 'internalEvent')
          })
    }),
  })
