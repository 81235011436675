import { useCallback, useState } from 'react'

import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel'

export const useSelectedRows = () => {
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])

  const handleRowSelection = useCallback((rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(rowSelectionModel)
  }, [])
  return { selectedRows, setSelectedRows, handleRowSelection }
}
