import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useRegisterAppMutation } from 'services/purchasing/api'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import TextInput from 'components/common/TextInput'

import { registerAppSchema } from './validation'

interface Props {
  onClose: () => void
  botId: string
}

export const RegisterAppModal: FC<Props> = ({ onClose, botId }) => {
  const [registerApp, { isLoading }] = useRegisterAppMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerAppSchema),
    defaultValues: {
      note: '',
    },
  })

  const onSubmit = (formData: { note: string }) => {
    registerApp({ botId, note: formData?.note })
      .unwrap()
      .then(() => {
        showNotification('Application successfully registered', 'success')
        onClose()
      })
      .catch((error) => {
        showNotification('Error. Try again later', 'error')
        console.error(error)
      })
  }

  return (
    <AppModal onClose={onClose} title="Register Application">
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ width: 450, height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ width: 450 }}>
          <TextInput
            control={control}
            label="Note"
            name="note"
            errors={errors}
            multiline
            rows={2}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button onClick={onClose} variant="outlined" color="warning" disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            Register
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
