import React, { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import { DialogProps } from '@mui/material/Dialog/Dialog'

import Button from 'components/common/Button'
import Modal from 'components/common/Modal'

interface Props extends DialogProps {
  children: ReactNode
  loading?: boolean
  cancel?: string
  onConfirm: (size?: number, pageNumber?: number) => void
  onClose: () => void
}

const ConfirmExportModal: FC<Props> = ({
  children,
  cancel = 'Cancel',
  loading,
  onClose,
  onConfirm,
  open,
}) => {
  const onSubmitCurrent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirm(undefined,undefined)
  }
  const onSubmitAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirm(-1,0)
  }

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClose()
  }

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  return (
    <Modal
      open={open}
      onClick={handleModalClick}
      onDoubleClick={(e) => e.stopPropagation()}
      onClose={onCancel}
    >
      <Box padding="24px" maxWidth="350px">
        {children}
        <Box sx={{ mt: 2 }}>
          <Button
            loading={loading}
            onClick={onSubmitCurrent}
            variant="contained"
            color="success"
            fullWidth
          >
            Current Page
          </Button>
          <Button
            loading={loading}
            onClick={onSubmitAll}
            variant="contained"
            color="success"
            fullWidth
            sx={{ mt: 1 }}
        >
            All Data
        </Button>
          <Button onClick={onCancel} variant="outlined" fullWidth sx={{ mt: 1 }}>
            {cancel}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmExportModal
