import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import Box from '@mui/material/Box/Box'

import { showNotification } from 'utils/showNotification'

import {
  useAddEventMutation,
  useEditEventMutation,
  useGetEventDetailsQuery,
} from 'services/events/api'
import { Event } from 'services/events/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import DatePicker from 'components/common/DatePicker'
import { RangeSlider } from 'components/common/RangeSlider'
import TextInput from 'components/common/TextInput'
import { TIME_ZONE_OPTIONS } from 'components/events/eventGroups/AddEventGroupModal'

import { ADD_EVENT_SCHEMA } from './validation'

interface Props {
  event?: Event
  onClose: () => void
}

interface FormValues {
  eventGroupId: {
    value: string
    name: string
  }
  name: string
  timeDifUtc: {
    value: string
    name: string
  }
  city?: string | null
  date: string
  venue?: string | null
  url?: string | null
  currencyId: {
    value: string
    name: string
  }
  description?: string | null
  categories?: {
    name: string
    priority: number
    price: number[]
    catId?: number
  }[]
}

export const EditEventModal: FC<Props> = ({ event, onClose }) => {
  const { data: eventDetails } = useGetEventDetailsQuery()
  const [createEvent, { isLoading: isCreating }] = useAddEventMutation()
  const [editEvent, { isLoading: isUpdating }] = useEditEventMutation()
  const currencyOptions = useMemo(
    () => eventDetails?.currencies?.map((v) => ({ value: v.id.toString(), name: v.name })) || [],
    [eventDetails]
  )

  const eventGroupOptions = useMemo(
    () =>
      eventDetails?.eventGroups?.map((v) => ({
        value: v.id?.toString(),
        name: v.name,
        currencyId: {
          value: v?.currencyId?.toString() || '',
          name: v?.currencyId?.toString() || '',
        },
      })) || [],
    [eventDetails]
  )
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(ADD_EVENT_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      eventGroupId: {
        value: event?.eventGroup?.id?.toString() || '',
        name: event?.eventGroup?.name?.toString() || '',
      },
      name: event?.name || '',
      timeDifUtc: {
        value: event?.timeDifUtc?.toString() || '0',
        name:
          TIME_ZONE_OPTIONS.find((v) => v.value === event?.timeDifUtc?.toString())?.name ||
          'UTC 00:00',
      },
      city: event?.city || '',
      date: event?.date || '',
      venue: event?.venue || '',
      url: event?.url || '',
      currencyId: {
        value: event?.currency?.id?.toString() || '',
        name: event?.currency?.name?.toString() || '',
      },
      description: event?.description || '',
      categories:
        event?.eventCategoriesStats?.map((category) => ({
          name: category.name,
          priority: category.priority,
          price: [category.priceLocalMin, category.priceLocalMax],
          catId: category.id,
        })) ?? [],
    },
  })

  const currencyId = watch('currencyId')

  const exchangeRate = useMemo(
    () => eventDetails?.currencies?.find((currency) => currency.id === +currencyId)?.rateToEur || 1,
    [currencyId, eventDetails]
  )

  const {
    fields: categories,
    append,
    replace,
    remove,
  } = useFieldArray({ control, name: 'categories' })

  const eventGroup = watch('eventGroupId')
  useEffect(() => {
    if (eventGroup && eventGroupOptions?.length && currencyOptions?.length) {
      setValue(
        'currencyId',
        currencyOptions.find(
          (v) =>
            v.value ===
            eventGroupOptions?.find((option) => option.value === eventGroup.value)?.currencyId.value
        ) ?? currencyOptions[0]
      )
    }
  }, [eventGroup, eventGroupOptions, currencyOptions])

  const maxAvailableValue = Math.round(2000 / exchangeRate)
  useEffect(() => {
    replace(
      categories.map((cat) => {
        if (cat.price[0] > maxAvailableValue) {
          return { ...cat, price: [maxAvailableValue, cat.price[1]] }
        }
        if (cat.price[1] > maxAvailableValue) {
          return { ...cat, price: [cat.price[0], maxAvailableValue] }
        } else {
          return cat
        }
      })
    )
  }, [maxAvailableValue])

  const onSubmit = (data: FormValues) => {
    const submitData = {
      ...data,
      date: data.date.split('+')[0],
      currencyId: +data.currencyId?.value,
      eventGroupId: +data.eventGroupId?.value,
      timeDifUtc: +data.timeDifUtc?.value ?? null,
      categories: data?.categories?.length
        ? data?.categories?.map((category) => ({
            name: category.name,
            priority: category.priority,
            priceLocalMin: category.price[0],
            priceLocalMax: category.price[1],
            id: category?.catId,
          }))
        : null,
    }

    if (event) {
      editEvent({ ...submitData, id: event.id })
        .unwrap()
        .then(() => {
          showNotification('Event successfully updated', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      createEvent(submitData)
        .unwrap()
        .then(() => {
          showNotification('Event successfully created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  return (
    <AppModal
      title={`${event ? 'Edit' : 'Add'} Event`}
      onClose={onClose}
      loading={isCreating || isUpdating}
    >
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ width: '800px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
              <ControlledSelectAutocomplete
                selectOptions={eventGroupOptions}
                name="eventGroupId"
                label="Event Group"
                errors={errors}
                control={control}
              />
              <TextInput name="name" label="Event Name" errors={errors} control={control} />
              <DatePicker name="date" label="Event Date" errors={errors} control={control} />
              <TextInput name="url" label="URL" errors={errors} control={control} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
              <ControlledSelectAutocomplete
                selectOptions={TIME_ZONE_OPTIONS}
                name="timeDifUtc"
                label="Time Zone"
                errors={errors}
                control={control}
              />
              <TextInput name="city" label="City" errors={errors} control={control} />
              <TextInput name="venue" label="Venue" errors={errors} control={control} />
              <ControlledSelectAutocomplete
                selectOptions={currencyOptions}
                name="currencyId"
                label="Currency"
                errors={errors}
                control={control}
              />
            </Box>
          </Box>
          <TextInput
            name="description"
            label="Description"
            errors={errors}
            control={control}
            multiline
            minRows={3}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 7, gap: 3 }}>
            {categories?.map((category, ind) => (
              <Box
                key={`${category.id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: 1.5,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                  mb: 2,
                }}
              >
                <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                  <TextInput
                    name={`categories.${ind}.name`}
                    label="Category Name"
                    errors={errors?.categories?.[ind]?.name?.message}
                    control={control}
                  />
                  <TextInput
                    name={`categories.${ind}.priority`}
                    label="Priority"
                    errors={errors?.categories?.[ind]?.priority?.message}
                    control={control}
                  />
                </Box>
                <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                  <RangeSlider
                    name={`categories.${ind}.price`}
                    minAvailableValue={0}
                    maxAvailableValue={maxAvailableValue}
                    minInputLabel={`Min Price, ${
                      currencyOptions.find((curr) => currencyId.value === curr.value)?.name || 'EUR'
                    }`}
                    maxInputLabel={`Max Price, ${
                      currencyOptions.find((curr) => currencyId.value === curr.value)?.name || 'EUR'
                    }`}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                  />
                </Box>
                <Button sx={{ mb: 2 }} color="warning" onClick={() => remove(ind)}>
                  Delete Category
                </Button>
              </Box>
            ))}
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={() =>
              append({ name: '', priority: 1, price: [0, Math.round(2000 / exchangeRate)] })
            }
          >
            Add Category
          </Button>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="warning" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isCreating || isUpdating}
              fullWidth={isMobileOnly}
            >
              {event ? 'Update' : 'Add'}
            </Button>
          </Box>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
