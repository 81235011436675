import React, {useMemo, useState} from 'react'

import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import { ALL_NUMBER_OPERATORS, ALL_STRING_OPERATORS } from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetProxiesQuery } from 'services/bots/api'

import { ProxiesTableToolbar } from 'components/bots/proxies/ProxiesTableToolbar/ProxiesTableToolbar'
import { CopyToClipboard } from 'components/common/CopyToClipboard/index'
import CustomDataGrid from 'components/table/DataGrid/index'

export type ProxiesTabActions = 'ADD' | 'EDIT' | 'DELETE' | 'EDIT_SINGLE' | 'DELETE_SINGLE' | ''
export const ProxiesTab = () => {
    const [isHighlight, setIsHighlight] = useState<boolean>(false)
    const handleCellHighlight = (field: string) => {
        console.log(field)
        if (['password', 'username', 'port', 'host'].includes(field)) {
            setIsHighlight(true)
        }
    }
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'left',
        align: 'left',
        type: 'Number',
        flex: 1,
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'host',
        headerName: 'Host',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterOperators: ALL_STRING_OPERATORS,
        renderCell: ({ row }) => <CopyToClipboard value={row?.host} field="host" handleCellHighlight={handleCellHighlight}/>,
      },
      {
        field: 'port',
        headerName: 'Port',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterOperators: ALL_STRING_OPERATORS,
        renderCell: ({ row }) => <CopyToClipboard value={row?.port} field="port" handleCellHighlight={handleCellHighlight}/>,
      },
      {
        field: 'username',
        headerName: 'Username',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterOperators: ALL_STRING_OPERATORS,
        renderCell: ({ row }) => <CopyToClipboard value={row?.username} field="username" handleCellHighlight={handleCellHighlight}/>,
      },
      {
        field: 'password',
        headerName: 'Password',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterOperators: ALL_STRING_OPERATORS,
        renderCell: ({ row }) => <CopyToClipboard value={row?.password} field="password" handleCellHighlight={handleCellHighlight}/>,
        filterable: false,
      },
      {
        field: 'country.name',
        headerName: 'Country',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        valueGetter: ({ row }) => row.countryName,
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'connectedBots',
        headerName: 'Connected Bots',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterable: false,
        sortable: false,
        renderCell: ({ row }) => (
          <Tooltip
            placement="right"
            title={
              row.connectedBots.length ? (
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {row.connectedBots.map((item: string, ind: number) => (
                    <li key={ind} style={{ marginBottom: '4px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                'There is no any connected bots.'
              )
            }
          >
            <Box>
              <SmartToyOutlinedIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'tags',
        headerName: 'Tags',
        headerAlign: 'center',
        type: 'String',
        align: 'center',
        flex: 2,
        filterable: false,
        sortable: false,
        renderCell: ({ row }) => (
          <Tooltip
            placement="right"
            title={
              row.tags.length ? (
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {row.tags.map((item: string, ind: number) => (
                    <li key={ind} style={{ marginBottom: '4px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                'There is no any added tags.'
              )
            }
          >
            <Box>
              <LocalOfferIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },

      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('DELETE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Delete Proxy"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Proxy"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { modal, setModal, singleRow, setSingleRow } = useActions<ProxiesTabActions>()
  const { data, isFetching } = useGetProxiesQuery(queryParams)

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<ProxiesTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={ProxiesTableToolbar}
        checkboxSelection
        {...paginationDataGridProps}
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
        onCellClick={() => setIsHighlight(false)}
        sx={{
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: isHighlight ? '' : 'none',
            },
        }}
      />
    </Box>
  )
}
