import { showNotification } from './showNotification'

const copyToClipboard = (textToCopy: string, message: string): Promise<void> => {
  return navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      showNotification(`${message}`, 'success')
    })
    .catch((err) => {
      console.error(err)
      showNotification('Error. Try again later', 'error')
    })
}

export default copyToClipboard
