import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useDeleteBotsMutation } from 'services/bots/api'
import { Bot } from 'services/bots/types'

import { CreateBotModal } from 'components/bots/bots/CreateBotModal/index'
import { CreateBotsModal } from 'components/bots/bots/CreateBotsModal/index'
import { EditBotModal } from 'components/bots/bots/EditBotModal/index'
import ConfirmModal from 'components/common/ConfirmModal'
import TableToolbar from 'components/table/TableToolbar/index'

import { BotsTabActions } from 'pages/bots/BotsTab/index'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: BotsTabActions
  setModal: React.Dispatch<React.SetStateAction<BotsTabActions>>
  singleRow: any
}

export const BotsTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const [deleteBots] = useDeleteBotsMutation()

  const confirmDelete = async () => {
    if (modal === 'DELETE') {
      deleteBots({ botIds: selectedRows.map((item: Bot) => item.id) })
        .unwrap()
        .then(() => {
          showNotification(
            selectedRows.length === 1
              ? `Bot successfully deleted`
              : `Selected Bots successfully deleted`,
            'success'
          )
          setModal('')
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      deleteBots({ botIds: [singleRow.id] })
        .unwrap()
        .then(() => {
          showNotification(`Bot successfully deleted`, 'success')
          setModal('')
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }
  const confirmMessage =
    modal === 'DELETE'
      ? selectedRows.length > 1
        ? 'Are you sure you want to delete selected bots?'
        : 'Are you sure you want to delete selected bot?'
      : 'Are you sure you want to delete selected bot?'
  return (
    <>
      <TableToolbar
        onChange={onSearchChange}
        searchValue={searchValue}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('CREATE_BOT')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Create Bot</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setModal('CREATE_BOTS')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Create Bots</Typography>
                </MenuItem>
                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Bot</Typography>
                  </MenuItem>
                ) : null}
                {selectedRows.length >= 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('DELETE')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">
                      Delete Bot{selectedRows.length > 1 && 's'}
                    </Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
          </Box>
        }
      />
      {modal === 'CREATE_BOT' && (
        <CreateBotModal
          onClose={() => {
            setModal('')
          }}
        />
      )}
      {modal === 'CREATE_BOTS' && (
        <CreateBotsModal
          onClose={() => {
            setModal('')
          }}
        />
      )}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditBotModal
          onClose={() => {
            setModal('')
          }}
          bot={modal === 'EDIT' ? selectedRows[0] : singleRow}
        />
      )}
      {(modal === 'DELETE' || modal === 'DELETE_SINGLE') && (
        <ConfirmModal open={true} onClose={() => setModal('')} onConfirm={confirmDelete}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
    </>
  )
}
