import { yupResolver } from '@hookform/resolvers/yup'
import React, { ChangeEvent, FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useEditScrapTasksMutation } from 'services/dashboard/api'
import { ScrapTask } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import Switch from 'components/common/Switch/index'
import TextInput from 'components/common/TextInput/index'
import { EDIT_SCRAP_TASKS } from 'components/mails/scrap-tasks/EditScrapTasksModal/validation'

interface FormValues {
  enable: boolean
  subjects: (string | undefined)[]
  senders: (string | undefined)[]
  recipients: (string | undefined)[]
  excludedSenders: (string | undefined)[]
  excludedSubjects: (string | undefined)[]
  excludedRecipients: (string | undefined)[]
}

interface Props {
  onClose: () => void
  scrapTask: ScrapTask
}

export const EditScrapTasksModal: FC<Props> = ({ scrapTask, onClose }) => {
  const [editScrapTasks, { isLoading }] = useEditScrapTasksMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(EDIT_SCRAP_TASKS),
    defaultValues: {
      enable: scrapTask.enable || false,
      subjects: scrapTask.subjects || [],
      senders: scrapTask.senders || [],
      recipients: scrapTask.recipients || [],
      excludedSenders: scrapTask.excludeOptions.excludedSenders || [],
      excludedSubjects: scrapTask.excludeOptions.excludedSubjects || [],
      excludedRecipients: scrapTask.excludeOptions.excludedRecipients || [],
    },
  })
  const onSubmit = async ({
    excludedSenders,
    excludedRecipients,
    excludedSubjects,
    ...rest
  }: FormValues) => {
    editScrapTasks({
      ...rest,
      name: scrapTask.name,
      excludeOptions: {
        excludedSenders,
        excludedRecipients,
        excludedSubjects,
      },
    })
      .unwrap()
      .then(() => {
        showNotification('Task edited', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const handleArraySetValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: any
  ) => {
    setValue(name, e.target.value.split(','))
  }
  return (
    <AppModal title={`Edit Scrap Task: ${scrapTask.name}`} onClose={onClose}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '700px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          <Switch sx={{ alignSelf: 'center' }} control={control} name={'enable'} />
          <TextInput
            control={control}
            name="subjects"
            label="Subjects  (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'subjects')}
          />
          <TextInput
            control={control}
            name="senders"
            label="Senders (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'senders')}
          />
          <TextInput
            control={control}
            name="recipients"
            label="Recipients (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'recipients')}
          />
          <TextInput
            control={control}
            name="excludedSenders"
            label="Excluded Senders (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedSenders')}
          />
          <TextInput
            control={control}
            name="excludedSubjects"
            label="Excluded Subjects (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedSubjects')}
          />
          <TextInput
            control={control}
            name="excludedRecipients"
            label="Excluded Recipients (separated by comma)"
            errors={errors}
            onChangeHandler={(e) => handleArraySetValue(e, 'excludedRecipients')}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading} fullWidth={isMobileOnly}>
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
