import { yupResolver } from '@hookform/resolvers/yup'
import { skipToken } from '@reduxjs/toolkit/query'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { showNotification } from 'utils/showNotification'

import { useDownloadConfirmationMutation } from 'services/bookings/api'
import {
  useCreateListingMutation,
  useGetCardsToSelectQuery,
  useUpdateListingMutation,
  useUploadConfirmationFileMutation,
  useUploadOrderScreenshotFileMutation,
} from 'services/purchasing/api'
import { Listing, NewListing } from 'services/purchasing/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { CustomDropzone } from 'components/common/CustomDropzone/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import DatePicker from 'components/common/DatePicker'
import TextInput from 'components/common/TextInput'

import { NEW_LISTING_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  listing?: Listing
}

interface FormValues {
  event:
    | string
    | {
        name?: string
        id?: string
        categories?: {
          name?: string
          id?: string
        }[]
      }
  user:
    | string
    | {
        name: string
        value: string
      }
  orderedDateCet: string
  numberOfTickets: number
  eventCategoryId: {
    name: string
    value: string
  }
  cardId: {
    value: string
    name: string
  }
  note?: string
  bookingConfirmationFilename: string | File[]
  orderScreenshotFilename: string | File[]
}

const EditListingModal: FC<Props> = ({ onClose, listing }) => {
  const [createListing, { isLoading: isSaving }] = useCreateListingMutation()
  const [updateListing, { isLoading: isUpdate }] = useUpdateListingMutation()
  const [downloadConfirmation] = useDownloadConfirmationMutation()
  const [uploadConfirmationFile, { isLoading: isUploading }] = useUploadConfirmationFileMutation()
  const [uploadOrderScreenshot, { isLoading: isUploadingScreenshot }] =
    useUploadOrderScreenshotFileMutation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(NEW_LISTING_SCHEMA),
    mode: 'onBlur',
    shouldFocusError: false,
    defaultValues: {
      event: listing?.eventCategory
        ? {
            name: listing?.eventCategory.eventName,
            id: listing?.eventCategory.eventId?.toString(),
            categories: [
              { name: listing?.eventCategory.name, id: listing?.eventCategory.id.toString() },
            ],
          }
        : '',
      user: listing?.user ? { name: listing?.user.username, value: listing?.user.password } : '',
      orderedDateCet: listing?.orderedDateCet
        ? format(new Date(listing.orderedDateCet), "yyyy-MM-dd'T'HH:mm:ss")
        : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      numberOfTickets: listing?.numberOfTickets,
      eventCategoryId: {
        value: listing?.eventCategory.id.toString() || '',
        name: listing?.eventCategory.name || '',
      },
      cardId: {
        value: listing?.card?.id?.toString() || '',
        name: listing?.card?.num?.toString() || '',
      },
      note: listing?.note,
      bookingConfirmationFilename: listing?.bookingConfirmationFile,
      orderScreenshotFilename: listing?.ordersScreenshotFile,
    },
  })

  const event = watch('event')
  const user = watch('user')

  const categoriesToSelect =
    (typeof event === 'object' &&
      event?.categories?.map((category: any) => ({
        name: category.name || '',
        value: category.id || '',
      }))) ||
    []

  const { data: cardsList = [] } = useGetCardsToSelectQuery(
    typeof user === 'object' && user?.name ? { botLogin: user.name } : skipToken,
    { refetchOnMountOrArgChange: true }
  )
  const cardsToSelect = listing
    ? [{ name: listing.card?.num, value: listing.card?.id?.toString() }, ...cardsList]
    : cardsList

  const onSubmit = async (newListing: FormValues) => {
    if (!user) return
    let confirmationFilename = listing?.bookingConfirmationFile || ''
    let screenshotFilename = listing?.ordersScreenshotFile || ''

    if (
      typeof newListing.bookingConfirmationFilename === 'object' &&
      newListing.bookingConfirmationFilename.length > 0
    ) {
      const formDataPdf = new FormData()
      formDataPdf.append('bookingConfirmation', newListing.bookingConfirmationFilename[0])
      await uploadConfirmationFile(formDataPdf)
        .unwrap()
        .then(({ resultFilename }) => {
          confirmationFilename = resultFilename
          clearErrors('bookingConfirmationFilename')
          setValue('bookingConfirmationFilename', resultFilename)
          showNotification('Document successfully uploaded', 'success')
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
          return
        })
    }

    if (
      typeof newListing.orderScreenshotFilename === 'object' &&
      newListing.orderScreenshotFilename.length > 0
    ) {
      const formDataImg = new FormData()
      formDataImg.append('orderScreenshot', newListing.orderScreenshotFilename[0])

      await uploadOrderScreenshot(formDataImg)
        .unwrap()
        .then(({ resultFilename }) => {
          screenshotFilename = resultFilename
          clearErrors('orderScreenshotFilename')
          setValue('orderScreenshotFilename', resultFilename)
          showNotification('Screenshot successfully uploaded', 'success')
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
          return
        })
    }

    const listingData: NewListing = {
      ...newListing,
      eventCategoryId: +newListing.eventCategoryId.value,
      user: typeof newListing.user === 'object' ? newListing.user?.name : '',
      numberOfTickets: +newListing.numberOfTickets,
      bookingConfirmationFilename: confirmationFilename,
      orderScreenshotFilename: screenshotFilename,
      password: typeof newListing.user === 'object' ? newListing.user?.value : '',
      note: newListing?.note || null,
      cardId: +newListing.cardId.value,
      orderedDateCet: newListing.orderedDateCet.split('+')[0],
    }

    if (listing && confirmationFilename && screenshotFilename) {
      await updateListing({ ...listingData, id: listing.id })
        .unwrap()
        .then(() => {
          showNotification('Listing successfully updated', 'success')
          onClose()
        })
        .catch(() => {
          showNotification('Error. Try again later', 'error')
          return
        })
    } else {
      createListing(listingData)
        .unwrap()
        .then(() => {
          showNotification('Listing successfully created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }
  return (
    <AppModal
      title={listing ? 'Edit listing' : 'Create listing'}
      onClose={onClose}
      loading={isSaving || isUpdate || isUploading || isUploadingScreenshot}
    >
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ width: '650px' }}>
          <ControlledSelectAutocomplete
            name="event"
            label="Event"
            control={control}
            errors={errors}
            url={`/api/events/suggestion`}
            transformOption={({ name, id, categories }) => ({
              name: name,
              value: id,
              categories,
            })}
          />
          <ControlledSelectAutocomplete
            name="user"
            label="User"
            control={control}
            errors={errors}
            url={`/api/purchasing/listings/suggested-users`}
            transformOption={(item) => ({ name: item.username, value: item.password })}
          />
          <Box display="flex" gap={2}>
            <ControlledSelectAutocomplete
              name="eventCategoryId"
              label="Category"
              errors={errors}
              control={control}
              selectOptions={categoriesToSelect}
              sx={{ flex: 1 }}
            />
            <TextInput
              name="numberOfTickets"
              label="Number Of Tickets"
              errors={errors}
              control={control}
              type="number"
              sx={{ flex: 1 }}
            />
          </Box>
          <Box display="flex" gap={2}>
            <DatePicker
              name="orderedDateCet"
              label="Start Date"
              control={control}
              errors={errors}
              sx={{ flex: 1 }}
            />
            <ControlledSelectAutocomplete
              name="cardId"
              label="Card"
              errors={errors}
              control={control}
              selectOptions={cardsToSelect}
              sx={{ flex: 1 }}
            />
          </Box>
          <TextInput
            name="note"
            label="Notes"
            errors={errors}
            control={control}
            multiline
            minRows={3}
          />
          <Box display="flex" mt="40px" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '300px',
              }}
            >
              <Typography fontSize="14px" fontWeight={500} pb={1} color="text.secondary">
                Booking confirmation file for listing
              </Typography>
              <CustomDropzone
                setValue={setValue}
                control={control}
                name={'bookingConfirmationFilename'}
                noMediaTitle={'Attach file'}
                acceptedFormats={{
                  'application/pdf': ['.pdf'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                }}
                formatsAsLabel=".pdf, .jpg, jpeg, png"
                viewType="droparea"
                error={errors?.bookingConfirmationFilename?.message as string}
                maxSize={2 * 2 ** 20}
                downloadConfirmation={downloadConfirmation}
                isPdf={true}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '300px',
              }}
            >
              <Typography fontSize="14px" fontWeight={500} pb={1} color="text.secondary">
                Screenshot of successful order
              </Typography>
              <CustomDropzone
                setValue={setValue}
                control={control}
                name={'orderScreenshotFilename'}
                noMediaTitle={'Attach file'}
                acceptedFormats={{
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                }}
                formatsAsLabel=".jpg, jpeg, png"
                viewType="droparea"
                error={errors?.orderScreenshotFilename?.message as string}
                maxSize={2 * 2 ** 20}
                downloadConfirmation={downloadConfirmation}
                isPdf={false}
              />
            </Box>
          </Box>
        </AppModal.ContentBox>

        <AppModal.ButtonsBox>
          <Button
            loading={isSaving || isUpdate || isUploading || isUploadingScreenshot}
            variant="outlined"
            color="warning"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSaving || isUpdate || isUploading || isUploadingScreenshot}
            fullWidth={isMobileOnly}
          >
            {listing ? 'Update' : 'Create'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}

export default EditListingModal
