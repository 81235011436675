import { QueryTableState } from 'types/TableServerRequest'

export interface GetAccountsPayload extends QueryTableState {
  showTaken: boolean
}

export interface GetListingsPayload extends QueryTableState {
  showArchived: boolean
}

interface IpData {
  ip: string
  continentCode: string
  continentName: string
  countryCode2: string
  countryCode3: string
  countryName: string
  countryCapital: string
  stateProv: string
  district: string
  city: string
  zipcode: string
  latitude: string
  longitude: string
  isEu: boolean
  callingCode: string
  countryTld: string
  languages: string
  countryFlag: string
  geonameId: string
  isp: string
  connectionType: string
  organization: string
  currency: {
    code: string
    name: string
    symbol: string
  }
  timeZone: {
    name: string
    offset: number
    currentTime: string
    currentTimeUnix: number
    isDst: boolean
    dstSavings: number
  }
}

interface Proxy {
  id: number
  host: string
  port: string
  username: string
  password: string
  status: string
  blocksCount: number
  captchaCount: number
  totalSearches: number
  lastUsage: null | string
  lastBlock: null | string
  countryId: number
  bot: null | string
  blocksPercent: number
  captchaPercent: number
  paused: boolean
  ipData: IpData
  ip: null | string
  dnsServer: null | string
  uniqueCounter: number
  label: number
  supportedCountries: string[]
}

interface TicketSystem {
  id: number
  name: string
  site: string
  createdAt: string
}

interface SettingsAccount {
  title: string
  lastName: string
  firstName: string
  birth: string
  phone: string
  address: string
  country: string
  zipCode: string
  city: string
  login: string
  email: string
  secQuestionNum: number
  secQuestion: string
  secAnswer: string
  cls: string
}

interface Settings {
  pause: boolean
  lastEventCategoryId: null | string
  cardToApproveId: null | string
  actualBasketId: null | string
  needClearBasket: boolean
  needSendTickets: boolean
  userAgent: null | string
  queueAhead: null | string
  account: SettingsAccount
  applicationTarget: null | string
}

export interface Account {
  id: number
  login: string
  password: string
  status: number
  proxy: Proxy
  ticketSystem: TicketSystem
  settings: Settings
  card: null | CardDetails
}

export interface CardDetails {
  id: number
  num: string
  name: string
  month: string
  year: string
  needApprove: boolean
  cardType: number
  balance: number | null
  botsCount: number | null
  cvv: string
}

export const BotStatus = {
  '0': 'Not Archived',
  '10': 'Deleted',
  '20': 'Unauthorized',
  '30': 'Ticket Limit Exceeded',
  '40': 'Basket Blocked',
  '50': 'Scraped By Spider',
  '60': 'Preregistered',
  '70': 'Application Success',
} as const

export const BOT_STATUSES = Object.entries(BotStatus).map(([value, name]) => ({
  value,
  name,
  label: name,
}))

interface EventGroup {
  id: number
  name: string
  url: null | string
}

export interface EventGoal {
  id: number
  name: string
  priority: number
  ticketGoals: {
    [key: string]: number
  }
}

export interface Event {
  id: number
  name: string
  date: string
  url: string
  goals: EventGoal[]
  eventGroup: EventGroup
}

export interface EventCategory {
  id: number
  name: string
  eventId: number
  eventName: string
  eventDate: string
  eventGroupId: number
  eventGroupName: string
  currency: { name: string; rateToEur: number }
}

export interface Listing {
  id: number
  eventCategory: EventCategory
  orderedDateCet: string
  totalTicketsHp: number
  numberOfTickets: number
  user: { username: string; password: string }

  note: string
  card: Card
  bookingConfirmationFile: string
  ordersScreenshotFile: string
}

export interface NewListing {
  eventCategoryId: number
  orderedDateCet: string
  user?: string
  password?: string
  note: string | null
  numberOfTickets: number
  cardId: number
  bookingConfirmationFilename: string
  orderScreenshotFilename: string
}

export interface Card {
  id: number
  num: string
}
