import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import Box from '@mui/material/Box/Box'

import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import { useEditBotMutation, useGetBotsCreateQuery } from 'services/bots/api'
import { Bot } from 'services/bots/types'
import { BotStatus, BOT_STATUSES } from 'services/purchasing/types'

import { EDIT_BOT_SCHEMA } from 'components/bots/bots/EditBotModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput'

interface Props {
  onClose: () => void
  bot: Bot
}

// ticketSystemId: number
// proxyId: number
// password: string
// archivedStatus: number
// botId: number

interface FormValues {
  ticketSystemId: {
    value: string
    name: string
  }
  proxyId: number
  password: string
  archivedStatus: {
    value: string
    name: string
  }
}

export const EditBotModal: FC<Props> = ({ onClose, bot }) => {
  const [editBot, { isLoading }] = useEditBotMutation()
  const { data } = useGetBotsCreateQuery()
  const ticketsSystemsOptions = useMemo(
    () => data?.ticketSystems.map((v) => ({ name: v.name, value: v.id + '' })),
    [data]
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(EDIT_BOT_SCHEMA),
    defaultValues: {
      ticketSystemId: {
        value: bot?.ticketSystem?.id?.toString() || '',
        name: bot?.ticketSystem?.name || '',
      },
      proxyId: bot?.proxy?.id ?? 0,
      password: bot?.password ?? '',
      archivedStatus: {
        value: bot?.archivedStatus?.toString() || '',
        name:
          (bot?.archivedStatus &&
            BotStatus[bot.archivedStatus.toString() as keyof typeof BotStatus]) ||
          '',
      },
    },
  })

  const generatePassword = () => {
    setValue('password', passwordGenerator())
    if (errors?.password?.message) clearErrors('password')
  }

  const onSubmit = async ({ ticketSystemId, proxyId, password, archivedStatus }: FormValues) => {
    await editBot({
      ticketSystemId: +ticketSystemId.value,
      proxyId,
      password,
      archivedStatus: +archivedStatus.value,
      botId: bot.id,
    })
      .unwrap()
      .then(() => {
        showNotification('Bot updated successfully', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  return (
    <AppModal title="Edit Bot" onClose={onClose} loading={isLoading}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <ControlledSelectAutocomplete
            name="ticketSystemId"
            label="Ticket System"
            control={control}
            errors={errors}
            selectOptions={ticketsSystemsOptions}
          />

          <Box
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-start',
            }}
          >
            <TextInput name="password" label="Password" errors={errors} control={control} />
            <Button variant="text" onClick={generatePassword} sx={{ mx: 1, mt: '4px' }}>
              <AutorenewIcon />
            </Button>
          </Box>
          <TextInput name="proxyId" label="Proxy ID" errors={errors} control={control} />
          <ControlledSelectAutocomplete
            name="archivedStatus"
            label="Archived Status"
            control={control}
            errors={errors}
            selectOptions={BOT_STATUSES}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button onClick={onClose} variant="outlined" color="warning">
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
