import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import TableToolbar from 'components/table/TableToolbar'

import { EventGroupActions } from 'pages/events/EventGroupsTab/EventGroupsTable'

import AddEventGroupModal from '../AddEventGroupModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: EventGroupActions
  setModal: React.Dispatch<React.SetStateAction<EventGroupActions>>
  singleRow: any
}

const EventGroupsTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('ADD')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Add Event Group</Typography>
                </MenuItem>
                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Event Group</Typography>
                  </MenuItem>
                ) : null}
              </Menu>
            </Box>
          </Box>
        }
      />
      {modal === 'ADD' && <AddEventGroupModal onClose={() => setModal('')} />}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <AddEventGroupModal
          eventGroupData={modal === 'EDIT' ? selectedRows[0] : singleRow}
          onClose={() => setModal('')}
        />
      )}
    </>
  )
}

export default EventGroupsTableToolbar
