import { useMemo } from 'react'

import FlagIcon from '@mui/icons-material/Flag'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_DATE_OPERATORS,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'
import { showNotification } from 'utils/showNotification'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useEditPauseGoalsEventMutation, useGetEventsQuery } from 'services/events/api'
import { EventCategoryStat, GOALS_MODE_STATUS_TYPE } from 'services/events/types'

import EventsTableToolbar from 'components/events/events/EventsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

export type EventActions =
  | 'ADD'
  | 'EDIT'
  | 'SET_GOALS'
  | 'EDIT_SINGLE'
  | 'SET_GOALS_SINGLE'
  | 'CONFIRM'
  | 'CONFIRM_SINGLE'
  | ''
const AUTOMATIC_BUY = [
  { value: true, label: 'Play' },
  { value: false, label: 'Pause' },
]
const EventsTable = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'eventGroup.name',
        headerName: 'Event group',
        type: 'String',
        flex: 2,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventGroup?.name,
        filterOperators: [
          createAutocompleteOperator('/api/events/distinct', 'event_group.name'),
          createNotEqualsAutocompleteOperator('/api/events/distinct', 'event_group.name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'name',
        headerName: 'Event Name',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/events/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/events/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center">
            {row.name}
            <OpenInNewIcon
              fontSize="small"
              sx={{ ml: 1, cursor: 'pointer' }}
              onClick={() => window.open(row.eventGroup.url, '_blank')}
            />
          </Box>
        ),
      },
      {
        field: 'date',
        headerName: 'Event Date',
        type: 'Datetime',
        flex: 1.5,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'automaticBuy',
        headerName: 'Automatic Buy',
        type: 'singleSelect',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        valueOptions: AUTOMATIC_BUY,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handlePause([row?.id], row?.automaticBuy)}
            >
              {row.automaticBuy ? (
                <Tooltip title="Enabled">
                  <PauseCircleIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Disabled">
                  <PlayCircleIcon />
                </Tooltip>
              )}
            </Box>
          )
        },
      },
      {
        field: 'eventCategoriesStats',
        headerName: 'Goals',
        sortable: false,
        flex: 2,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value }) => (
          <Tooltip
            placement="right"
            title={
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {value?.map((i: EventCategoryStat) => {
                  const ticketGoalsString = Object.entries(i?.settings)
                    .map(([key, val]) => {
                      const ticketWord = +key === 1 ? 'ticket' : 'tickets'
                      if (!val) {
                        return ''
                      } else if (val === -1) {
                        return `${key} ${ticketWord}: ∞`
                      } else {
                        return `${key} ${ticketWord} x ${val}`
                      }
                    })
                    .filter((s) => s)
                    .join(', ')

                  return (
                    <li key={i.name}>
                      {i.name} :{' '}
                      <ul>
                        <li>
                          <b>{GOALS_MODE_STATUS_TYPE[i.goalsMode] || ''}</b>
                        </li>{' '}
                        <li>{ticketGoalsString}</li>
                      </ul>
                    </li>
                  )
                })}
              </ul>
            }
          >
            <Box>
              <FlagIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },
      // {
      //   field: 'goalsMode',
      //   headerName: 'Goals Mode',
      //   sortable: false,
      //   flex: 2,
      //   headerAlign: 'center',
      //   align: 'center',
      //   filterable: false,
      //   valueGetter: ({ row }) => row.goalsMode,
      //   valueFormatter: ({ value }) => {
      //     const methodKey = value as GoalsModeKeyType
      //     return GOALS_MODE_STATUS_TYPE[methodKey] || ''
      //   },
      //   // valueOptions: GOALS_MODE_OPTIONS_TO_SELECT,
      //   // // @ts-ignore
      //   // filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      //   renderCell: ({ row }) => (
      //     <Chip
      //       label={GOAL_MODE_STYLE?.[row?.sentStatus as GoalsModeKeyType]?.text}
      //       color={GOAL_MODE_STYLE?.[row?.sentStatus as GoalsModeKeyType]?.color}
      //     />
      //   ),
      // },
      {
        field: 'priority',
        headerName: 'Priority',
        sortable: false,
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip
            placement="right"
            title={
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {row.eventCategoriesStats?.map((i: any) => (
                  <li key={i.id} style={{ marginBottom: '4px' }}>
                    {i.name}: {i.priority}
                  </li>
                ))}
              </ul>
            }
          >
            <Box>
              <LowPriorityIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 3,
        hideable: false,
        type: 'String',

        filterOperators: [
          createAutocompleteOperator('/api/events/distinct', 'description'),
          createNotEqualsAutocompleteOperator('/api/events/distinct', 'description'),
        ],
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Event"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setSingleRow(params.id)
                setModal('CONFIRM_SINGLE')
              }}
              label="Archive Event"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('SET_GOALS_SINGLE')
                setSingleRow(params.row)
              }}
              label="Set Goals"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const [pauseGoal] = useEditPauseGoalsEventMutation()
  const handlePause = (id: number[], data: boolean) => {
    pauseGoal({ eventIds: id, paused: data })
      .unwrap()
      .then(() => {
        showNotification(`Automatic buy is  ${data ? 'disabled' : 'enabled'}`, 'success')
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { modal, setModal, singleRow, setSingleRow } = useActions<EventActions>()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { data, isFetching } = useGetEventsQuery(queryParams)
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<EventActions>
        rows={data?.data || []}
        columns={columns}
        columnVisibilityModel={{
          matchNumber: false,
          description: false,
        }}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={EventsTableToolbar}
        rowHeight={80}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}

export default EventsTable
