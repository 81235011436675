import { yupResolver } from '@hookform/resolvers/yup'
import { axiosApi } from 'api'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import useAuth from 'hooks/useAuth'

import PasswordInput from 'components/auth/PasswordInput'
import Button from 'components/common/Button'
import Input from 'components/common/TextInput'

import { loginSchema } from './validation'
import { showNotification } from 'utils/showNotification'

interface FormValues {
  login: string
  password: string
}

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      login: '',
      password: '',
    },
  })

  const { user, login } = useAuth()
  const navigateTo = location?.state?.from?.pathname || '/dashboard'

  if (user) {
    return <Navigate to={navigateTo} replace />
  }

  const onSubmit = async (values: FormValues) => {
    try {
      const { data } = await axiosApi.post('api/users/login', values)
      login(data?.user)
      navigate(navigateTo)
    } catch (error) {
      console.error('Login error:', error)
      showNotification('Invalid login or password', 'error')
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Box width={560}>
        <Box fontSize={32} lineHeight="120%" fontWeight={600}>
          Sign In
        </Box>

        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ my: 3 }}>
          <Input
            size="medium"
            control={control}
            name="login"
            label="Login"
            errors={errors}
            sx={{ my: 2 }}
          />
          <PasswordInput
            name="password"
            label="Password"
            control={control}
            errors={errors}
            sx={{ my: 2 }}
          />

          <Button size="large" disabled={false} type="submit" fullWidth sx={{ mt: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Login
