import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { MutationDefinition } from '@reduxjs/toolkit/query'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { TTagType } from 'api/api'

import { Bot } from 'services/bots/types'
import { Account } from 'services/purchasing/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'

type CardDataType = { cardTypes: string[]; cardNames: string[] } | undefined
type AssignCardType = MutationTrigger<
  MutationDefinition<
    {
      botId: number
      cardName: string
      cardType: string
    },
    BaseQueryFn<
      {
        url: string
        method: string | undefined
        data?: any
        params?: any
      },
      unknown,
      unknown,
      {},
      {}
    >,
    TTagType,
    {},
    'api'
  >
>

interface FormValues {
  cardName: {
    value: string
    name: string
  }
  cardType: {
    value: string
    name: string
  }
}

interface Props {
  onClose: () => void
  selectedAccount: Account | Bot
  assignCardHandler: AssignCardType
  isLoading: boolean
  data: CardDataType
  isFetching: boolean
}

export const AssignCardModal = ({
  onClose,
  selectedAccount,
  assignCardHandler,
  isLoading,
  data,
  isFetching,
}: Props) => {
  const cardNamesToSelect = data?.cardNames?.map((i) => ({ value: i, name: i })) || []
  const cardTypesToSelect = data?.cardTypes?.map((i) => ({ value: i, name: i })) || []

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: {
      cardName: {
        value: '',
        name: '',
      },
      cardType: {
        value: '',
        name: '',
      },
    },
  })

  const onSubmit = ({ cardName, cardType }: FormValues) => {
    assignCardHandler({
      botId: selectedAccount.id,
      cardName: cardName?.value,
      cardType: cardType?.value,
    })
      .unwrap()
      .then(() => {
        showNotification(`Card successfully assigned to ${selectedAccount.login}`, 'success')
        onClose()
      })
      .catch((error: any) => {
        showNotification('Error. Try again later', 'error')
        console.error(error)
      })
  }

  return (
    <AppModal onClose={onClose} title={`Assign Card to ${selectedAccount.login}`} maxWidth={600}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ minWidth: '556px', height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ height: 'fit-content', flexDirection: 'row', width: '100%' }}>
          <ControlledSelectAutocomplete
            name="cardName"
            label="Card Name"
            errors={errors}
            control={control}
            disabled={isFetching}
            selectOptions={cardNamesToSelect}
            sx={{ flex: 1 }}
          />
          <ControlledSelectAutocomplete
            name="cardType"
            label="Card Type"
            errors={errors}
            control={control}
            disabled={isFetching}
            selectOptions={cardTypesToSelect}
            sx={{ flex: 1 }}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button loading={isLoading} color="warning" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading} fullWidth={isMobileOnly}>
            Assign
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
