export interface BotStation {
  id: number
  ip: string
  normalBotsCount: number
  botWorkingTimeLimit: number
  logicFunctionNumber: number
  browserArgs: any
  isLocal: boolean
  isActive: boolean
  browserMode: BrowserModeType
  mockOptions: {
    name: string
    enabled: boolean
  }[]
  eventGroupDetails: { id: number; name: string } | null
  proxyMasks: string[] | null
  extraOptions: any
}

export interface EditBotStation {
  id: number
  eventGroupId: number
  normalBotsCount: number
  browser: number
  logicFunctionNumber: number
  proxyTags: (string | undefined)[]
  mockOptions: {
    name: string
    enabled: boolean
  }[]
  browserConfig: any
  extraOptions: any
}

export interface PauseBotStation {
  botStationsIds: number[]
}

export interface BotLog {
  id: number
  botDetails: {
    id: number
    login: string
    ticketSystemName: string
    stationIp: string
  }
  logDetails: {
    logLevel: number
    logText: string
    createdAt: string
  }
}

export interface ViewBotsLogsPayload {
  botId: number
  startDate?: string | null
  endDate?: string | null
  logLevel?: number | null
  searchString?: string | null
  limit?: number | null
}

export const LOG_LEVELS = {
  '0': 'NOTSET',
  '10': 'DEBUG',
  '20': 'INFO',
  '30': 'WARNING',
  '40': 'ERROR',
  '50': 'CRITICAL',
} as const
export type LogLevelsType = keyof typeof LOG_LEVELS

export const LOG_LEVELS_OPTIONS = Object.entries(LOG_LEVELS).map(([value, name]) => ({
  value,
  name,
}))

export interface ViewBotsLogsResponse {
  botId: number
  logs: {
    logLevel: number
    logText: string
    createdAt: string
  }[]
  totalCount: number
}

export interface Mail {
  id: number
  sender: string
  recipient: string
  subject: string
  receivedAt: string
  catcherId: number
  catcherName: string
}

export interface GetMailMessageResponse {
  htmlContent: string
  files: string[]
}

export interface GetMailMessagePayload {
  catcherId: number
  mailId: number
}

export const BROWSER_MODE = {
  '0': 'CHROME',
  '1': 'GHOST',
  '2': 'FIREFOX',
  '3': 'CUSTOM',
} as const
export type BrowserModeType = keyof typeof BROWSER_MODE

export const BROWSER_MODE_OPTIONS = Object.entries(BROWSER_MODE).map(([value, name]) => ({
  value,
  name,
  label: name,
}))

export interface ScrapTask {
  name: string
  enable: boolean
  senders: string[]

  recipients: string[]
  subjects: string[]
  ticketSystemId: number
  ticketSystemName: string
  excludeOptions: {
    excludedSenders: string[]
    excludedSubjects: string[]
    excludedRecipients: string[]
  }
}

export interface EditScrapTask {
  name: string
  enable: boolean
  senders: (string | undefined)[]
  excludeOptions: {
    excludedSenders: (string | undefined)[]
    excludedSubjects: (string | undefined)[]
    excludedRecipients: (string | undefined)[]
  }

  recipients: (string | undefined)[]
  subjects: (string | undefined)[]
}

export interface CreateReportPayload {
  sqlQuery: string
}

export interface GetTemplatesPayload {
  reports: {
    id: number
    name: string
    sqlQuery: string
  }[]
}

export interface UpdateTemplatesPayload {
  reportsList: {
    id?: number | null
    name: string
    sqlQuery: string
  }[]
}

export interface DeleteTemplatePayload {
  ids: number[]
}

interface DashboardStatsResponseProperty {
  data: {
    statTime: string
    items: { [key: string]: number }
  }[]
  captions: { [key: string]: string }
  title: string
}

export interface DashboardStatsResponse {
  charts: DashboardStatsResponseProperty[]
}

export interface WorkloadStatsResponse {
  hdd: {
    total: string
    free: string
    used: string
    pgWeight: string
    chWeight: string
    logsWeight: string
  }
  ramAndCpu: {
    ramTotal: string
    ramAvailable: string
    ramUsed: string
    ramFree: string
    ramPercent: string
    cpuPercent: string
  }
}
