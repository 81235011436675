import React, { FC, useMemo } from 'react'

import OutboxIcon from '@mui/icons-material/Outbox'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip/Chip'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  ALL_NUMBER_OPERATORS,
  ALL_STRING_OPERATORS,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetSalesQuery } from 'services/bookings/api'
import {
  SENT_STATUS_OPTIONS_TO_SELECT,
  SENT_STATUS_TYPE,
  SentStatusType,
} from 'services/bookings/types'

import { SendTicketsModal } from 'components/bookings/Sales/SendTicketsModal/index'
import CustomDataGrid from 'components/table/DataGrid'

import { SALE_STATUS_STYLE } from 'pages/bookings/ListingsTab'

export const SENT_STATUS_STYLE = {
  0: { text: 'Not Sent', color: 'error' },
  10: { text: 'Sent', color: 'primary' },
  20: { text: 'Deferred', color: 'warning' },
  30: { text: 'Partially Sent', color: 'info' },
} as const

export type SalesModalActions = 'SEND_TICKETS' | 'SEND_TICKETS_SINGLE' | ''
interface IProps {
  listingId: number
  price: number
}

const InnerListingTable: FC<IProps> = ({ listingId, price }) => {
  const { modal, setModal, singleRow, setSingleRow } = useActions<SalesModalActions>()

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 0.5,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'sentStatusHp',
        headerName: 'Sent status',
        type: 'singleSelect',
        flex: 1.7,
        resizable: true,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row.sentStatus,
        valueFormatter: ({ value }) => {
          const methodKey = value as SentStatusType
          return SENT_STATUS_TYPE[methodKey] || ''
        },
        valueOptions: SENT_STATUS_OPTIONS_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (
          <Chip
            label={SENT_STATUS_STYLE?.[row?.sentStatus as SentStatusType]?.text}
            color={SALE_STATUS_STYLE?.[row?.sentStatus as SentStatusType]?.color}
          />
        ),
      },
      {
        field: 'active',
        headerName: 'Sale status',
        type: 'singleSelect',
        flex: 1.7,
        resizable: true,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row.active,
        valueOptions: [
          { value: true, label: 'On Sale' },
          { value: false, label: 'Sold' },
        ],
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (
          <Chip label={row.active ? 'On Sale' : 'Sold'} color={row.active ? 'warning' : 'info'} />
        ),
      },
      {
        field: 'price',
        headerName: 'Price',
        type: 'Number',
        flex: 1.7,
        resizable: true,
        valueGetter: () => price,
        filterable: false,
        sortable: false,
      },
      {
        field: 'numberOfTickets',
        headerName: 'Number of Tickets',
        type: 'Number',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row?.tickets?.length,
        filterable: false,
        sortable: false,
      },
      {
        field: 'platformOrderId',
        headerName: 'Order ID',
        type: 'String',
        flex: 1.7,
        resizable: true,
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return !params.row.active
            ? [
                <GridActionsCellItem
                  key={params.id}
                  onClick={() => {
                    setModal('SEND_TICKETS_SINGLE')
                    setSingleRow(params.row)
                  }}
                  label=""
                  icon={
                    <Tooltip title="Send tickets">
                      <OutboxIcon />
                    </Tooltip>
                  }
                />,
              ]
            : []
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetSalesQuery({
    ...queryParams,
    completed: false,
    filters: [{ column: 'listing.id', operator: '=', values: [listingId], valueType: 'Number' }],
  })
  return (
    <Box sx={{ height: '400px', width: '100%', pt: 1 }}>
      <CustomDataGrid<SalesModalActions>
        rows={data?.data || []}
        columns={columns}
        columnVisibilityModel={{
          matchNumber: false,
          description: false,
        }}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        rowHeight={80}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
      {modal === 'SEND_TICKETS_SINGLE' && (
        <SendTicketsModal onClose={() => setModal('')} sale={singleRow} />
      )}
    </Box>
  )
}
export default InnerListingTable
