import { GridColDef, GridFilterModel } from '@mui/x-data-grid'

import { FilterOperator, FilterValueType, IFilterParam } from 'types/TableServerRequest'

import { dateFormatHelper } from 'utils/DateFormatHelper'
import { camelToSnake, capitalize } from 'utils/text'

export const handleFilterChangeHelper = (model: GridFilterModel, columns: GridColDef[]) => {
  const newFilterParams: IFilterParam[] = []
  model.items.map((item) => {
    const column = columns.find((col) => col.field === item.field)
    if (item.operator === 'between' && column?.type?.toLowerCase() === 'datetime') {
      if (item.value && Array.isArray(item.value)) {
        const lowerBoundFilter: IFilterParam = {
          column: camelToSnake(item.field),
          operator: '>=',
          values: [
            (item.value[0] && dateFormatHelper(item.value[0])) ||
              dateFormatHelper(new Date(Date.now())),
          ],
          valueType: 'Datetime',
        }
        const upperBoundFilter: IFilterParam = {
          column: camelToSnake(item.field),
          operator: '<=',
          values: [
            (item.value[1] && dateFormatHelper(item.value[1])) ||
              dateFormatHelper(new Date(Date.now())),
          ],
          valueType: 'Datetime',
        }
        if (item.value[0]) newFilterParams.push(lowerBoundFilter, upperBoundFilter)
      }
    }
    if (item.operator === 'between' && column?.type?.toLowerCase() === 'number') {
      if (item.value && Array.isArray(item.value)) {
        const lowerBoundFilter: IFilterParam = {
          column: camelToSnake(item.field),
          operator: '>=',
          values: [+item.value[0]],
          valueType: 'Number',
        }
        const upperBoundFilter: IFilterParam = {
          column: camelToSnake(item.field),
          operator: '<=',
          values: [+item.value[1] || 1],
          valueType: 'Number',
        }
        if (item.value[0]) newFilterParams.push(lowerBoundFilter, upperBoundFilter)
      }
    }
    if (item.operator !== 'between') {
      const filter: IFilterParam = {
        column: camelToSnake(item.field),
        operator: item.operator as FilterOperator,
        values:
          item.value !== null && item.value !== undefined && item.value !== ''
            ? Array.isArray(item.value)
              ? [...item.value]
              : [item.value]
            : [],
        valueType:
          column?.type === 'singleSelect'
            ? typeof item.value === 'boolean'
              ? 'Boolean'
              : 'Number'
            : (typeof column?.type === 'string' &&
                (capitalize(column?.type!) as FilterValueType)) ||
              'Number',
      }
      newFilterParams.push(filter)
    }
  })
  return newFilterParams
}
