import { AxiosResponse } from 'axios'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { debounce } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField, { TextFieldVariants } from '@mui/material/TextField'

import { camelToSnake } from 'utils/text'

import { axiosApi } from 'api/index'

export interface AutocompleteOption {
  name: string
  value: string
}

export interface CommonSelectAutocompleteProps
  extends Omit<
    AutocompleteProps<any, any, any, any>,
    'options' | 'renderInput' | 'onChange' | 'value'
  > {
  name?: string
  value: AutocompleteOption | AutocompleteOption[]
  onChange: (value: AutocompleteOption | AutocompleteOption[]) => void
  inputRef?: any
  errors?: any
  label?: string
  url?: string
  transformOption?: (item: any) => AutocompleteOption
  column?: string
  columnValue?: string
  selectOptions?: AutocompleteOption[]
  noOptionsText?: string
  variant?: TextFieldVariants
}

export const CommonSelectAutocomplete: FC<CommonSelectAutocompleteProps> = ({
  value,
  onChange,
  inputRef = null,
  errors,
  label,
  url,
  transformOption,
  column,
  columnValue,
  name = column || '',
  selectOptions,
  noOptionsText,
  disabled,
  multiple,
  sx,
  variant = 'outlined',
  size,
  ...rest
}) => {
  const [options, setOptions] = useState<AutocompleteOption[]>(selectOptions || [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectOptions) {
      setOptions(selectOptions)
    }
  }, [JSON.stringify(selectOptions)])

  const columnWithBackendCase = column && camelToSnake(column)
  const columnValueWithBackendCase = columnValue && camelToSnake(columnValue)
  const fetchOptions = async (searchValue: string) => {
    setLoading(true)
    if (url) {
      try {
        const { data }: AxiosResponse<{ suggestions: any[]; data?: any[] }> = await axiosApi.post(
          url,
          column
            ? {
                column: columnWithBackendCase,
                search_string: searchValue,
                search_map: {
                  name: columnWithBackendCase,
                  value: columnValueWithBackendCase || columnWithBackendCase,
                },
              }
            : { substring: searchValue }
        )

        if (Array.isArray(data?.suggestions) && transformOption) {
          const newOptions = data.suggestions
            .map(transformOption)
            .filter((option) => option !== null)
          setOptions(newOptions)
        } else if (Array.isArray(data?.data) && transformOption) {
          const newOptions = data.data.map(transformOption).filter((option) => option !== null)
          setOptions(newOptions)
        } else if (Array.isArray(data?.data)) {
          setOptions(data.data)
        } else {
          console.error('Response data is not an array', data)
        }
      } catch (error) {
        console.error('Error fetching options', error)
      }
    }
    setLoading(false)
  }
  const debouncedSetSearchValue = useCallback(
    debounce(async (value: string) => {
      await fetchOptions(value)
    }, 300),
    [column, columnValue]
  )
  return (
    <Autocomplete
      {...rest}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        if (newValue) onChange(newValue)
      }}
      getOptionLabel={(option) => option.name ?? ''}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.name}
          </li>
        )
      }}
      multiple={multiple}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      ChipProps={{
        size: 'small',
      }}
      noOptionsText={noOptionsText || 'No options'}
      onInputChange={async (event, newInputValue) => {
        if (newInputValue.length > 1) {
          await debouncedSetSearchValue(newInputValue)
        }
      }}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          error={typeof errors === 'string' ? !!errors : !!errors?.[name]?.value?.message}
          helperText={typeof errors === 'string' ? errors : errors?.[name]?.value?.message}
          inputRef={inputRef}
          disabled={disabled}
          size={size}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            height: 'fit-content',
            minHeight: '70px',
          }}
        />
      )}
    />
  )
}
