import { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'

import { showNotification } from 'utils/showNotification'

import { useEditListingTagsMutation } from 'services/bookings/api'
import { BookingsListing } from 'services/bookings/types'

import { Tags } from 'components/bookings/listings/tags/Tags'
import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'

interface Props {
  item: BookingsListing
  onClose: () => void
}
interface FormType {
  tagIds: number[]
}
export const EditTagsModal = ({ item, onClose }: Props) => {
  const [editTags, { isLoading: isSaving }] = useEditListingTagsMutation()
  const { register, watch, setValue, handleSubmit } = useForm<FormType>({
    defaultValues: {
      tagIds: [],
    },
  })

  useEffect(() => {
    setValue(
      'tagIds',
      item.tags.map((tag) => tag.id as number)
    )
  }, [])

  const onSubmit = async (data: FormType) => {
    await editTags({ listingId: item.id, tagIds: data.tagIds })
      .unwrap()
      .then(() => {
        showNotification('Tags successfully updated', 'success')
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }

  return (
    <AppModal title="Edit Tags" onClose={onClose} maxWidth={400} loading={isSaving}>
      <AppModal.Wrapper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <Box
            sx={{
              m: 2,
            }}
          >
            <Tags register={register} watch={watch} setValue={setValue} />
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button loading={isSaving} color="warning" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSaving} fullWidth={isMobileOnly}>
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
