import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs from 'components/common/NavigationTabs'

const tabs = [
  { id: 'accounts', label: 'ACCOUNTS' },
  { id: 'in-work', label: 'IN WORK' },
  { id: 'goals', label: 'GOALS' },
  { id: 'listings', label: 'LISTINGS' },
]

const Purchasing: FC = () => {
  return (
    <Box sx={{ height: 'calc(100vh - 80px)' }}>
      <NavigationTabs tabs={tabs} basePath="/purchasing" />
      <Outlet />
    </Box>
  )
}
export default Purchasing
