import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const USER_SCHEMA = yup.object().shape({
  login: yup.string().trim().required(requiredMessage).min(3, 'Min 3 symbols'),
  name: yup.string().trim().required(requiredMessage).min(2, 'Min 2 symbols'),
  password: yup.string().when('$user', (user, schema) =>
    user[0]
      ? schema.test('isPassword', 'Min 4 symbols', (v) => {
          if (v === undefined || v === '') {
            return true
          } else if (v.length >= 4) {
            return true
          }
        })
      : schema.required(requiredMessage).min(4, 'Min 4 symbols')
  ),
  role: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
})
