import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { showNotification } from 'utils/showNotification'

import { useCreateBotMutation, useGetBotsCreateQuery } from 'services/bots/api'

import { CREATE_BOT_SCHEMA } from 'components/bots/bots/CreateBotModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'

interface Props {
  onClose: () => void
}

interface FormValues {
  accountId: any | ''
  ticketSystemId: {
    value: string
    name: string
  }
  proxyTags?:
    | {
        value?: string
        name?: string
      }[]
    | null
}

export const CreateBotModal: FC<Props> = ({ onClose }) => {
  const { data } = useGetBotsCreateQuery()
  const [createBot, { isLoading: isCreating }] = useCreateBotMutation()

  const proxyTagsOptions = useMemo(
    () => data?.proxyTags.map((v) => ({ name: v, value: v })) || [],
    [data]
  )
  const ticketsSystemsOptions = useMemo(
    () => data?.ticketSystems.map((v) => ({ name: v.name, value: v.id + '' })),
    [data]
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(CREATE_BOT_SCHEMA),
    defaultValues: {
      accountId: '',
      ticketSystemId: {
        value: '',
        name: '',
      },
      proxyTags: [],
    },
  })
  const onSubmit = async ({ accountId, ticketSystemId, proxyTags }: FormValues) => {
    await createBot({
      accountId: accountId.value,
      ticketSystemId: +ticketSystemId.value,
      proxyTags: proxyTags?.map((value) => value?.name) || null,
    })
      .unwrap()
      .then(() => {
        showNotification('Bot successfully created', 'success')
        onClose()
      })
      .catch(() => showNotification('Error.Try again later', 'error'))
  }

  return (
    <AppModal title="Create Bot" onClose={onClose} loading={isCreating}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: 'fit-content' }}
      >
        <AppModal.ContentBox>
          <ControlledSelectAutocomplete
            name="accountId"
            label="Account"
            control={control}
            errors={errors}
            url="/api/bots/accounts/distinct"
            column="email"
            columnValue="id"
            transformOption={({ name, value }) => ({
              name,
              value,
            })}
          />
          <ControlledSelectAutocomplete
            name="ticketSystemId"
            label="Ticket System"
            control={control}
            errors={errors}
            selectOptions={ticketsSystemsOptions}
          />

          <ControlledSelectAutocomplete
            name="proxyTags"
            label="Proxy Tags"
            control={control}
            selectOptions={proxyTagsOptions}
            multiple={true}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button onClick={onClose} variant="outlined" color="warning">
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
