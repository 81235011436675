import { createTheme } from '@mui/material/styles'

const primary = {
  main: '#007bff',
  text: '#222b45',
}

export const theme = createTheme({
  palette: {
    background: {
      default: '#edf1f7',
    },
    primary,
    secondary: {
      main: '#E0F1F0',
    },
    text: {
      primary: primary.text,
      secondary: '#797D80',
      disabled: '#A6A8AA',
    },
    warning: {
      main: '#ce1637',
      light: '#ee6881',
      dark: '#a42e44',
    },
    error: {
      main: '#FC395C',
      light: '#FFEBEF',
      dark: '#FFD9E0',
    },
    grey: {
      300: '#D2D4D5',
      600: '#797D80',
    },
    common: {
      black: primary.text,
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontWeightRegular: 500,
    fontSize: 14,
    h2: {
      fontSize: 30,
      fontWeight: 700,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: 1.44,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '48px',

          '&:hover': {
            border: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '0',
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '20px',
          height: '48px',
          padding: '14px 16px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '& .MuiButton-endIcon': {
            margin: '0 0 0 4px',
            fontSize: '24px',
            width: '24px',
            height: '24px',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
            width: '24px',
            height: '24px',
          },
        },
        sizeSmall: {
          padding: '10px',
          height: '40px',
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          '&:hover': {
            color: '#007bff',
          },
          '&:active': {
            color: '#007bff',
          },
          '&.Mui-disabled': {
            color: '#797D80',
            opacity: 1,
          },
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f0f0f0',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root a': {
            color: primary.main,
          },
          '& .MuiTypography-root': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          lineHeight: '144%',
          textDecoration: 'none',
          minWidth: 0,
          textTransform: 'capitalize',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          minHeight: 'fit-content',
          '& .MuiDataGrid-menuList': {
            minWidth: 'auto',
            '& .MuiListItemText-primary': {
              fontSize: 14,
            },
            '& .MuiMenuItem-root': {
              //height: '40px',
            },
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          height: 'fit-content',
          minHeight: 'fit-content',
          '& .MuiDataGrid-panelContent': {
            minHeight: 'fit-content',
            //height: '100%'
          },
          '& .MuiDataGrid-filterForm': {
            minHeight: 'fit-content',
            height: '100%',
            width: '710px',
            display: 'flex',
            gap: '16px',
          },
          '& .MuiDataGrid-filterFormValueInput': {
            minHeight: 'fit-content',
            width: '250px',
          },
          '& .MuiDataGrid-filterFormDeleteIcon': {
            alignSelf: 'flex-start',
          },
          '& .MuiCalendarOrClockPicker-root': {
            width: 'fit-content',
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
})