import React, { FC, MouseEvent, useState } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useArchiveEventMutation } from 'services/events/api'
import { Event } from 'services/events/types'

import ConfirmModal from 'components/common/ConfirmModal/index'
import SetGoalsModal from 'components/events/events/SetGoalsModal/index'
import TableToolbar from 'components/table/TableToolbar'

import { EventActions } from 'pages/events/EventsTab/EventsTable'

import { EditEventModal } from '../EditEventModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: EventActions
  setModal: React.Dispatch<React.SetStateAction<EventActions>>
  singleRow: any
}

const GoalsTableToolbar: FC<Props> = ({
  searchValue,
  onSearchChange,
  selectedRows,
  modal,
  setModal,
  singleRow,
}) => {
  const [archieveEvent, { isLoading: isArchiveLoading }] = useArchiveEventMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const eventsIds = selectedRows.map((item: Event) => item.id)
  const confirmMessage =
    modal === 'CONFIRM'
      ? `Are you sure you want to archive selected event${selectedRows.length === 1 ? '' : 's'} ?`
      : `Are you sure you want to archive selected event?`

  const confirmArchive = async () => {
    if (modal === 'CONFIRM')
      await archieveEvent({
        archive: true,
        eventIds: eventsIds,
      })
        .unwrap()
        .then(() => {
          showNotification(
            `Event${selectedRows.length === 1 ? '' : 's'} successfully archived`,
            'success'
          )
          setModal('')
        })
        .catch((error) => {
          showNotification(`Error. Try again later`, 'error')
          console.error(error)
        })
    else
      await archieveEvent({
        archive: true,
        eventIds: [singleRow],
      })
        .unwrap()
        .then(() => {
          showNotification(`Event successfully archived`, 'success')
          setModal('')
        })
        .catch((error) => {
          showNotification(`Error. Try again later`, 'error')
          console.error(error)
        })
  }
  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    setModal('ADD')
                    handleCloseMenu()
                  }}
                >
                  <Typography textAlign="center">Add Event</Typography>
                </MenuItem>
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Event</Typography>
                  </MenuItem>
                )}
                {selectedRows.length ? (
                  <MenuItem
                    onClick={() => {
                      setModal('CONFIRM')
                      handleCloseMenu()
                    }}
                  >
                    Archive Event{selectedRows.length === 1 ? '' : 's'}
                  </MenuItem>
                ) : null}
                {selectedRows.length === 1 && (
                  <MenuItem
                    onClick={() => {
                      setModal('SET_GOALS')
                      handleCloseMenu()
                    }}
                  >
                    Set Goals
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        }
      />
      {(modal === 'CONFIRM' || modal === 'CONFIRM_SINGLE') && (
        <ConfirmModal
          open={true}
          onConfirm={confirmArchive}
          loading={isArchiveLoading}
          onClose={() => setModal('')}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            {confirmMessage}
          </Typography>
        </ConfirmModal>
      )}
      {modal === 'ADD' && <EditEventModal onClose={() => setModal('')} />}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <EditEventModal
          onClose={() => setModal('')}
          event={modal === 'EDIT' ? selectedRows[0] : singleRow}
        />
      )}
      {(modal === 'SET_GOALS' || modal === 'SET_GOALS_SINGLE') && (
        <SetGoalsModal
          onClose={() => setModal('')}
          goalsData={
            modal === 'SET_GOALS'
              ? selectedRows[0].eventCategoriesStats
              : singleRow.eventCategoriesStats
          }
        />
      )}
    </>
  )
}

export default GoalsTableToolbar
