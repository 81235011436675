import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { JSONTree } from 'react-json-tree'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import { PieChart } from '@mui/x-charts/PieChart'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { createPieSeries, createSeries } from 'utils/createSeriesForChartsHelpers'

import { useActions } from 'hooks/useActions'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { DetailsModal, theme } from 'components/common/DetailsModal'
import { ReportsTableToolbar } from 'components/dashboard/reports/ReportsTableToolbar'

export type ReportsTabActions = 'CREATE_REPORT' | 'EDIT_TEMPLATES' | 'INFO' | ''
export type SelectedTabType = 'TABLE' | 'BAR_CHART' | 'LINE_CHART' | 'PIE_CHART'

export const ReportsTab = () => {
  const { modal, setModal, singleRow, setSingleRow } = useActions<ReportsTabActions>()
  const [tab, setTab] = useState<SelectedTabType>('TABLE')

  const [data, setData] = useState<{
    table: { row: { columnName: string; value: any }[] }[]
    fileName: string
  }>({ table: [], fileName: '' })

  const handleDetailsClick = useCallback((row: any) => {
    setModal('INFO')
    setSingleRow(row)
  }, [])

  const columns = data?.table?.[0]?.row?.map((row: any) => ({
    field: row.columnName,
    flex: 1,
    renderCell: () => {
      if (row?.value === null || row?.value === undefined)
        return <Typography color={'text.secondary'}>[NULL]</Typography>
      if (typeof row?.value === 'object') {
        if (_.isEmpty(row.value)) {
          return <Typography color={'text.secondary'}>[EMPTY_OBJECT]</Typography>
        }
        return (
          <Button
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              handleDetailsClick(row.value)
            }}
          >
            Show Details
          </Button>
        )
      }
    },
  }))
  const rows = data?.table?.map((row, ind) => {
    return {
      id: ind,
      ...row?.row?.reduce((prev, next) => ({ ...prev, [next.columnName]: next.value }), {}),
    }
  })

  const BAR_CHART_SERIES = rows?.length ? createSeries(rows?.map(({ id, ...rest }) => rest)) : [] // eslint-disable-line

  const BAR_CHART_SERIES_LENGTH = BAR_CHART_SERIES.length

  const yAxis = BAR_CHART_SERIES.find((item) => typeof item.data[0] === 'number')
  const yAxisInd = yAxis && BAR_CHART_SERIES.indexOf(yAxis)
  const seriesBeforeYAxis = (yAxisInd || yAxisInd === 0) && BAR_CHART_SERIES.slice(yAxisInd + 1)

  const xAxis = (Array.isArray(seriesBeforeYAxis) && seriesBeforeYAxis[0]) || {
    data: [],
    label: '',
  }

  const PIE_CHART_SERIES = createPieSeries(yAxis, xAxis)

  const renderAs = {
    TABLE: <DataGridPro rows={rows || []} columns={columns || []} rowCount={0} hideFooter />,
    BAR_CHART: (
      <Box id="chartToDownload" sx={{ width: '100%', height: '100%' }}>
        <BarChart
          series={yAxis?.data ? [yAxis] : []}
          xAxis={[{ scaleType: 'band', label: xAxis?.label, data: xAxis?.data ?? [] }]}
        />
      </Box>
    ),
    LINE_CHART: (
      <Box id="chartToDownload" sx={{ width: '100%', height: '100%' }}>
        <LineChart
          series={yAxis?.data ? [yAxis] : []}
          xAxis={[{ scaleType: 'band', label: xAxis?.label, data: xAxis?.data ?? [] }]}
        />
      </Box>
    ),
    PIE_CHART: (
      <Box id="chartToDownload" sx={{ width: '100%', height: '100%' }}>
        {PIE_CHART_SERIES[0]?.data && (
          <PieChart
            series={[PIE_CHART_SERIES[0]]}
            xAxis={[{ scaleType: 'band', label: xAxis?.label, data: xAxis?.data ?? [] }]}
          />
        )}
      </Box>
    ),
  }

  return (
    <Box sx={{ pt: 1 }}>
      <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}>
        <ReportsTableToolbar
          chartSeriesLength={BAR_CHART_SERIES_LENGTH}
          tab={tab}
          setTab={setTab}
          modal={modal}
          setModal={setModal}
          setData={setData}
          reportFileName={data?.fileName}
        />
        <Box
          sx={{
            height: 'calc(100vh - 285px)',
            padding: '0 16px 16px 16px',
          }}
        >
          {renderAs[tab]}
        </Box>
      </Box>
      {modal === 'INFO' && (
        <AppModal
          title="Details"
          onClose={() => {
            setModal('')
            setSingleRow(null)
          }}
          maxWidth={800}
        >
          <AppModal.Wrapper sx={{ maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}>
            <Box sx={{ width: 800 }} px={2}>
              <JSONTree hideRoot theme={theme} data={singleRow} invertTheme />
            </Box>
          </AppModal.Wrapper>
        </AppModal>
      )}
    </Box>
  )
}
