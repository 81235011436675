import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import copyToClipboard from 'utils/clipboardUtils'
import { formatCardNumber } from 'utils/formatCardNumber'

import { BotCard } from 'services/bots/types'
import { CardDetails } from 'services/purchasing/types'

import { AppModal } from 'components/common/AppModal/index'

interface Props {
  onClose: () => void
  card: BotCard | CardDetails
}

const CardInfoPopup = ({ onClose, card }: Props) => {
  const handleValueClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const fieldName = event.currentTarget.getAttribute('data-field-name')
    const valueToCopy = event.currentTarget.value
    copyToClipboard(valueToCopy, `${fieldName} copied to clipboard`)
  }

  return (
    <AppModal title="Card Info" onClose={onClose}>
      <Box p={2} display="grid" gap={1}>
        <Box>
          Card number:
          <Button
            size="small"
            variant="text"
            onClick={handleValueClick}
            value={card.num}
            data-field-name="Card number"
          >
            {formatCardNumber(card.num)}
          </Button>
        </Box>
        <Box>
          Card cvv:
          <Button
            size="small"
            variant="text"
            onClick={handleValueClick}
            value={card.cvv}
            data-field-name="Card cvv"
          >
            {card.cvv}
          </Button>
        </Box>
        <Box>
          Card date:
          <Button
            size="small"
            variant="text"
            onClick={handleValueClick}
            value={card.month}
            data-field-name="Card expiration month"
          >
            {card.month}
          </Button>
          /
          <Button
            size="small"
            variant="text"
            onClick={handleValueClick}
            value={card.year}
            data-field-name="Card expiration year"
          >
            {card.year}
          </Button>
        </Box>
        <Box>
          Card holder:
          <Button
            size="small"
            variant="text"
            onClick={handleValueClick}
            value={card.name}
            data-field-name="Card holder"
          >
            {card.name}
          </Button>
        </Box>
      </Box>
    </AppModal>
  )
}

export default CardInfoPopup
