import React, { FC } from 'react'

import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import TableToolbar from 'components/table/TableToolbar'

const LogsTableToolbar: FC<Partial<GridToolbarProps & ToolbarPropsOverrides>> = ({
  searchValue,
  onExportButtonClick,
  onSearchChange,
}) => {
  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
      />
    </>
  )
}

export default LogsTableToolbar
