import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import Box from '@mui/material/Box/Box'
import Button from '@mui/material/Button'

import copyToClipboard from 'utils/clipboardUtils'
import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import { useEditPasswordMutation } from 'services/purchasing/api'

import { AppModal } from 'components/common/AppModal'
import TextInput from 'components/common/TextInput'

import { CHANGE_PASSWORD_SCHEMA } from './validation'

interface Props {
  onClose: () => void
  selectedAccountId: number
}

interface FormValues {
  password: string
}

export const EditPassword = ({ onClose, selectedAccountId }: Props) => {
  const [editPassword, { isLoading }] = useEditPasswordMutation()

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(CHANGE_PASSWORD_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      password: '',
    },
  })

  const generatePassword = () => {
    setValue('password', passwordGenerator())
    if (errors?.password?.message) clearErrors('password')
  }

  const onSubmit = async (formValues: FormValues) => {
    await editPassword({ botId: selectedAccountId, newPassword: formValues.password })
      .unwrap()
      .then(() => {
        showNotification('Password successfully changed.', 'success')
        copyToClipboard(formValues.password, `Password was copied to clipboard`)
        onClose()
      })
      .catch(() => {
        showNotification('Error. Try again later', 'error')
      })
  }

  return (
    <AppModal title="Edit password" onClose={onClose} loading={isLoading} maxWidth={400}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ height: 'fit-content' }}
      >
        <AppModal.ContentBox sx={{ height: 'fit-content' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <TextInput name="password" label="Password" errors={errors} control={control} />
            <Button variant="text" onClick={generatePassword} sx={{ mx: 1, mt: '4px' }}>
              <AutorenewIcon />
            </Button>
          </Box>
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" fullWidth={isMobileOnly} disabled={isLoading}>
            Update
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
