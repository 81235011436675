import { ReportRequest } from 'types/ReportFileRequest'
import { QueryTableState } from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import {
  ArchiveListingPayload,
  ArchiveListingResponse,
  BookingsCard,
  BookingsListing,
  BookingsSalesPlatform,
  EditCard,
  EditSalePlatform,
  GetListingsPayload,
  NewCard,
  SuggestionEvent,
  Tag,
  TagResponse,
  SuggestionsResponse,
  SuggestionUser,
  NewSalePlatform,
  NewBookingListing,
  EditBookingListing,
  RegisterSalePayload,
  RegisterOnSalePayload,
  UtilitySalesPlatform,
  AddTag,
  EditSalePayload,
  Sale,
  SendTicketsPayload,
} from './types'

export const bookingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBookingsListing: builder.query<TableServerResponse<BookingsListing[]>, GetListingsPayload>({
      query: (body) => ({
        url: '/api/bookings/listings/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    getBookingListingById: builder.query<{ data: BookingsListing }, { id: number }>({
      query: (body) => ({
        url: `/api/bookings/listings?listing_id=${body.id}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    getListingReport: builder.mutation<Blob, ReportRequest>({
      query: (body) => ({
        url: '/api/bookings/listings/report',
        method: 'POST',
        data: body,
        responseType: 'blob',
      }),
    }),
    downloadConfirmation: builder.mutation<Blob, { filename: string; disposition: string }>({
      query: (body) => ({
        url: '/api/bookings/download-booking-confirmation',
        method: 'POST',
        data: body,
        responseType: 'blob',
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    createBookingListing: builder.mutation<{ msg: string }, NewBookingListing>({
      query: (body) => ({
        url: '/api/bookings/listings',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    editBookingListing: builder.mutation<{ msg: string }, EditBookingListing>({
      query: (body) => ({
        url: `/api/bookings/listings`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    deleteBookingListing: builder.mutation<{ msg: string }, {listingIds: number[]}>({
      query: (data) => ({
        url: `/api/bookings/listings`,
        method: 'DELETE',
        data,
      }),
      //invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    editListingTags: builder.mutation<{ msg: string }, { listingId: number; tagIds: number[] }>({
      query: (body) => ({
        url: `/api/bookings/listings/tags`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [
        { type: 'Tags', id: 'TAGS' },
        { type: 'BookingListings', id: 'BOOKING_LISTING' },
      ],
    }),
    registerSale: builder.mutation<{ msg: string }, RegisterSalePayload>({
      query: (body) => ({
        url: '/api/bookings/listings/register-sale',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    registerOnSale: builder.mutation<{ msg: string }, RegisterOnSalePayload>({
      query: (body) => ({
        url: '/api/bookings/listings/register-on-sale',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    editSale: builder.mutation<{ msg: string }, EditSalePayload>({
      query: (body) => ({
        url: '/api/bookings/listings/sales',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    deleteSale: builder.mutation<{ msg: string }, { saleIds: number[] }>({
      query: (body) => ({
        url: '/api/bookings/listings/sales',
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    getBookingsCards: builder.query<TableServerResponse<BookingsCard[]>, GetListingsPayload>({
      query: (body) => ({
        url: '/api/bookings/cards/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Cards', id: 'CARDS' }],
    }),

    addBookingsCard: builder.mutation<{ msg: string }, NewCard>({
      query: (body) => ({
        url: '/api/bookings/cards',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Cards', id: 'CARDS' }],
    }),
    updateBookingsCard: builder.mutation<{ msg: string }, EditCard>({
      query: (body) => ({
        url: `/api/bookings/cards`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Cards', id: 'CARDS' }],
    }),
    deleteBookingsCards: builder.mutation<{ msg: string }, { cardIds: number[] }>({
      query: (body) => ({
        url: `/api/bookings/cards`,
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Cards', id: 'CARDS' }],
    }),
    archiveListing: builder.mutation<ArchiveListingResponse, ArchiveListingPayload>({
      query: (body) => ({
        url: '/api/bookings/listings/archive',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingListings', id: 'BOOKING_LISTING' }],
    }),
    getCardsReport: builder.mutation<Blob, ReportRequest>({
      query: (body) => ({
        url: '/api/bookings/cards/report',
        method: 'POST',
        data: body,
        responseType: 'blob',
      }),
    }),
    getSalePlatforms: builder.query<TableServerResponse<BookingsSalesPlatform[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/bookings/sales-platforms/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'SalePlatforms', id: 'SALE_PLATFORMS' }],
    }),
    getSalesPlatfromsToSelect: builder.query<{ data: UtilitySalesPlatform[] }, void>({
      query: (body) => ({
        url: '/api/bookings/sales-platforms',
        method: 'GET',
        data: body,
      }),
      providesTags: () => [{ type: 'SalePlatforms', id: 'SALE_PLATFORMS' }],
    }),
    getSuggestedEvents: builder.query<
      SuggestionsResponse<SuggestionEvent[]>,
      { searchString: string }
    >({
      query: ({ searchString }) => ({
        url: `/api/events/suggestion`,
        method: 'POST',
        data: {
          substring: searchString,
          limit: 10,
        },
      }),
      providesTags: () => [{ type: 'SuggestedEvents', id: 'SUGGESTED_EVENTS' }],
    }),
    getTags: builder.query<Tag[], void>({
      query: () => ({
        url: `/api/bookings/listings/tags`,
        method: 'GET',
      }),
      transformResponse: (res: { data: TagResponse[] }) =>
        res?.data.map((tag) => ({
          id: tag.id,
          label: tag.caption,
          color: tag.color,
        })),
      providesTags: () => [{ type: 'Tags', id: 'TAGS' }],
    }),
    addTags: builder.mutation<{ msg: string }, AddTag>({
      query: (body) => ({
        url: '/api/bookings/listings/tags',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Tags', id: 'TAGS' }],
    }),
    addSalePlatform: builder.mutation<{ msg: string }, NewSalePlatform>({
      query: (body) => ({
        url: '/api/bookings/sales-platforms',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'SalePlatforms', id: 'SALE_PLATFORMS' }],
    }),
    editSalePlatform: builder.mutation<{ msg: string }, EditSalePlatform>({
      query: (body) => ({
        url: '/api/bookings/sales-platforms',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'SalePlatforms', id: 'SALE_PLATFORMS' }],
    }),
    getSuggestedUsers: builder.query<
      SuggestionsResponse<SuggestionUser[]>,
      { searchString: string }
    >({
      query: ({ searchString }) => ({
        url: `/api/bookings/listings/suggested-users`,
        method: 'POST',
        data: {
          substring: searchString,
          limit: 10,
        },
      }),
      providesTags: () => [{ type: 'SuggestedUsers', id: 'SUGGESTED_USERS' }],
    }),
    getSales: builder.query<TableServerResponse<Sale[]>, QueryTableState & { completed?: boolean }>(
      {
        query: (body) => ({
          url: '/api/bookings/sales/paging',
          method: 'POST',
          data: body,
        }),
        providesTags: () => [{ type: 'BookingsSales', id: 'BOOKING_SALE' }],
      }
    ),
    sendTickets: builder.mutation<{ msg: string }, SendTicketsPayload>({
      query: (body) => ({
        url: '/api/bookings/completed-sales/save',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'BookingsSales', id: 'BOOKING_SALE' }],
    }),
    uploadTicket: builder.mutation<{ resultFilename: string }, FormData>({
      query: (data) => ({
        url: `/api/bookings/upload-ticket`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const {
  useGetBookingsListingQuery,
  useGetBookingsCardsQuery,
  useAddBookingsCardMutation,
  useUpdateBookingsCardMutation,
  useDeleteBookingsCardsMutation,
  useArchiveListingMutation,
  useGetCardsReportMutation,
  useDeleteBookingListingMutation,
  useGetSalePlatformsQuery,
  useGetTagsQuery,
  useAddSalePlatformMutation,
  useEditSalePlatformMutation,
  useEditListingTagsMutation,
  useCreateBookingListingMutation,
  useEditBookingListingMutation,
  useGetBookingListingByIdQuery,
  useRegisterSaleMutation,
  useRegisterOnSaleMutation,
  useEditSaleMutation,
  useDeleteSaleMutation,
  useGetSalesQuery,
  useSendTicketsMutation,
  useGetSalesPlatfromsToSelectQuery,
  useGetListingReportMutation,
  useAddTagsMutation,
  useUploadTicketMutation,
  useDownloadConfirmationMutation,
} = bookingsApi
