import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const CARD_SCHEMA = yup.object().shape({
  num: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(/^([0-9]{16})$/, 'Must have only 16 digits'),
  name: yup.string().trim().required(requiredMessage),
  cvv: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(/^[0-9]{3,4}$/, 'Must have only 3-4 digits'),
  month: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(/^[0-1][0-9]$/, 'Must have leading zero if less then 10')
    .test('is-between-0-12', 'Must be from 00 to 12', (value) => +value > 0 && +value < 13),
  year: yup
    .string()
    .trim()
    .required(requiredMessage)
    .matches(/^\d{4}$/, 'Must have only 4 digits'),
  needApprove: yup.boolean().required(requiredMessage),
  cardType: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  balance: yup
    .number()
    .typeError('Must be a number')
    .required(requiredMessage)
    .min(0, 'Must be >= 0'),
})