import { requiredMessage } from 'validation'
import * as yup from 'yup'

export const REGISTER_ON_SALE_SCHEMA = yup.object().shape({
  salesPlatformId: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  salePriceEur: yup
    .number()
    .required(requiredMessage)
    .typeError('Must be a number')
    .min(0, 'Must be 0 or more'),
  priceOrCoefficient: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  platformUserId: yup
    .object()
    .shape({
      value: yup
        .string()
        .trim()
        //.required(requiredMessage)
        .when('salesPlatformId', (salesPlatformId, schema) =>
          salesPlatformId[0]?.value === '123' ? schema.required(requiredMessage) : schema
        ),
      name: yup
        .string()
        .trim()
        //.required(requiredMessage)
        .when('salesPlatformId', (salesPlatformId, schema) =>
          salesPlatformId[0]?.value === '123' ? schema.required(requiredMessage) : schema
        ),
    })
    .nullable()
    .transform((v) => (!v.value ? null : v)),
  eventId: yup
    .string()
    .when('salesPlatformId', (salesPlatformId, schema) =>
      salesPlatformId[0]?.value === '123' ? schema.required(requiredMessage).uuid() : schema
    ),
  splitType: yup.object().shape({
    value: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
  }),
  listings: yup
    .array()
    .required(requiredMessage)
    .of(
      yup.object().shape({
        listingId: yup.number().typeError('Must be a number'),
        salePriceEur: yup.number().typeError('Must be a number').min(0, 'Must be 0 or more'),
        splitType: yup.object().shape({
          value: yup.string().trim().required(requiredMessage),
          name: yup.string().trim().required(requiredMessage),
        }),
        priceOrCoefficient: yup.object().shape({
          value: yup.string().trim().required(requiredMessage),
          name: yup.string().trim().required(requiredMessage),
        }),
        numberOfTickets: yup
          .number()
          .typeError('Must be a number')
          .required(requiredMessage)
          .min(1, 'Must be 1 or more'),
      })
    ),
  platformListingId: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
})
