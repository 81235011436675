import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'

import { showNotification } from 'utils/showNotification'

import {
  useAddEventGroupMutation,
  useEditEventGroupMutation,
  useGetEventGroupDetailsQuery,
} from 'services/events/api'
import { EventGroup } from 'services/events/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import DatePicker from 'components/common/DatePicker'
import TextInput from 'components/common/TextInput'

import { EVENT_GROUP_SCHEMA } from './validation'

export const TIME_ZONE_OPTIONS = [
  { value: '-12', name: 'UTC -12:00' },
  { value: '-11', name: 'UTC -11:00' },
  { value: '-10', name: 'UTC -10:00' },
  { value: '-9', name: 'UTC -09:00' },
  { value: '-8', name: 'UTC -08:00' },
  { value: '-7', name: 'UTC -07:00' },
  { value: '-6', name: 'UTC -06:00' },
  { value: '-5', name: 'UTC -05:00' },
  { value: '-4', name: 'UTC -04:00' },
  { value: '-3', name: 'UTC -03:00' },
  { value: '-2', name: 'UTC -02:00' },
  { value: '-1', name: 'UTC -01:00' },
  { value: '0', name: 'UTC 00:00' },
  { value: '1', name: 'UTC +01:00' },
  { value: '2', name: 'UTC +02:00' },
  { value: '3', name: 'UTC +03:00' },
  { value: '4', name: 'UTC +04:00' },
  { value: '5', name: 'UTC +05:00' },
  { value: '6', name: 'UTC +06:00' },
  { value: '7', name: 'UTC +07:00' },
  { value: '8', name: 'UTC +08:00' },
  { value: '9', name: 'UTC +09:00' },
  { value: '10', name: 'UTC +10:00' },
  { value: '11', name: 'UTC +11:00' },
  { value: '12', name: 'UTC +12:00' },
  { value: '13', name: 'UTC +13:00' },
  { value: '14', name: 'UTC +14:00' },
]

interface FormValues {
  name: string
  ticketSystemId: {
    value: string
    name: string
  }
  url?: string | null
  timeDifUtc: {
    value: string
    name: string
  } | null
  country?: string | null
  currencyId: {
    value: string
    name: string
  } | null
  startDate?: string | null
  endDate?: string | null
  description?: string | null
}

interface Props {
  onClose: () => void
  eventGroupData?: EventGroup
}

const AddEventGroupModal: FC<Props> = ({ onClose, eventGroupData }) => {
  const [addEventGroup, { isLoading: isSaving }] = useAddEventGroupMutation()
  const [editEventGroup, { isLoading: isUpdating }] = useEditEventGroupMutation()
  const { data } = useGetEventGroupDetailsQuery()

  const currencyOptions = useMemo(
    () => data?.currencies?.map((v) => ({ value: v.id?.toString(), name: v.name })),
    [data]
  )

  const ticketSystemOptions = useMemo(
    () => data?.ticketSystem?.map((v) => ({ value: v.id?.toString(), name: v.name })),
    [data]
  )
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(EVENT_GROUP_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: eventGroupData?.name || '',
      ticketSystemId: {
        value: eventGroupData?.ticketSystem?.id.toString() || '2',
        name:
          eventGroupData?.ticketSystem?.name ||
          ticketSystemOptions?.find((v) => v?.value === '2')?.name,
      },
      url: eventGroupData?.url || '',
      timeDifUtc: {
        value: eventGroupData?.timeDifUtc?.toString() || '0',
        name: eventGroupData?.timeDifUtc?.toString() || 'UTC 00:00',
      },
      country: eventGroupData?.country || '',
      currencyId: {
        value: eventGroupData?.currency?.id?.toString() || '1',
        name:
          eventGroupData?.currency?.name?.toString() ||
          currencyOptions?.find((v) => v?.value === '1')?.name,
      },
      startDate: eventGroupData?.startDate || '',
      endDate: eventGroupData?.endDate || '',
      description: eventGroupData?.description || '',
    },
  })

  const onSubmit = async (newEventGroup: FormValues) => {
    if (eventGroupData) {
      editEventGroup({
        ...newEventGroup,
        id: eventGroupData?.id,
        timeDifUtc: newEventGroup?.timeDifUtc?.value ? +newEventGroup?.timeDifUtc?.value : 0,
        currencyId: newEventGroup?.currencyId?.value ? +newEventGroup?.currencyId?.value : 1,
        ticketSystemId: +newEventGroup?.ticketSystemId?.value,
      })
        .unwrap()
        .then(() => {
          showNotification('Event group successfully updated', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    } else {
      addEventGroup({
        ...newEventGroup,
        timeDifUtc: newEventGroup?.timeDifUtc?.value ? +newEventGroup?.timeDifUtc?.value : 0,
        currencyId: newEventGroup?.currencyId?.value ? +newEventGroup?.currencyId?.value : 1,
        ticketSystemId: newEventGroup?.ticketSystemId?.value
          ? +newEventGroup?.ticketSystemId?.value
          : 2,
      })
        .unwrap()
        .then(() => {
          showNotification('Event group successfully created', 'success')
          onClose()
        })
        .catch(() => showNotification('Error. Try again later', 'error'))
    }
  }

  return (
    <AppModal
      title={`${eventGroupData ? 'Edit' : 'Add'} event group`}
      onClose={onClose}
      loading={isSaving || isUpdating}
    >
      <AppModal.Wrapper
        sx={{ width: '800px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <AppModal.ContentBox sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextInput name="name" label="Event Group Name" errors={errors} control={control} />
              <TextInput name="url" label="URL" errors={errors} control={control} />
              <TextInput name="country" label="Country" errors={errors} control={control} />
              <DatePicker name="startDate" label="Start Date" errors={errors} control={control} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
              }}
            >
              <ControlledSelectAutocomplete
                selectOptions={ticketSystemOptions}
                name="ticketSystemId"
                label="Ticket System"
                errors={errors}
                control={control}
              />
              <ControlledSelectAutocomplete
                selectOptions={TIME_ZONE_OPTIONS}
                name="timeDifUtc"
                label="Time Zone"
                errors={errors}
                control={control}
              />
              <ControlledSelectAutocomplete
                selectOptions={currencyOptions}
                name="currencyId"
                label="Currency"
                errors={errors}
                control={control}
              />
              <DatePicker name="endDate" label="End Date" errors={errors} control={control} />
            </Box>
          </Box>
          <TextInput
            multiline
            rows={4}
            name="description"
            label="Event Description"
            errors={errors}
            control={control}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button
            variant="outlined"
            color="warning"
            onClick={onClose}
            disabled={isSaving || isUpdating}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth={isMobileOnly}
            disabled={isSaving || isUpdating}
          >
            {eventGroupData ? 'Update' : 'Add'}
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
export default AddEventGroupModal
