import { useState } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField/TextField'
import { GridFilterInputValueProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterInputValueProps'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

import { formatDate } from 'utils/dateFormat'
import { isValidDate } from 'utils/dateHelper'

export function DateInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props
  const [filterValueState, setFilterValueState] = useState<Date | null>(
    item?.value ? new Date(item.value) : null
  )

  const updateFilterValue = (val: Date | null) => {
    setFilterValueState(val)
    applyValue({
      ...item,
      value:
        val !== null && isValidDate(val) && isValidDate(formatDate(val)) ? formatDate(val) : null,
    })
  }
  const handleFilterChange = (val: Date | null) => {
    updateFilterValue(val)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'end',
        position: 'relative',
        maxWidth: '250px',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              variant: 'standard',
            },
          }}
          label="Date"
          value={filterValueState}
          onChange={(val) => handleFilterChange(val)}
        />
      </LocalizationProvider>
    </Box>
  )
}
