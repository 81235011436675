import { QueryTableState } from 'types/TableServerRequest'

export interface EventCategoryStat {
  id: number
  name: string
  settings: { [key: string]: number }
  priority: number
  goalsMode: GoalsModes
  priceLocalMin: number
  priceLocalMax: number
}

export interface Event {
  id: number
  name: string
  description: string
  url: string
  date: string
  venue: string
  city: string | null
  eventGroup: {
    id: number
    name: string
    ticketSystem: {
      id: number
      name: string
    }
    country: string | null
    startDate: string | null
    endDate: string | null
    description: string | null
    currency: {
      id: number
      name: string
    } | null
    url: string | null
  }
  eventCategoriesStats: EventCategoryStat[]
  automaticBuy: boolean
  automaticCard: boolean
  profit: number
  currency: {
    id: number
    name: string
    rateToEur: number
    additionalName: string | null
  }
  eventGroupId: number
  archivedStatus: number
  timeDifUtc: number
}

export interface CreateEvent {
  name: string
  currencyId: number
  eventGroupId: number
  date: string | null
  description?: string | null
  venue?: string | null
  timeDifUtc: number | null
  city?: string | null
  url?: string | null
  categories:
    | {
        name: string
        priority: number
        priceLocalMin: number
        priceLocalMax: number
        id?: number
      }[]
    | null
}

export interface EditEvent extends CreateEvent {
  id: number
}

export interface EventDetails {
  eventGroups: {
    id: number
    name: string
    currencyId: number | null
  }[]
  currencies: {
    id: number
    name: string
    rateToEur: number
  }[]
}

export interface CreateEventGroup {
  name: string
  ticketSystemId: number
  country?: string | null
  startDate?: string | null
  endDate?: string | null
  description?: string | null
  currencyId?: number | null
  timeDifUtc?: number | null
  url?: string | null
}

export interface EditEventGroup extends CreateEventGroup {
  id: number
}

export interface EventGroup {
  id: number
  name: string
  ticketSystem: {
    id: number
    name: string
  }
  country: string | null
  startDate: string | null
  endDate: string | null
  description: string | null
  currency: {
    id: number
    name: string
  } | null
  url: string | null
  timeDifUtc: number | null
}

export interface ArchiveEventResponse {
  msc: string
  type?: string
}

export interface ArchiveEventPayload {
  archive: boolean
  eventIds: number[]
}

export interface GetEventGroupPayload extends QueryTableState {
  showArchived: boolean
}

export interface EventGroupDetails {
  ticketSystem: {
    id: number
    name: string
  }[]
  currencies: {
    id: number
    name: string
  }[]
}

export enum GoalsModes {
  DISABLED = 0,
  MANUAL = 10,
  LOW = 20,
  HIGH = 30,
  ON_HOLD = 40,
}

export const GOALS_MODE_STATUS_TYPE = {
  '0': 'Disabled',
  '10': 'Manual',
  '20': 'Low(auto)',
  '30': 'High(auto)',
  '40': 'On Hold(auto)',
} as const

export type GoalsModeKeyType = keyof typeof GOALS_MODE_STATUS_TYPE

export const GOALS_MODE_OPTIONS_TO_SELECT = Object.entries(GOALS_MODE_STATUS_TYPE).map(
  ([value, label]) => ({
    value,
    label,
  })
)
export const GOAL_MODE_STYLE = {
  0: { text: 'Disabled', color: 'error' },
  10: { text: 'Manual', color: 'primary' },
  20: { text: 'Low(auto)', color: 'warning' },
  30: { text: 'High(auto)', color: 'info' },
  40: {text: 'On Hold(auto)', color: 'secondary'}
} as const

export interface EditEventGoals {
  settingGoals: {
    id: number
    priority: number
    settings: { [key: string]: number }
    goalsMode: GoalsModes | null
  }[]
}

export interface EventGroupTransform {
  value: string
  name: string
}

export interface EventPauseGoalsRequest {
  eventIds: number[]
  paused: boolean
}
