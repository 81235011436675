import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip/Chip'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  createAutocompleteOperator,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  ALL_NUMBER_OPERATORS,
  createNotEqualsAutocompleteOperator,
  ALL_STRING_OPERATORS,
  NOT_CONTAINS_STRING_OPERATOR,
  CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetSalesQuery } from 'services/bookings/api'
import {
  SENT_STATUS_OPTIONS_TO_SELECT,
  SENT_STATUS_TYPE,
  SentStatusType,
} from 'services/bookings/types'

import { SalesTableToolbar } from 'components/bookings/Sales/SalesTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

import { SALE_STATUS_STYLE } from '../ListingsTab'

export const SENT_STATUS_STYLE = {
  0: { text: 'Not Sent', color: 'error' },
  10: { text: 'Sent', color: 'primary' },
  20: { text: 'Deferred', color: 'warning' },
  30: { text: 'Partially Sent', color: 'info' },
} as const

export type SalesModalActions = 'SEND_TICKETS' | 'SEND_TICKETS_SINGLE' | ''

const SalesTable = () => {
  const { modal, setModal, singleRow, setSingleRow } = useActions<SalesModalActions>()

  const navigate = useNavigate()

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 0.5,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'sentStatusHp',
        headerName: 'Sent status',
        type: 'singleSelect',
        flex: 1.7,
        resizable: true,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row.sentStatus,
        valueFormatter: ({ value }) => {
          const methodKey = value as SentStatusType
          return SENT_STATUS_TYPE[methodKey] || ''
        },
        valueOptions: SENT_STATUS_OPTIONS_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (
          <Chip
            label={SENT_STATUS_STYLE?.[row?.sentStatus as SentStatusType]?.text}
            color={SALE_STATUS_STYLE?.[row?.sentStatus as SentStatusType]?.color}
          />
        ),
      },
      {
        field: 'active',
        headerName: 'Sale status',
        type: 'singleSelect',
        flex: 1.7,
        resizable: true,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => row.active,
        valueOptions: [
          { value: true, label: 'On Sale' },
          { value: false, label: 'Sold' },
        ],
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ row }) => (
          <Chip label={row.active ? 'On Sale' : 'Sold'} color={row.active ? 'warning' : 'info'} />
        ),
      },
      {
        field: 'listing.user',
        headerName: 'From account',
        type: 'String',
        flex: 2,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.accountDetails?.email,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/completed-sales/distinct', 'listing.user'),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.user'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'listing.eventCategory.event.eventGroup.name',
        headerName: 'Event group',
        type: 'String',
        flex: 2,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventCategory?.eventGroupName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.event_group.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.event_group.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'listing.eventCategory.event.name',
        headerName: 'Event Name',
        type: 'String',
        flex: 2,
        valueGetter: ({ row }) => row?.eventCategory?.eventName,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.event.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'numberOfTickets',
        headerName: 'Number of Tickets',
        type: 'Number',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row?.tickets?.length,
        filterable: false,
        sortable: false,
      },
      {
        field: 'listing.eventCategory.name',
        headerName: 'Category',
        type: 'String',
        flex: 1.7,
        resizable: true,
        valueGetter: ({ row }) => row?.eventCategory?.name,
        filterOperators: [
          createAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.name'
          ),
          createNotEqualsAutocompleteOperator(
            '/api/bookings/completed-sales/distinct',
            'listing.event_category.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'platformOrderId',
        headerName: 'Order ID',
        type: 'String',
        flex: 1.7,
        resizable: true,
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return !params.row.active
            ? [
                <GridActionsCellItem
                  key={1}
                  onClick={() => {
                    setModal('SEND_TICKETS_SINGLE')
                    setSingleRow(params.row)
                  }}
                  label="Send tickets"
                  showInMenu
                />,
                <GridActionsCellItem
                  key={2}
                  onClick={() => navigate(`/bookings/listings/manage/${params.row.listingId}`)}
                  label="Go to Listing"
                  showInMenu
                />,
              ]
            : [
                <GridActionsCellItem
                  key={3}
                  onClick={() => navigate(`/bookings/listings/manage/${params.row.listingId}`)}
                  label="Go to Listing"
                  showInMenu
                />,
              ]
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetSalesQuery({
    ...queryParams,
    completed: true,
    filters: [...queryParams.filters],
  })

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<SalesModalActions>
        rows={data?.data || []}
        columns={columns}
        columnVisibilityModel={{
          matchNumber: false,
          description: false,
        }}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={SalesTableToolbar}
        rowHeight={80}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}

export default SalesTable
