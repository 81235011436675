import { QueryTableState } from 'types/TableServerRequest'
import { TableServerResponse } from 'types/TableServerResponse'

import { baseApi } from 'api/api'

import { AutocompleteOption } from 'components/common/CustomSelectAutocomplete/CommonSelectAutocomplete/index'
import { SelectOption } from 'components/common/Select'

import {
  Account,
  Card,
  Event,
  GetAccountsPayload,
  GetListingsPayload,
  Listing,
  NewListing,
} from './types'

export const purchasingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<TableServerResponse<Account[]>, GetAccountsPayload>({
      query: (body) => ({
        url: '/api/purchasing/bots/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Accounts', id: 'LIST' }],
    }),
    getAccountInfo: build.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `/api/purchasing/bots/details?bot_id=${id}`,
        method: 'GET',
      }),
    }),
    takeBotsToWork: build.mutation<{ msg: string }, { botsIds: string[] }>({
      query: (body) => ({
        url: '/api/purchasing/bots/take-to-work',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),
    removeBotsFromWork: build.mutation<{ msg: string }, { botsIds: string[] }>({
      query: (body) => ({
        url: '/api/purchasing/bots/remove-from-work',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),
    registerApp: build.mutation<{ msg: string }, { botId: string; note: string }>({
      query: (body) => ({
        url: '/api/purchasing/bots/register-app',
        method: 'POST',
        data: body,
      }),
    }),
    getCards: build.query<SelectOption[], void>({
      query: () => ({
        url: '/api/bookings/cards',
        method: 'GET',
      }),
      transformResponse: (res: { data: Card[] }) =>
        res?.data.map((card) => ({ value: card.id, label: card.num })),
    }),
    assignCard: build.mutation<
      { msg: string },
      { botId: number; cardName: string; cardType: string }
    >({
      query: (body) => ({
        url: '/api/purchasing/bots/assign-card',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),
    editPassword: build.mutation<{ msg: string }, { botId: number; newPassword: string }>({
      query: (body) => ({
        url: '/api/purchasing/bots/update-password',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),
    getCardTypesAndAccountNames: build.query<{ cardTypes: string[]; cardNames: string[] }, void>({
      query: () => ({
        url: '/api/purchasing/bots/assign-card',
        method: 'GET',
      }),
      transformResponse: (res: { data: { cardTypes: string[]; cardNames: string[] } }) => res?.data,
    }),
    getGoals: build.query<TableServerResponse<Event[]>, QueryTableState>({
      query: (body) => ({
        url: '/api/purchasing/events/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Goals', id: 'LIST' }],
    }),
    getListings: build.query<TableServerResponse<Listing[]>, GetListingsPayload>({
      query: (body) => ({
        url: '/api/purchasing/listings/paging',
        method: 'POST',
        data: body,
      }),
      providesTags: () => [{ type: 'Listings', id: 'LIST' }],
    }),
    createListing: build.mutation<{ msg: string }, NewListing>({
      query: (body) => ({
        url: '/api/purchasing/listings',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: () => [{ type: 'Listings', id: 'LIST' }],
    }),
    updateListing: build.mutation<{ msg: string }, NewListing & { id: number }>({
      query: (data) => ({
        url: `/api/purchasing/listings`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Listings', id: 'LIST' }],
    }),
    getCardsToSelect: build.query<AutocompleteOption[], { botLogin: string }>({
      query: (botLogin) => ({
        url: `/api/purchasing/listings/cards-for-user`,
        method: 'GET',
        params: botLogin,
      }),
      transformResponse: (res: { data: Card[] }) =>
        res?.data.map((card) => ({ value: card.id?.toString(), name: card.num })),
    }),
    uploadConfirmationFile: build.mutation<{ resultFilename: string }, FormData>({
      query: (data) => ({
        url: `/api/bookings/upload-booking-confirmation`,
        method: 'POST',
        data,
      }),
    }),
    uploadOrderScreenshotFile: build.mutation<{ resultFilename: string }, FormData>({
      query: (data) => ({
        url: `/api/purchasing/listings/order-screenshot`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const {
  useGetAccountsQuery,
  useTakeBotsToWorkMutation,
  useRemoveBotsFromWorkMutation,
  useRegisterAppMutation,
  useGetCardTypesAndAccountNamesQuery,
  useAssignCardMutation,
  useGetGoalsQuery,
  useGetListingsQuery,
  useEditPasswordMutation,
  useCreateListingMutation,
  useUpdateListingMutation,
  useGetCardsToSelectQuery,
  useUploadConfirmationFileMutation,
  useUploadOrderScreenshotFileMutation,
} = purchasingApi