import React, { FC, useState, MouseEvent } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import { Button, Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import TableToolbar from 'components/table/TableToolbar'

import { LogsTabActions } from 'pages/dashboard/LogsTab'

import { ViewLogsModal } from '../ViewLogsModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: LogsTabActions
  setModal: React.Dispatch<React.SetStateAction<LogsTabActions>>
  singleRow: any
}

export const LogsTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    if (selectedRows.length) {
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              {selectedRows.length === 1 ? (
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-toolbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={!!anchorEl}
                  onClose={handleCloseMenu}
                >
                  {selectedRows.length === 1 ? (
                    <MenuItem
                      onClick={() => {
                        setModal('VIEW_LOGS')
                        handleCloseMenu()
                      }}
                    >
                      <Typography textAlign="center">View Logs</Typography>
                    </MenuItem>
                  ) : null}
                </Menu>
              ) : null}
            </Box>
          </Box>
        }
      />

      {(modal === 'VIEW_LOGS' || modal === 'VIEW_LOGS_SINGLE') && (
        <ViewLogsModal
          onClose={() => setModal('')}
          log={modal === 'VIEW_LOGS' ? selectedRows[0] : singleRow}
        />
      )}
    </>
  )
}
