import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import { ALL_DATE_OPERATORS, ALL_STRING_OPERATORS } from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetBotsLogsQuery } from 'services/dashboard/api'

import { LogsTableToolbar } from 'components/dashboard/logs/LogsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

export type LogsTabActions = 'VIEW_LOGS' | 'VIEW_LOGS_SINGLE' | ''

export const LogsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'account.email',
        headerName: 'Bot Login',
        type: 'String',
        headerAlign: 'left',
        align: 'left',
        flex: 4,
        hideable: false,
        valueGetter: (data) => {
          return data?.row?.botDetails?.login
        },
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'botStation.ip',
        headerName: 'Station IP',
        type: 'String',
        flex: 1.5,

        valueGetter: (data) => {
          return data?.row?.botDetails?.stationIp
        },
        renderCell: ({ value }) => {
          return (
            <Box display="flex" alignItems="center">
              {value}
            </Box>
          )
        },
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'ticketSystem.name',
        headerName: 'Ticket System Name',
        type: 'String',
        flex: 3.5,
        valueGetter: (data) => {
          return data?.row?.botDetails?.ticketSystemName
        },
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'logDetails.logText',
        headerName: 'Last log',
        type: 'String',
        flex: 3,
        valueGetter: (data) => {
          return data?.row?.logDetails?.logText
        },
        filterOperators: ALL_STRING_OPERATORS,
        sortable: false,
        filterable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 2.7,
        type: 'Datetime',
        valueGetter: (data) => {
          return data?.row?.logDetails?.createdAt
        },
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('VIEW_LOGS_SINGLE')
                setSingleRow(params.row)
              }}
              label="View Logs"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )

  const { queryParams, paginationDataGridProps } = usePagination({
    columns,
    defaultSortModel: { field: 'created_at', sort: 'asc' },
  })

  const { modal, setModal, singleRow, setSingleRow } = useActions<LogsTabActions>()
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetBotsLogsQuery(queryParams)
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<LogsTabActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        onRowSelectionModelChange={handleRowSelection}
        {...paginationDataGridProps}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={LogsTableToolbar}
        singleRow={singleRow}
        modal={modal}
        setModal={setModal}
        checkboxSelection
      />
    </Box>
  )
}
