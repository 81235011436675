import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import { GridColDef } from '@mui/x-data-grid'

import {
  ALL_DATE_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  ALL_NUMBER_OPERATORS,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useExportReport } from 'hooks/useExportReport'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetLogsReportMutation, useGetUsersLogsQuery } from 'services/users/api'
import {
  LOGS_METHOD_OPTIONS_TO_SELECT,
  LOGS_METHOD_TYPE,
  LOGS_SECTION_TYPE,
  LOGS_SECTION_TYPE_TO_SELECT,
  LogsMethodType,
  LogsSectionType,
} from 'services/users/types'

import CustomDataGrid from 'components/table/DataGrid'
import LogsTableToolbar from 'components/users/LogsTableToolbar'
import ConfirmExportModal from "components/common/ConfirmExportModal/index";
import Typography from "@mui/material/Typography";

const Logs = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        hideable: false,
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'user.login',
        headerName: 'User',
        type: 'string',
        flex: 4,
        filterOperators: [
          createAutocompleteOperator('/api/users/logs/distinct', 'user.login'),
          createNotEqualsAutocompleteOperator('/api/users/logs/distinct', 'user.login'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        valueGetter: (data) => {
          return data?.row?.user?.login
        },
        renderCell: ({ value }) => {
          return (
            <Box display="flex" alignItems="center">
              {value}
            </Box>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'Datetime',
        flex: 2.5,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        flex: 3,
        filterOperators: [
          createAutocompleteOperator('/api/users/logs/distinct', 'description'),
          createNotEqualsAutocompleteOperator('/api/users/logs/distinct', 'description'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'apiMethod',
        headerName: 'Method',
        flex: 2.7,
        type: 'singleSelect',
        valueFormatter: ({ value }) => {
          const methodKey = value as LogsMethodType
          return LOGS_METHOD_TYPE[methodKey] || ''
        },
        valueOptions: LOGS_METHOD_OPTIONS_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'apiSection',
        headerName: 'Section',
        flex: 2,
        type: 'singleSelect',
        valueFormatter: ({ value }) => {
          const sectionKey = value as LogsSectionType
          return LOGS_SECTION_TYPE[sectionKey] || ''
        },
        valueOptions: LOGS_SECTION_TYPE_TO_SELECT,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'apiUri',
        headerName: 'URI',
        flex: 3,
        type: 'string',
        filterOperators: [
          createAutocompleteOperator('/api/users/logs/distinct', 'apiUri'),
          createNotEqualsAutocompleteOperator('/api/users/logs/distinct', 'apiUri'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
    ],
    []
  )

  const [getReport, { isLoading: isDownload }] = useGetLogsReportMutation()
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { isModal,setIsModal,handleExport, handleExportButtonClick } = useExportReport({
    queryParams,
    columns,
    getReport,
    reportName: 'Users logs',
    replacement: { user: 'user.id' },
  })
  const { data, isFetching } = useGetUsersLogsQuery(queryParams)
  return (
      <Box sx={{ height: 'calc(100vh - 122px)', width: '100%',pt:1 }}>
      <CustomDataGrid
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching || isDownload}
        onRowSelectionModelChange={handleRowSelection}
        {...paginationDataGridProps}
        selectedRows={selectedRows}
        onExportButtonClick={handleExportButtonClick}
        toolbar={LogsTableToolbar}
      />
      {isModal && (
          <ConfirmExportModal
              open={true}
              onConfirm={handleExport}
              loading={isDownload}
              onClose={() => {
                setIsModal(false)
              }}
          >
            <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
            >
              Do you want to export only the current page or all the data?
            </Typography>
          </ConfirmExportModal>
      )}
    </Box>
  )
}

export default Logs
