import { FC, ReactNode } from 'react'
import { isMobileOnly } from 'react-device-detect'

import Dialog from '@mui/material/Dialog'
import { DialogProps } from '@mui/material/Dialog/Dialog'

interface Props {
  children: ReactNode
}

const Modal: FC<Props & DialogProps> = ({ children, open, onClose,sx, ...rest }) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={{ color: 'text.primary',...sx }}
    PaperProps={
      !isMobileOnly
        ? {
            style: { borderRadius: '8px', position: 'absolute', top: '50px' },
          }
        : undefined
    }
    maxWidth={'md'}
    {...rest}
  >
    {children}
  </Dialog>
)

export default Modal
