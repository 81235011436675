import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as NoData } from 'assets/svg/noData.svg'

const NoDataInDataGridTable = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <Box>
        <NoData />
        <Typography color="text.secondary" fontSize="14px" textAlign="center">
          No data
        </Typography>
      </Box>
    </Box>
  )
}

export default NoDataInDataGridTable
