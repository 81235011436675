import React, { useMemo } from 'react'

import { Chip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_STRING_OPERATORS,
  SELECT_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { useExportReport } from 'hooks/useExportReport'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetBookingsCardsQuery, useGetCardsReportMutation } from 'services/bookings/api'
import { CARD_TYPES_OPTIONS } from 'services/bookings/types'

import BookingCardsTableToolbar from 'components/bookings/Cards/BookingsCardsTableToolbar'
import ConfirmExportModal from 'components/common/ConfirmExportModal/index'
import CustomDataGrid from 'components/table/DataGrid'

const ACTIVE_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]
export type CardsTabActions = 'ADD' | 'EDIT' | 'DELETE' | 'EDIT_SINGLE' | 'DELETE_SINGLE' | ''
const CardsTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'Card ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'num',
        headerName: 'Card Number',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/cards/distinct', 'num'),
          createNotEqualsAutocompleteOperator('/api/bookings/cards/distinct', 'num'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'name',
        headerName: 'Holder Name',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/bookings/cards/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/bookings/cards/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'month',
        headerName: 'Month',
        type: 'String',
        flex: 1,

        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'year',
        headerName: 'Year',
        type: 'String',
        flex: 1,

        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'cardType',
        headerName: 'Card Type',
        type: 'singleSelect',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueOptions: CARD_TYPES_OPTIONS,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ value }) => (
          <Box display="flex" alignItems="center">
            {CARD_TYPES_OPTIONS?.[value]?.name ?? null}
          </Box>
        ),
      },
      {
        field: 'active',
        headerName: 'Active',
        type: 'singleSelect',
        flex: 1,
        hideable: false,
        headerAlign: 'center',
        align: 'center',
        valueOptions: ACTIVE_OPTIONS,
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
        renderCell: ({ value }) => (
          <Box display="flex" alignItems="center">
            {value ? (
              <Chip sx={{ minWidth: '60px' }} label={'Yes'} color="success" />
            ) : (
              <Chip sx={{ minWidth: '60px' }} label={'No'} color="error" />
            )}
          </Box>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Card"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('DELETE_SINGLE')
                setSingleRow(params.row)
              }}
              label="Deactivate Card"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const [getReport, { isLoading: isDownload }] = useGetCardsReportMutation()
  const { modal, setModal, singleRow, setSingleRow } = useActions<CardsTabActions>()
  const { queryParams, paginationDataGridProps } = usePagination({
    columns,
  })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { isModal, setIsModal, handleExport, handleExportButtonClick } = useExportReport({
    queryParams,
    columns,
    getReport,
    reportName: 'Users logs',
    replacement: { user: 'user.id' },
  })
  const { data, isFetching: isLoading } = useGetBookingsCardsQuery(
    {
      ...queryParams,
      showArchived: false,
    },
    { refetchOnMountOrArgChange: true }
  )
  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<CardsTabActions>
        rows={data?.data || []}
        columns={columns}
        {...paginationDataGridProps}
        rowCount={data?.page?.totalElements || 0}
        loading={isLoading || isDownload}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((row) => selectedRows.includes(row.id)) || []}
        toolbar={BookingCardsTableToolbar}
        onExportButtonClick={handleExportButtonClick}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
      {isModal && (
        <ConfirmExportModal
          open={true}
          onConfirm={handleExport}
          loading={isDownload}
          onClose={() => {
            setIsModal(false)
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Do you want to export only the current page or all the data?
          </Typography>
        </ConfirmExportModal>
      )}
    </Box>
  )
}
export default CardsTab
