import * as yup from 'yup'

import { requiredMessage } from 'validation/index'

export const EDIT_SCRAP_TASKS = yup.object().shape({
  enable: yup.boolean().required(requiredMessage),
  subjects: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  senders: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  recipients: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  excludedSenders: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  excludedRecipients: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
  excludedSubjects: yup
    .array()
    .of(yup.string().trim())
    .required(requiredMessage)
    .transform((v) => (v.length === 1 && v[0] === '' ? [] : v))
    .transform((v) => v.filter((s?: string) => s !== '')),
})
