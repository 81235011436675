import React, { FC, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ToolbarPropsOverrides } from '@mui/x-data-grid'
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar'

import { showNotification } from 'utils/showNotification'

import { useArchiveListingMutation } from 'services/bookings/api'
import { BookingsListing } from 'services/bookings/types'

import ConfirmModal from 'components/common/ConfirmModal'
import TableToolbar from 'components/table/TableToolbar'

import { BookingListingsTabActions } from 'pages/bookings/ListingsTab/index'

import { CreateListingModal } from './CreateListingModal'
import CreateTagModal from './tags/CreateTagModal'

interface Props extends Partial<GridToolbarProps & ToolbarPropsOverrides> {
  modal: BookingListingsTabActions
  setModal: React.Dispatch<React.SetStateAction<BookingListingsTabActions>>
  singleRow: any
}

const BookingListingsTableToolbar: FC<Props> = ({
  selectedRows,
  searchValue,
  onExportButtonClick,
  onSearchChange,
  modal,
  setModal,
  singleRow,
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [archiveListings, { isLoading: isArchiveLoading }] = useArchiveListingMutation()
  const listingsIds = selectedRows.map((item: BookingsListing) => item.id)
  const confirmMessage =
    modal === 'CONFIRM'
      ? `Are you sure you want to archive listing${selectedRows.length === 1 ? '' : 's'} with ID${
          selectedRows.length === 1 ? '' : 's'
        } ${listingsIds.join(', ')}?`
      : `Are you sure you want to ${
          singleRow?.saleStatus === 40 ? 'move from' : ''
        } archive listing ${singleRow?.id}?`

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const onCreateListingClick = () => {
    setAnchorEl(null)
    setModal('ADD')
  }
  const onCloseModal = () => {
    setModal('')
  }

  const confirmArchive = async () => {
    if (modal === 'CONFIRM')
      await archiveListings({
        archive: true,
        listingIds: listingsIds,
      })
        .unwrap()
        .then(() => {
          showNotification(`Listings successfully archived`, 'success')
          setModal('')
        })
        .catch((error) => {
          showNotification(`Error. Try again later`, 'error')
          console.error(error)
        })
    else
      await archiveListings({
        archive: singleRow?.saleStatus === 40 ? false : true,
        listingIds: [singleRow?.id],
      })
        .unwrap()
        .then(() => {
          showNotification(`Listing successfully archived`, 'success')
          setModal('')
        })
        .catch((error) => {
          showNotification(`Error. Try again later`, 'error')
          console.error(error)
        })
  }

  return (
    <>
      <TableToolbar
        searchValue={searchValue}
        onExportButtonClick={onExportButtonClick}
        onChange={onSearchChange}
        rightContent={
          <Box>
            <Button size="small" startIcon={<MenuIcon />} onClick={handleOpenMenu}>
              Actions
            </Button>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-toolbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={onCreateListingClick}>
                  <Typography textAlign="center">Create Listing</Typography>
                </MenuItem>
                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('EDIT')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Edit Listing</Typography>
                  </MenuItem>
                ) : null}

                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      setModal('CLONE')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Clone Listing</Typography>
                  </MenuItem>
                ) : null}

                {selectedRows.length === 1 ? (
                  <MenuItem
                    onClick={() => {
                      navigate(`manage/${selectedRows[0].id}`)
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">Manage Listing</Typography>
                  </MenuItem>
                ) : null}
                {selectedRows.length ? (
                  <MenuItem
                    onClick={() => {
                      setModal('CONFIRM')
                      handleCloseMenu()
                    }}
                  >
                    <Typography textAlign="center">
                      Archive Listing{selectedRows.length > 1 ? 's' : ''}
                    </Typography>
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    handleCloseMenu()
                    setModal('TAG')
                  }}
                >
                  <Typography textAlign="center">Create Tag</Typography>
                </MenuItem>
              </Menu>
            </Box>
            {(modal === 'CONFIRM' || modal === 'CONFIRM_SINGLE') && (
              <ConfirmModal
                open={true}
                onConfirm={confirmArchive}
                loading={isArchiveLoading}
                onClose={() => setModal('')}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    textAlign: 'center',
                  }}
                >
                  {confirmMessage}
                </Typography>
              </ConfirmModal>
            )}
          </Box>
        }
      />
      {modal === 'ADD' && <CreateListingModal onClose={onCloseModal} />}
      {(modal === 'EDIT' || modal === 'EDIT_SINGLE') && (
        <CreateListingModal
          onClose={onCloseModal}
          listing={modal === 'EDIT' ? selectedRows[0] : singleRow}
        />
      )}
      {(modal === 'CLONE' || modal === 'CLONE_SINGLE') && (
        <CreateListingModal
          onClose={onCloseModal}
          listing={modal === 'CLONE' ? selectedRows[0] : singleRow}
          clone
        />
      )}
      {modal === 'TAG' && <CreateTagModal onClose={onCloseModal} />}
    </>
  )
}

export default BookingListingsTableToolbar
