import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm } from 'react-hook-form'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box/Box'

import copyToClipboard from 'utils/clipboardUtils'
import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import {
  useGetCountriesQuery,
  useGetMailDomainsQuery,
  useCreateBotsAccountMutation,
} from 'services/bots/api'

import { CREATE_ACCOUNT_SCHEMA } from 'components/bots/accounts/AddAccountModal/validation'
import { AppModal } from 'components/common/AppModal/index'
import Button from 'components/common/Button/index'
import { ControlledSelectAutocomplete } from 'components/common/CustomSelectAutocomplete/ControlledSelectAutocomplete/index'
import TextInput from 'components/common/TextInput/index'

interface Props {
  onCLose: () => void
}

interface FormValues {
  email: string
  password: string
  recoveryEmail: string
  domainId: {
    value: string
    name: string
  }
  countryId: {
    value: string
    name: string
  }
}

export const AddAccountModal: FC<Props> = ({ onCLose }) => {
  const { data: countries } = useGetCountriesQuery()
  const { data: mailDomains } = useGetMailDomainsQuery()
  const [addAccount, { isLoading }] = useCreateBotsAccountMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(CREATE_ACCOUNT_SCHEMA),
    defaultValues: {
      email: '',
      password: '',
      recoveryEmail: '',
      domainId: {
        value: '',
        name: '',
      },
      countryId: {
        value: '',
        name: '',
      },
    },
  })
  const onSubmit = async (newAccount: FormValues) => {
    await addAccount({
      ...newAccount,
      domainId: +newAccount.domainId.value,
      domainName: mailDomains?.find((v) => v.value === newAccount.domainId.value)?.name,
      countryId: +newAccount.countryId.value,
    })
      .unwrap()
      .then(() => {
        showNotification('Account successfully added', 'success')
        onCLose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }
  const generatePassword = () => {
    setValue('password', passwordGenerator())
    if (errors.password?.message) clearErrors('password')
  }
  const wPassword = watch('password')
  return (
    <AppModal title="Add Account" onClose={onCLose}>
      <AppModal.Wrapper
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: '700px', maxHeight: 'calc(100dvh - 250px)', height: 'fit-content' }}
      >
        <AppModal.ContentBox
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        >
          <TextInput control={control} name="email" label="Name" errors={errors} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <TextInput
              control={control}
              name="recoveryEmail"
              label="Recovery/Forwarding Email"
              errors={errors}
              sx={{ display: 'flex', flex: 2 }}
            />
            <ControlledSelectAutocomplete
              control={control}
              name="domainId"
              label="Domain"
              errors={errors}
              sx={{ display: 'flex', flex: 1 }}
              selectOptions={mailDomains}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <TextInput name="password" label="Password" errors={errors} control={control} />
            <Box sx={{ display: 'flex', mb: 2 }}>
              <IconButton
                sx={{ alignSelf: 'center' }}
                aria-label="copy"
                disabled={!wPassword}
                color="primary"
                size="small"
                onClick={async () => {
                  await copyToClipboard(wPassword, 'Password was copied to clipboard')
                }}
              >
                <ContentCopyIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
              <Button variant="text" onClick={generatePassword}>
                <AutorenewIcon />
              </Button>
            </Box>
          </Box>
          <ControlledSelectAutocomplete
            control={control}
            name="countryId"
            label="Country"
            errors={errors}
            sx={{ display: 'flex', flex: 1 }}
            selectOptions={countries}
          />
        </AppModal.ContentBox>
        <AppModal.ButtonsBox>
          <Button variant="outlined" color="warning" onClick={onCLose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading} fullWidth={isMobileOnly}>
            Add
          </Button>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
