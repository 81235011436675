import React, { useMemo } from 'react'

import ViewDayIcon from '@mui/icons-material/ViewDay'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_STRING_OPERATORS,
  CONTAINS_STRING_OPERATOR,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  NOT_CONTAINS_STRING_OPERATOR,
  NOT_EQUAL_SELECT_OPERATOR,
  SELECT_OPERATOR,
} from 'constants/table-filters'

import { useActions } from 'hooks/useActions'
import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetBotsStationsQuery } from 'services/dashboard/api'
import { BROWSER_MODE, BROWSER_MODE_OPTIONS, BrowserModeType } from 'services/dashboard/types'

import { BotStationToolbar } from 'components/dashboard/bot-station/BotStationToolbar/index'
import CustomDataGrid from 'components/table/DataGrid/index'

const STATUS_OPTIONS = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Inactive',
  },
]

export type BotStationTabMActions = 'EDIT' | 'PAUSE' | 'EDIT_SINGLE' | 'PAUSE_SINGLE' | ''
export const BotStationTab = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'ip',
        headerName: 'IP address',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_STRING_OPERATORS,
      },
      {
        field: 'isActiveHp',
        headerName: 'Status',
        type: 'singleSelect',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => (row.isActive ? 'Active' : 'Inactive'),

        valueOptions: STATUS_OPTIONS,
        //@ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'normalBotsCount',
        headerName: 'Bots Count',
        type: 'Number',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'eventGroup.name',
        headerName: 'Event Group',
        type: 'String',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row.eventGroupDetails?.name,
        filterOperators: [
          createAutocompleteOperator('/api/events/event-groups/distinct', 'name'),
          createNotEqualsAutocompleteOperator('/api/events/event-groups/distinct', 'name'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'proxyMasks',
        headerName: 'Proxy masks',
        type: 'Number',
        flex: 2,
        headerAlign: 'left',
        align: 'center',
        filterable: false,
        sortable: false,
        filterOperators: ALL_NUMBER_OPERATORS,
        renderCell: ({ row }) =>
          row.proxyMasks.length ? (
            <Tooltip
              placement="right"
              title={
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {row.proxyMasks.map((item: any, ind: number) => (
                    <li key={ind} style={{ marginBottom: '4px' }}>
                      {item}
                    </li>
                  ))}
                </ul>
              }
            >
              <Box>
                <ViewDayIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
              </Box>
            </Tooltip>
          ) : (
            'No proxy mask'
          ),
      },
      {
        field: 'logicFunctionNumber',
        headerName: 'Logic Number',
        type: 'Number',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'browserMode',
        headerName: 'Browser',
        type: 'singleSelect',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueOptions: BROWSER_MODE_OPTIONS,
        renderCell: ({ row }) => BROWSER_MODE[row.browserMode as BrowserModeType],
        // @ts-ignore
        filterOperators: [SELECT_OPERATOR, NOT_EQUAL_SELECT_OPERATOR],
      },
      {
        field: 'distinctiveNumber',
        headerName: 'Distinctive Number',
        type: 'Number',
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          return [
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('EDIT_SINGLE')
                setSingleRow(params.row)
              }}
              label="Edit Bot Station"
              showInMenu
            />,
            <GridActionsCellItem
              key={params.id}
              onClick={() => {
                setModal('PAUSE_SINGLE')
                setSingleRow([params.row.id])
              }}
              label="Pause Bot Station"
              showInMenu
            />,
          ]
        },
      },
    ],
    []
  )
  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetBotsStationsQuery(queryParams)
  const { modal, setModal, singleRow, setSingleRow } = useActions<BotStationTabMActions>()

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid<BotStationTabMActions>
        rows={data?.data || []}
        columns={columns}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={data?.data.filter((v) => selectedRows.includes(v.id)) || []}
        {...paginationDataGridProps}
        toolbar={BotStationToolbar}
        checkboxSelection
        modal={modal}
        setModal={setModal}
        singleRow={singleRow}
      />
    </Box>
  )
}
