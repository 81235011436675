import React, { FC } from 'react'
import {Outlet, useParams} from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs from 'components/common/NavigationTabs'

const tabs = [
  { id: 'listings', label: 'LISTINGS' },
  { id: 'sales', label: 'SALES' },
  { id: 'cards', label: 'CARDS' },
  { id: 'Sale-platforms', label: 'SALE PLATFORMS' },
]

const Bookings: FC = () => {
    const {id} = useParams()
  return (
    <Box sx={{ minHeight: 'calc(100vh - 80px)' }}>
        {!id && <NavigationTabs tabs={tabs} basePath="/bookings"/>}
      <Outlet />
    </Box>
  )
}
export default Bookings
