import React from 'react'

import Box from '@mui/material/Box/Box'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { useGetMailFileMutation, useGetMailMessageQuery } from 'services/dashboard/api'
import { Mail } from 'services/dashboard/types'

import { AppModal } from 'components/common/AppModal/index'
import { FilePreviewLink } from 'components/mails/mails/ShowMessageModal/FilePreviewLink/index'

interface Props {
  onClose: () => void
  mail: Mail
}

export const ShowMessageModal = ({ onClose, mail }: Props) => {
  const { data, isLoading } = useGetMailMessageQuery({ catcherId: mail.catcherId, mailId: mail.id })

  const [getFile, { isLoading: isFileLoading }] = useGetMailFileMutation()
  return (
    <AppModal
      title={mail.subject}
      onClose={onClose}
      maxWidth={700}
      loading={isLoading || isFileLoading}
    >
      <AppModal.Wrapper sx={{ width: '100%' }}>
        <AppModal.ContentBox sx={{ height: 'fit-content', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Sender:</b>
              <br /> {mail.sender}
            </Box>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Recipient:</b>
              <br /> {mail.recipient}
            </Box>
            <Box sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
              <b>Received At:</b>
              <br /> {formatToLocaleTimezone(mail.receivedAt)}
            </Box>
          </Box>

          <div dangerouslySetInnerHTML={{ __html: data?.htmlContent || '' }} />
        </AppModal.ContentBox>

        <AppModal.ButtonsBox>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            {data?.files?.map(
              (
                file //@ts-ignore
              ) => <FilePreviewLink key={file} fileName={file} downloadFile={getFile} />
            )}
          </Box>
        </AppModal.ButtonsBox>
      </AppModal.Wrapper>
    </AppModal>
  )
}
