import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useFieldArray, useForm } from 'react-hook-form'

import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, Chip } from '@mui/material'

import { passwordGenerator } from 'utils/passwordGenerator'
import { showNotification } from 'utils/showNotification'

import { useSendTicketsMutation, useUploadTicketMutation } from 'services/bookings/api'
import { Sale, SendType } from 'services/bookings/types'

import { AppModal } from 'components/common/AppModal'
import Button from 'components/common/Button'
import CustomCheckbox from 'components/common/Checkbox'
import { CustomDropzone } from 'components/common/CustomDropzone'
import TextInput from 'components/common/TextInput'

import { SaveAndSendModal } from './SaveAndSendModal'
import { SEND_TICKETS_SCHEMA } from './validation'

export const SENT_STATUS_MAP = {
  0: { text: 'Not Sent', color: 'error' },
  10: { text: 'Partially Sent', color: 'secondary' },
  20: { text: 'Deferred', color: 'success' },
  30: { text: 'Sent', color: 'primary' },
} as const

interface Props {
  onClose: () => void
  sale: Sale
}

interface FormValues {
  tickets: {
    id: number
    linkedFile?: any
    holderFirstName?: string | null
    holderLastName?: string | null
    holderEmail: string
    send: boolean
  }[]
  accountDetails: {
    email: string
    password: string
  }
  platformOrderId?: string | null
  send: boolean
  message?: string | null
}




export const SendTicketsModal = ({ onClose, sale }: Props) => {
  const [sendTickets, { isLoading }] = useSendTicketsMutation()
  const [uploadTicket, { isLoading: isFilesLoading }] = useUploadTicketMutation()

  const [isSaveAndSendModalOpen, setSaveAndSendModalOpen] = useState(false)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(SEND_TICKETS_SCHEMA),
    defaultValues: {
      accountDetails: {
        email: sale?.accountDetails?.email ?? '',
        password: sale?.accountDetails?.password ?? '',
      },
      platformOrderId: sale?.platformOrderId ?? '',
      send: sale?.sentStatus !== 10,
      tickets:
        sale?.tickets?.map((ticket) => ({
          id: ticket?.id,
          linkedFile: ticket?.linkedFile,
          holderFirstName: ticket?.holderFirstName ?? '',
          holderLastName: ticket?.holderLastName ?? '',
          holderEmail: ticket?.holderEmail ?? '',
          send: ticket.sentStatusType !== 10,
        })) || [],
      message: '',
    },
  })

  const generatePassword = () => {
    setValue('accountDetails.password', passwordGenerator())
    if (errors?.accountDetails?.password?.message) clearErrors('accountDetails.password')
  }

  const { fields: tickets } = useFieldArray({ control, name: 'tickets' })

  const onSubmit = (send: SendType) => async (formValues: FormValues) => {
    const filesMap = formValues.tickets
      .map((t) => t.linkedFile || [])
      .reduce(
        (prev, file, fileInd) => ({
          ...prev,
          [`${fileInd}`]: file?.length > 0 && file[0] instanceof File ? file[0] : null, //if file[0] and instanceof file === 'File'
        }),
        {}
      )

    const requests = []
    for (const file in filesMap) {
      if (filesMap[file] !== null) {
        const formData = new FormData()
        formData.append('tickets', filesMap[file])
        requests.push(uploadTicket(formData))
      }
    }
    const fileRequests = await Promise.all(requests)

    let requestsInd = 0
    for (const fileInd in filesMap) {
      if (filesMap[fileInd] !== null) {
        formValues.tickets[+fileInd].linkedFile = (
          fileRequests[requestsInd] as {
            data: {
              resultFilename: string
            }
          }
        )?.data?.resultFilename
        requestsInd += 1
      }
    }

    await sendTickets({
      ...formValues,
      id: sale.id,
      send: send,
      tickets: formValues.tickets.map((ticket) => ({
        ...ticket,
        linkedFile: ticket.linkedFile || null,
      })),
    })
      .unwrap()
      .then(() => {
        showNotification(
          `Tickets successfully ${send ? 'saved and sent' : 'saved'}`,
          'success'
        )
        onClose()
      })
      .catch(() => showNotification('Error. Try again later', 'error'))
  }

  return (
    <>
      <AppModal
        onClose={onClose}
        title="Send tickets"
        maxWidth={800}
        loading={isLoading || isFilesLoading}
      >
        <AppModal.Wrapper
          component="form"
          noValidate
          sx={{ width: '800px', maxHeight: '80vh', height: 'fit-content' }}
          id="sendTicketsForm"
        >
          <AppModal.ContentBox sx={{ width: '800px' }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextInput
                label="Email"
                control={control}
                name="accountDetails.email"
                errors={errors.accountDetails?.email?.message}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <TextInput
                  name="accountDetails.password"
                  label="Password"
                  errors={errors.accountDetails?.password?.message}
                  control={control}
                />
                <Button variant="text" onClick={generatePassword} sx={{ mx: 1, mt: '4px' }}>
                  <AutorenewIcon />
                </Button>
              </Box>
            </Box>
            <TextInput control={control} name="platformOrderId" label="Order ID" errors={errors} />
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              {tickets.map((ticket, ind) => (
                <Box
                  key={ticket.id}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: 2,
                    borderBottom: '1px solid rgba(0,0,0,0.3)',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <CustomCheckbox
                        control={control}
                        name={`tickets.${ind}.send`}
                        label={`Section: ${sale.tickets[ind].section}, row: ${sale.tickets[ind].row}, seat: ${sale.tickets[ind].seat}`}
                      />
                      <Chip
                        label={
                          (sale?.tickets?.[ind]?.sentStatusType ||
                            sale?.tickets?.[ind]?.sentStatusType === 0) &&
                          SENT_STATUS_MAP[sale?.tickets?.[ind]?.sentStatusType].text
                        }
                        color={
                          ((sale?.tickets?.[ind]?.sentStatusType ||
                            sale?.tickets?.[ind]?.sentStatusType === 0) &&
                            SENT_STATUS_MAP[sale?.tickets?.[ind]?.sentStatusType].color) ||
                          'info'
                        }
                      />
                    </Box>
                    <CustomDropzone
                      setValue={setValue}
                      control={control}
                      name={`tickets.${ind}.linkedFile`}
                      noMediaTitle={'Attach file'}
                      maxSize={2 * 2 ** 20}
                      formatsAsLabel=".pdf"
                      acceptedFormats={{ 'application/pdf': ['.pdf'] }}
                      viewType="button"
                      error={errors.tickets?.[ind]?.linkedFile?.message as string}
                      isPdf={true}
                    />
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                    <TextInput
                      control={control}
                      name={`tickets.${ind}.holderEmail`}
                      label="Holder Email"
                      errors={errors?.tickets?.[ind]?.holderEmail?.message}
                    />
                    <TextInput
                      control={control}
                      name={`tickets.${ind}.holderFirstName`}
                      label="Holder First Name"
                      errors={errors?.tickets?.[ind]?.holderFirstName?.message}
                    />
                    <TextInput
                      control={control}
                      name={`tickets.${ind}.holderLastName`}
                      label="Holder Last Name"
                      errors={errors?.tickets?.[ind]?.holderLastName?.message}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </AppModal.ContentBox>
          <AppModal.ButtonsBox>
            <Button variant="outlined" color="warning" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              fullWidth={isMobileOnly}
              onClick={handleSubmit(onSubmit(SendType.DONT_SEND))}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="contained"
              disabled={isLoading}
              fullWidth={isMobileOnly}
              onClick={() => setSaveAndSendModalOpen(true)}
            >
              Save & Send
            </Button>
          </AppModal.ButtonsBox>
          {isSaveAndSendModalOpen && (
            <SaveAndSendModal
              onClose={() => setSaveAndSendModalOpen(false)}
              isLoading={isLoading}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          )}
        </AppModal.Wrapper>
      </AppModal>
    </>
  )
}
