import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import NavigationTabs from 'components/common/NavigationTabs'

const tabs = [
  { id: 'events', label: 'EVENTS' },
  { id: 'event-groups', label: 'EVENT GROUPS' },
]

const Events: FC = () => {
  return (
    <Box sx={{ height: 'calc(100vh - 80px)' }}>
      <NavigationTabs tabs={tabs} basePath="/events" />
      <Outlet />
    </Box>
  )
}
export default Events
