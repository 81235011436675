import { useMemo } from 'react'

import FlagIcon from '@mui/icons-material/Flag'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import { GridColDef } from '@mui/x-data-grid'

import {
  ALL_NUMBER_OPERATORS,
  ALL_DATE_OPERATORS,
  createAutocompleteOperator,
  createNotEqualsAutocompleteOperator,
  CONTAINS_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
} from 'constants/table-filters'

import { formatToLocaleTimezone } from 'utils/formatToLocalTimezone'

import { usePagination } from 'hooks/usePagination'
import { useSelectedRows } from 'hooks/useSelectedRows'

import { useGetGoalsQuery } from 'services/purchasing/api'
import { EventGoal } from 'services/purchasing/types'

import GoalsTableToolbar from 'components/purchasing/goals/GoalsTableToolbar'
import CustomDataGrid from 'components/table/DataGrid'

const Goals = () => {
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'eventGroup.name',
        headerName: 'Event group',
        type: 'String',
        flex: 2,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        valueGetter: ({ row }) => row?.eventGroup?.name,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/events/distinct', 'event_group.name'),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/events/distinct',
            'event_group.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
      {
        field: 'name',
        headerName: 'Event Name',
        type: 'String',
        flex: 2,
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/events/distinct', 'event_group.events.name'),
          createNotEqualsAutocompleteOperator(
            '/api/purchasing/events/distinct',
            'event_group.events.name'
          ),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center">
            {row.name}
            <OpenInNewIcon
              fontSize="small"
              sx={{ ml: 1, cursor: 'pointer' }}
              onClick={() => window.open(row.eventGroup.url, '_blank')}
            />
          </Box>
        ),
      },
      {
        field: 'date',
        headerName: 'Event Date',
        type: 'Datetime',
        flex: 1.5,
        valueFormatter: ({ value }) => formatToLocaleTimezone(value),
        //@ts-ignore
        filterOperators: ALL_DATE_OPERATORS,
      },
      {
        field: 'goals',
        headerName: 'Goals',
        flex: 2,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ value }) => (
          <Tooltip
            placement="right"
            title={
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {value?.map((i: EventGoal) => {
                  const ticketGoalsString = Object.entries(i.ticketGoals)
                    .map(([key, val]) => {
                      const ticketWord = +key === 1 ? 'ticket' : 'tickets'
                      if (!val) {
                        return ''
                      } else if (val === -1) {
                        return `${key} ${ticketWord}: ∞`
                      } else {
                        return `${key} ${ticketWord} x ${val}`
                      }
                    })
                    .filter((s) => s)
                    .join(', ')

                  return (
                    <li key={i.name}>
                      {i.name} : {ticketGoalsString}
                    </li>
                  )
                })}
              </ul>
            }
          >
            <Box>
              <FlagIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'priority',
        headerName: 'Priority',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: ({ row }) => (
          <Tooltip
            placement="right"
            title={
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {row.goals?.map((i: any) => (
                  <li key={i.id} style={{ marginBottom: '4px' }}>
                    {i.name}: {i.priority}
                  </li>
                ))}
              </ul>
            }
          >
            <Box>
              <LowPriorityIcon sx={{ color: '#007BFF', cursor: 'pointer' }} fill="#007BFF" />
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'matchNumber',
        headerName: 'Match Number',
        type: 'Number',
        hideable: false,
        filterOperators: ALL_NUMBER_OPERATORS,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 3,
        hideable: false,
        type: 'String',
        filterOperators: [
          createAutocompleteOperator('/api/purchasing/events/distinct', 'description'),
          createNotEqualsAutocompleteOperator('/api/purchasing/events/distinct', 'description'),
          ...CONTAINS_STRING_OPERATOR,
          ...NOT_CONTAINS_STRING_OPERATOR,
        ],
      },
    ],
    []
  )

  const { queryParams, paginationDataGridProps } = usePagination({ columns })
  const { selectedRows, handleRowSelection } = useSelectedRows()
  const { data, isFetching } = useGetGoalsQuery(queryParams)

  return (
    <Box sx={{ height: 'calc(100vh - 122px)', width: '100%', pt: 1 }}>
      <CustomDataGrid
        rows={data?.data || []}
        columns={columns}
        columnVisibilityModel={{
          matchNumber: false,
          description: false,
        }}
        rowCount={data?.page?.totalElements || 0}
        loading={isFetching}
        {...paginationDataGridProps}
        onRowSelectionModelChange={handleRowSelection}
        selectedRows={selectedRows}
        toolbar={GoalsTableToolbar}
        rowHeight={80}
        rowSelection={false}
      />
    </Box>
  )
}

export default Goals
